import { AppLockState, GSSchoolAction, subscriptionTypeUsage, BulkClassEditErrorCode } from '@app/util/enum';
import { PathConfig } from '@app/config/pathconfig';
import { StateType } from '../index';
import { SchoolService } from "@app/service/schools";
import {
    CurriculumType as CurriculumTypeEnum,
    CurriculumType,
    SchoolClassModel,
    SchoolClassService,
    SubscriptionType,
    UnitPlanProgressionHistoryModel,
    ClassesModel
} from "@app/service/class";
import { UserService } from '@app/service/users';
import * as moment from 'moment';
import { clearQuery, fmtMsg, isFutureDate, isPastDate, setQueryState, sliceMoment } from '@app/util/func';
import { GLGlobal, maskMain, MessageHelper, NotificationType } from 'gl-commonui';
import { StatisticService } from '@app/service/school/statistic/service';
import { CONSTS, ClassHelper, ContextHelper, DateHelper, formatDate } from '@app/util';
import { StudentSubscriptionService } from '@app/service/school/student-subscription';
import { SchoolLocale } from '@app/locales/localeid';
import { change as changeSchool } from './school';
import { CampusModel, CampusService } from '@app/service/school/campus';
import { MoveStudentRequestModel } from './classModel';
import { reload as reloadClasses } from '@app/states/school/classes';
import { reload as reloadCampus } from '@app/states/school/campus';
import { change as reloadSchool } from '@app/states/school/school';

interface Services {
    campus: CampusService
    school: SchoolService
    schoolClass: SchoolClassService
    user: UserService
    statistic: StatisticService
    studentSubscription: StudentSubscriptionService
}

export interface SchoolClassState {
    model?: SchoolClassModel;
    list?: SchoolClassModel[];
    campusList?: CampusModel[];
    total?: number;
    activeCnt?: number;
    futureCount?: number;
    loading?: boolean;
    unitPlans?: any[];
    appUnlockUnit?: number;
    autoFillUnitPlans?: number;
    unit;
    license?: {
        curriculumType?: string;
        subTypeLable?: string;
        subType?: string;
        counts?: {
            sub: { cnt, preNewCnt, newCnt, note },
            license: { cnt, preNewCnt, newCnt, note }
        }
        period?: () => string;
        billedFrom?: () => string;
        lastBilledPeriod?: () => string;
        lastPeriod?: string;
        startDate?: Date;
    };
    students?: any;
    origStudents?: any;
    promoteHistories?: any[];
    progressionHistories?: UnitPlanProgressionHistoryModel[];
    classRoles?: any[];
    classInvoiceInfo?: ClassBillingCycleInfoModel;
    futureAnnualOuter?: boolean;
    licenseUpdated: boolean;
    shouldSubmitEditLicense: boolean;
    licenseEditError: boolean;
    isStudentRegistered: boolean;
    isInvitationTab: boolean;
    hasGSConnectPermission: boolean;
}

export interface ClassBillingCycleInfoModel {
    classId: string;
    lastCycle: BillingCycleInfoModel;
    currentCycle: BillingCycleInfoModel;
    nextCycle: BillingCycleInfoModel;
    currentCycleInvoiceDate: Date;
}

export interface BillingCycleInfoModel {
    students: number;
    adjustmentManual: number;
    adjustmentAutomatic: number;
    billingDoneFor: number;
    billingCycleStart?: Date | string;
    billingCycleEnd?: Date | string;
}

export interface ManualAdjustmentInfoModel {
    schoolClassId: string;
    isInvoiceGenerated: boolean;
    adjustments: { id?: string; billingDate: string, adjustment: number, note?: string, shouldSkip: boolean }[];
}

export interface RegionManualAdjustmentCountModel {
    schooolClassId: string;
    adjustmentCount: number;
}

export default {
    namespace: 'schoolClass',
    state: {
        model: {},
        list: [],
        total: 0,
        activeCnt: 0,
        futureCount: 0,
        loading: false,
        unitPlans: [],
        appUnlockUnit: null,
        autoFillUnitPlans: 0,
        unit: null,
        license: {},
        promoteHistories: [],
        progressionHistories: [],
        classRoles: [],
        origStudents: [],
        classInvoiceInfo: null,
        futureAnnualOuter: false,
        licenseUpdated: false,
        shouldSubmitEditLicense: false,
        licenseEditError: false,
        isStudentRegistered: false,
        isInvitationTab: false,
        hasGSConnectPermission: false
    },
    reducers: {
        reload(state, { payload }) {
            return { ...state, ...payload };
        },
        change(state, action) {
            return { ...state, current: action.payload };
        },
        fillList(state, { payload: { list, pagination } }) {
            return { ...state, list: list || state.list, pagination: pagination || state.pagination };
        },
        loadUnitPlans(state, action) {
            return { ...state, ...action.payload };
        },
        setLicense(state, action) {
            return { ...state, license: action.payload };
        },
        setPromoteHistories(state, { payload: { promoteHistories } }) {
            return { ...state, promoteHistories: promoteHistories };
        },
        setIsStudentRegistered(state, { payload: { isStudentRegistered } }) {
            return { ...state, isStudentRegistered: isStudentRegistered };
        },
        setIsOnInvitationTab(state, { payload: { isInvitationTab } }) {
            return { ...state, isInvitationTab: isInvitationTab };
        },
    },
    effects: {
        *getItemsBy({ payload: { schoolId, campusId, classId: id, pagination, sorter, className } }, { call, put, select }, { schoolClass, user }: Services): any {
            yield put(reload({ loading: true }));
            const { data: { schoolClasses }, totalCount, extraData: { activeCnt, futureCnt: futureCount } } = yield call(schoolClass.getItemsBy, {
                campusId,
                id, ...(pagination ? pagination.toRequest() : {}),
                disabled: pagination ? pagination.disabled : null, ...sorter,
                future: pagination ? pagination.future : null,
                className
            }, { schoolId });
            const list = formatLicenseTypeUnit(schoolClasses);
            yield put(reload({ list, total: totalCount, activeCnt, futureCount, loading: false }));

            const teacherIds = schoolClasses.map(d => d.teacherId).concat(schoolClasses.map(d => d.substituteTeacherId)).filter(id => id);
            if (teacherIds.length > 0) {
                const { data: teachers } = yield call(user.getUsersByPost, { ids: teacherIds });
                yield put(reload({ list: formatTeachers(list, teachers) }));
            }
        },
        *get({ payload }, { select, call, put, all }, { schoolClass }: Services): any {
            const { schoolId, id } = payload;
            const [data, classRoles] = yield all([
                call(schoolClass.get, null, payload),
                call(schoolClass.getUserRole, { schoolId, id, userId: ContextHelper.getUserLoginInfo().profile.sub })
            ]);
            const state = yield select((state: StateType) => state.schoolClass);
            yield put(reload({ ...state, model: data, classRoles: classRoles.map(classRole => classRole.name) }));
        },
        *getPromotion({ payload }, { call, put }, { schoolClass }: Services): any {
            const data = yield call(schoolClass.getPromotion, payload);
            yield put(reload({ list: data }));
        },
        *getAccessibleCampus({ payload }, { call, put }, { school }: Services): any {
            const data = yield call(school.getAccessibleCampuses, payload.schoolId, payload.disabled);
            yield put(reload({ campusList: data }));
        },
        *update({ payload }, { call, put, select }, { schoolClass }: Services, { push, pathStringify }) {
            let newPayload = {
                schoolClassPromotion: {
                    startDate: moment(DateHelper.formatDate2ISO(payload.startDate)).toDate()
                },
                ...payload,
                startDate: formatDate(payload.startDate,'YYYY-MM-DD'),
                endDate: payload.endDate ? formatDate(payload.endDate,'YYYY-MM-DD') : null
            }
            const { school: { current: { id: schoolId, allowSchoolEditLicense: allowSchoolEditLicense, regionId } }, schoolClass: { model: { id } } } = yield select(state => state);
            if (GLGlobal.isActionValid(GSSchoolAction.SaveClass)) {
                yield call(schoolClass.update, newPayload, { schoolId, id });
            }
            yield put(push(pathStringify(PathConfig.Students, { regionId, schoolId, campusId: newPayload.campusId, classId: id })));
        },
        *create({ payload }, { call, put, select }, { schoolClass }: Services, { push, pathStringify }) {
            const { current: { id: schoolId, allowSchoolEditLicense: allowSchoolEditLicense, regionId } } = yield select((state: StateType) => state.school);

            if (payload.destinationCampusId && payload.campusId != payload.destinationCampusId) {
                payload.campusId = payload.destinationCampusId;
            }
            let newPayload = payload;
            let studentCount = payload.studentCount;
            if (payload.isPromotion) {
                newPayload = {
                    schoolClassPromotion: {
                        selectionSchoolClassId: payload.selectionSchoolClassId,
                        startDate: moment(DateHelper.formatDate2ISO(payload.startDate)).toDate()
                    },
                    ...payload
                }
                delete newPayload.selectionSchoolClassId
                delete newPayload.studentCount
            }
            newPayload = {
                ...newPayload,
                startDate: formatDate(payload.startDate,'YYYY-MM-DD'),
                endDate: payload.endDate ? formatDate(payload.endDate,'YYYY-MM-DD') : null
            };
            const classId = yield call(schoolClass.create, newPayload, { schoolId });
            const school = yield select((state: StateType) => state.school.current);
            if (payload.isPromotion && payload.unitPlanStartDate) {
                yield call(schoolClass.updateUnitPlans, schoolId, classId, formatUnitPlan([{ unit: newPayload.startUnit, startDate: payload.unitPlanStartDate, lockState: school.appLockState }], false, 'YYYY-MM-DD', true, CurriculumType.GrapeSEED));
            }
            else {
                yield call(schoolClass.updateUnitPlans, schoolId, classId, formatUnitPlan([{ unit: newPayload.startUnit, startDate: newPayload.startDate, lockState: school.appLockState }], false, 'YYYY-MM-DD', true, newPayload.licenseType));
            }
            const classRoles = yield call(schoolClass.getUserRole, { schoolId: schoolId, id: classId, userId: ContextHelper.getUserLoginInfo().profile.sub });
            yield put(reload({ classRoles: classRoles.map(classRole => classRole.name) }));
            if (payload.disabled || !ClassHelper.canEditLicense(classRoles.map(classRole => classRole.name), allowSchoolEditLicense)) {
                yield put(push(pathStringify(PathConfig.Students, { regionId, schoolId, campusId: payload.campusId, classId: classId })));
            } else if (payload.isPromotion && studentCount) {
                sessionStorage.setItem("selectionSchoolClassId", JSON.stringify({ selectedId: payload.selectionSchoolClassId }));
                yield put(push(pathStringify(PathConfig.ClassStudentsPromote, { regionId, schoolId, campusId: payload.campusId, classId })));
            } else if (payload.isPromotion && !studentCount) {
                sessionStorage.setItem("isPromoteClass", JSON.stringify({ isPromoteClass: true }));
                yield put(push(pathStringify(PathConfig.ClassUnitPlan, { regionId, schoolId: payload.schoolId, campusId: payload.campusId, classId })));
            } else {
                sessionStorage.setItem("isCreatedClass", JSON.stringify({ isCreatedClass: true }));
                yield put(push(pathStringify(PathConfig.ClassUnitPlan, { regionId, schoolId: payload.schoolId, campusId: payload.campusId, classId })));;
            }
        },
        *promotestudents({ payload }, { call, put, select }, { schoolClass }: Services, { push, pathStringify }) {
            yield call(schoolClass.createPromoteStudents, payload.schoolId, payload.classId, payload.studentIds, payload.isPromotion);
            sessionStorage.setItem("isPromoteClass", JSON.stringify({ isPromoteClass: true }));
            yield put(push(pathStringify(PathConfig.ClassUnitPlan, { regionId: payload.regionId, schoolId: payload.schoolId, campusId: payload.campusId, classId: payload.classId })));
        },
        *remove(d, { select, call, put }, { schoolClass }: Services, { push, pathStringify }): any {
            const { school: { current: { id: schoolId } }, schoolClass: { model: { id } } } = yield select(state => state);
            yield call(schoolClass.delete, { schoolId, id });
            yield put(goToList());
        },
        *goToList(action, { select, put }, services, { push, pathStringify, pathParse }): any {
            const schoolId = yield select((state: StateType) => state.school.current.id);
            clearQuery('classes');
            const params = pathParse({ path: PathConfig.Classes });
            yield put(push(pathStringify(PathConfig.Classes, { ...params, schoolId })));
        },
        *goToStudents(action, { select, put }, services, { push, pathParse, pathStringify }): any {
            const params = pathParse({ path: PathConfig.Students.replace('/students', '') });
            setQueryState('students', { current: 1 }, params);
            yield put(push(pathStringify(PathConfig.Students, params)));
        },
        *getUnitPlans({ payload: { schoolId, campusId, classId } }, { select, call, put, all }, { school, schoolClass }: Services): any {
            yield put(reload({ loading: true }));
            const [schoolData, classData, { currentUnit, appUnlockUnit, unitPlan, unitPlanProgression }] = yield all([
                call(school.get, null, { id: schoolId }),
                call(schoolClass.get, null, { schoolId, id: classId }),
                call(schoolClass.getUnitPlans, schoolId, classId)
            ]);
            unitPlan.forEach(plan => plan.editable = !isPastDate(plan.startDate));
            yield put(changeSchool(schoolData));
            yield put(setFutureAnnualOuter({ schoolId, campusId, classId }));
            yield put(loadUnitPlans({ unitPlans: formatAppStatus(formatUnitPlan(unitPlan, true), schoolData), unit: currentUnit ? Math.abs(currentUnit) : null, appUnlockUnit: appUnlockUnit ? Math.abs(appUnlockUnit) : null }));
            yield put(reload({
                model: classData,
                progressionHistories: formatProgressionHistory(unitPlanProgression),
                loading: false
            }));
        },
        *getUnitPlansForBulk({ payload: options }, { select, call, put, all }, { school, campus, schoolClass }: Services): any {
            yield put(reload({ loading: true }));
            const [schoolModel, campusModel, { data: schoolClasses, totalCount, extraData: { activeCnt, futureCnt: futureCount } }] = yield all([
                call(school.get, null, { id: options.schoolId }),
                call(campus.get, options),
                call(schoolClass.getUnitPlansForBulk, { ...options, sortBy: 'name', isDescending: false })
            ]);
            yield put(changeSchool(schoolModel));
            schoolClasses.forEach(formatSchoolClassForBulk(campusModel));
            yield put(fillList({ list: schoolClasses }));
            yield put(reload({ total: totalCount, activeCnt, futureCount, loading: false }));
        },
        *updateUnitPlansForBulk({ payload: { data, regionId, schoolId, campusId, schoolAppLockState} }, { call, put }, { schoolClass }: Services, { push, pathStringify }) {
            data = updateSchoolClassForBulk(data);
            const results = yield call(schoolClass.updateUnitPlansForBulk, { schoolClasses: data.map(sc=> fixUnitPlanUnitAndLockState(sc, schoolAppLockState)) }, { schoolId, campusId });
            if (hasExecutedSuccess(results, data)) {
                yield put(push(pathStringify(PathConfig.Classes, { regionId, schoolId, campusId })));
            }
        },
        *generateUnitPlans({ payload: { schoolId: schoolKey, schoolClassId, beginUnit, startDate, endDate, unitPlans, minDaysPerUnit, daysofweek, successCallback } }, { select, call, put }, { schoolClass }: Services, { pathParse }): any {
            yield put(reload({ loading: true }));
            const { schoolId = schoolKey, classId = schoolClassId } = pathParse({ path: PathConfig.ClassUnitPlan });
            const isPastUnitPlans = unitPlans && unitPlans.length > 0 && unitPlans[unitPlans.length - 1].startDate && isPastDate(unitPlans[unitPlans.length - 1].startDate);

            function getClosestDate(date, weekDays: any[]) {
                if (weekDays.length == 0) return date;
                let minoffset = 7;
                weekDays.forEach(day => {
                    const offset = day - moment(date).day();
                    if (Math.abs(offset) <= Math.abs(minoffset)) {
                        minoffset = offset;
                    }
                })
                return moment(date).add(minoffset, 'days');
            }

            function getDateWithDaysofWeek(date, daysofweek: number[], minDate) {
                const week = moment(date).day();
                if (daysofweek.find(dow => dow == week)) return date;
                let weekDays = [];
                const firstWeekDayofDate = sliceMoment(moment(date).startOf('week'));
                const lastWeekDayofminDate = sliceMoment(moment(firstWeekDayofDate).endOf('week'));
                let isInSameWeek = minDate ? minDate.diff(firstWeekDayofDate, 'days') >= 0 && minDate.diff(lastWeekDayofminDate, 'days') <= 0 : true;
                if (isInSameWeek && moment(minDate).day() > Math.max(...daysofweek)) {
                    minDate = sliceMoment(moment(firstWeekDayofDate).add(7, 'days'));
                    isInSameWeek = false;
                }
                if (minDate && minDate.diff(date, 'days') >= 0 && !isInSameWeek) {
                    weekDays = daysofweek.map(dow => moment(minDate).startOf('week').add(dow, 'days')).filter(dow => dow.diff(minDate, 'days') >= 0);
                    return weekDays.length == 0 ? moment.max(date, minDate) : getClosestDate(minDate, daysofweek);
                }
                else {
                    weekDays = daysofweek.map(dow => moment(firstWeekDayofDate).add(dow, 'days')).filter(dow => minDate ? dow.diff(minDate, 'days') >= 0 : true);
                    return weekDays.length == 0 ? moment.max(date, minDate) : getClosestDate(date, daysofweek);
                }
            }

            function getGenerateUnitPlanError(teachUnitCount, loadUnitCount) {
                if (teachUnitCount > 1 && loadUnitCount > 1) {
                    return SchoolLocale.UnitPlanGenerateErrorMM;
                } else if (teachUnitCount > 1 && loadUnitCount <= 1) {
                    return SchoolLocale.UnitPlanGenerateErrorMS;
                } else if (teachUnitCount <= 1 && loadUnitCount > 1) {
                    return SchoolLocale.UnitPlanGenerateErrorSM;
                } else {
                    return SchoolLocale.UnitPlanGenerateErrorSS;
                }
            }

            try {
                if (startDate && endDate && unitPlans && unitPlans.length > 0 && !isPastUnitPlans) {
                    let startUnit = unitPlans[0].unit;
                    for (let i = unitPlans.length - 1; i--; i >= 0) {
                        if (unitPlans[i].startDate && isPastDate(unitPlans[i].startDate)) {
                            startUnit = unitPlans[i].unit;
                            break;
                        }
                    }
                    if (beginUnit) {
                        startUnit = beginUnit;
                    }
                    const validUnitPlans = unitPlans.filter(plan => plan.unit >= startUnit);
                    const validUnits = validUnitPlans.length == 0 ? unitPlans.length : validUnitPlans.length;
                    const teachDays = sliceMoment(moment(endDate)).diff(moment(startDate), 'days') + 1;
                    const average_days_per_unit = Math.ceil(teachDays / validUnits);
                    if (teachDays < minDaysPerUnit * validUnits) {
                        const teachUnitCount = Math.floor(teachDays / minDaysPerUnit);
                        const loadUnitCount = validUnitPlans.length == 0 ? unitPlans.length : validUnitPlans.length;
                        const message = fmtMsg({ id: getGenerateUnitPlanError(teachUnitCount, loadUnitCount) },
                            {
                                startDate: DateHelper.formatDate2Local(startDate),
                                endDate: DateHelper.formatDate2Local(endDate),
                                teachUnitCount,
                                loadUnitCount
                            }
                        );
                        MessageHelper.Message(NotificationType.Warning, message);
                    }
                    else {
                        //save class endDate to back end
                        yield schoolClass.merge([{ op: 'replace', value: moment(endDate).format('YYYY-MM-DD'), path: '/EndDate' }], { schoolId, id: classId });
                        yield put(get({ schoolId, id: classId }));
                        const offset = average_days_per_unit - minDaysPerUnit;
                        const mergedUnitPlans = validUnitPlans.length == 0 ? unitPlans.filter(plan => plan.unit >= startUnit) : validUnitPlans;
                        for (let index = 0; index < mergedUnitPlans.length; index++) {
                            const mergedStartDate = index == 0 ? startDate : mergedUnitPlans[index - 1].startDate;
                            const minDate = index == 0 ? (mergedUnitPlans[index].startDate ? null : sliceMoment(moment(startDate))) : sliceMoment(moment(mergedUnitPlans[index - 1].startDate).add(minDaysPerUnit, 'days'));
                            const addingDays = index == 0 ? 0 : average_days_per_unit;
                            if (index == 0 && isPastDate(mergedStartDate) && mergedUnitPlans[index].startDate) {
                                continue;
                            } else {
                                mergedUnitPlans[index].startDate = getDateWithDaysofWeek(moment(mergedStartDate).add(addingDays, 'days'), daysofweek.sort(), minDate);
                            }
                        }

                        const school = yield select((state: StateType) => state.school.current);
                        const formatedUnitPlans = formatAppStatus(formatUnitPlan(unitPlans, false), school, true);
                        yield put(loadUnitPlans({ unitPlans: formatedUnitPlans, autoFillUnitPlans: Date.now() }));
                        successCallback && successCallback(formatedUnitPlans);
                    }
                }
                else {
                    successCallback && successCallback();
                }
            } catch (response) {
                //console.log(response);
            }
            yield put(reload({ loading: false }));
        },
        *updateUnitPlans({ payload }, { select, call, put }, { schoolClass }: Services, { push, pathStringify }): any {
            const licenseType = yield select((state: StateType) => state.schoolClass.model.curriculumType);
            const unitPlans = yield select((state: StateType) => state.schoolClass.unitPlans);
            yield call(schoolClass.updateUnitPlans, payload.schoolId, payload.classId, formatUnitPlan(unitPlans, true, 'YYYY-MM-DD', true, licenseType, true));
            if (payload.onSubmit) {
                payload.onSubmit();
            }
            const obj = JSON.parse(sessionStorage.getItem("isCreatedClass"));
            if (obj && obj.isCreatedClass) {
                yield put(push(pathStringify(PathConfig.ClassLicense, payload)));
            } else if (payload.redirectToStudents) {
                yield put(goToStudents());
            }
        },
        *getLicense({ payload: { schoolId, classId: id } }, { select, call, put, delay }, { schoolClass }: Services): any {
            yield put(reload({ loading: true }));
            let currentSchool;
            let subType ;

           do{
               yield delay(100);
               currentSchool= yield select((state: StateType) => state.school.current);
               subType = currentSchool.subscriptionType;
           } while (!currentSchool.subscriptionType);
            
            const { curriculumType, lastBilledPeriod,
                currentStudentCount, newStudentCount,
                currentTextbookLicenses, newTextbookLicenses,
                currentDigitalLicenses, newDigitalLicenses,
                billingDay, digitalLicenseEditNote, textbookLicenseEditNote, startDate } = yield call(schoolClass.get, null, { schoolId, id });
            const classRoles = yield call(schoolClass.getUserRole, { schoolId: schoolId, id: id, userId: ContextHelper.getUserLoginInfo().profile.sub })
            yield put(setLicense({
                curriculumType,
                subTypeLable: SubscriptionType[subType],
                subType,
                startDate,
                ...fmtPeriod(lastBilledPeriod, billingDay),
                counts: getCnts(subType,
                    currentStudentCount,
                    newStudentCount,
                    currentTextbookLicenses,
                    newTextbookLicenses,
                    currentDigitalLicenses,
                    newDigitalLicenses,
                    digitalLicenseEditNote,
                    textbookLicenseEditNote)
            }));
            yield put(reload({ loading: false, classRoles: classRoles.map(classRole => classRole.name) }));
        },
        *updateLicense({ payload: { schoolId, classId, data } }, { select, call, put }, { schoolClass }: Services, { push, pathParse, pathStringify }): any {
            try {
                yield call(schoolClass.updateLicenseAndSubscription, schoolId, classId, data);
                yield put(reload({ licenseUpdated: true, shouldSubmitEditLicense: false }));
            }
            catch (e) {
                yield put(reload({ licenseEditError: true, licenseUpdated: true, shouldSubmitEditLicense: false }));
            }
            //yield put(goToStudents());
        },
        *updateLicenseCounts({ payload: { schoolId, classId, data, schoolClassInfo } }, { select, call, put }, { schoolClass }: Services) {
            yield put(reload({ loading: true }));
            const { subscriptionType: subType } = yield select((state: StateType) => state.school.current);
            const { currentStudentCount,
                currentTextbookLicenses,
                currentDigitalLicenses } = yield call(schoolClass.get, null, { schoolId, id: classId });

            const schoolClassState = yield select((state: StateType) => state.schoolClass);
            const licenseInfo = schoolClassState.license;
            const schoolClassStatus = data.schoolClassStatus;
            const updatedClassInfo = schoolClassInfo;

            updatedClassInfo.studentCount = schoolClassStatus.newStudentCount;
            updatedClassInfo.textbookLicense = schoolClassStatus.newTextbookLicenses;
            updatedClassInfo.digitalLicense = schoolClassStatus.newDigitalLicenses;

            licenseInfo.counts = getCnts(subType,
                currentStudentCount,
                schoolClassStatus.newStudentCount,
                currentTextbookLicenses,
                schoolClassStatus.newTextbookLicenses,
                currentDigitalLicenses,
                schoolClassStatus.newDigitalLicenses,
                schoolClassStatus.digitalLicenseEditNote,
                schoolClassStatus.textbookLicenseEditNote);

            let classList = [];
            classList.push(updatedClassInfo);

            yield put(reload({ loading: true, license: licenseInfo, list: classList }));
        },
        *updateLicenseWithMaterial({ payload: { schoolId, classId, data } }, { select, call, put }, { schoolClass }: Services, { push, pathParse, pathStringify }): any {
            ;
            yield call(schoolClass.updateLicenseAndSubscription, schoolId, classId, data);
            //const params = pathParse({ path: PathConfig.Students });
            //yield put(push(pathStringify(PathConfig.ClassCart, params)));
        },
        *getLicenseHistoryBy({ payload: { schoolId, classId: id } }, { call, put, select, all }, { schoolClass, user }: Services): any {
            yield put(reload({ loading: true }));
            const [{ data: list }, promoteHistories] = yield all([
                call(schoolClass.getClassLicenseHistory, null, { schoolId, id }),
                call(schoolClass.getClassPromotionHistory, null, { schoolId, id })
            ]);
            const mergedHistories = promoteHistories.reduce((pre, cur) => pre.concat(cur.licenseHistory), []);
            //yield put(reload({ list, loading: false }));
            const ids = list.map(d => d.updatedBy).concat(mergedHistories.map(h => h.updatedBy));
            const users = ids.length > 0 ? ContextHelper.getUsers(yield all(ContextHelper.getUsersActions(ids, user))) : [];
            const { subscriptionType: subType, subscriptionTypeUsage: usage } = yield select((state: StateType) => state.school.current);

            function calcChange(prop, value, index, array) {
                if (index === 0) {
                    value[prop + 'V'] = value[prop]
                    return value;
                } else {
                    const change = value[prop] - array[index - 1][prop];
                    const changeSuffix = () => {
                        if (change == 0) {
                            return ""
                        } else {
                            return ` (${change >= 0 ? "+" : "-"}${Math.abs(change)})`
                        }
                    }
                    value[prop + 'V'] = `${value[prop]}${changeSuffix()}`
                    return value
                }
            }

            function fmtCount(subType, usage, licenseHistories) {
                return (licenseHistories as any[]).reverse().map((value, index, array) => {
                    value = calcChange('studentCount', value, index, array)
                    value = calcChange('digitalLicenses', value, index, array)
                    value = calcChange('textbookLicenses', value, index, array)
                    return value
                })
            }

            promoteHistories.forEach(ph => {
                if (ph.licenseHistory) {
                    ph.licenseHistory = fmtCount(subType, usage, ph.licenseHistory).reverse().map(history => {
                        users.forEach(user => user.id === history.updatedBy && (history.user = user.name))
                        return history;
                    })
                }
            });
            yield put(setPromoteHistories({ promoteHistories }));

            yield put(reload({
                loading: false,
                list: (() => {
                    return fmtCount(subType, usage, list).reverse().map(history => {
                        users.forEach(user => user.id === history.updatedBy && (history.user = user.name))
                        return history;
                    })
                })()
            }));
        },
        *getStudentsBy({ payload: { schoolId, classId, pagination, subscriptionType, currentUnit } }, { call, put }, { schoolClass, user, statistic }: Services): any {
            yield put(reload({ loading: true }));
            const { data: students, totalCount: total } = yield call(schoolClass.getStudentsBy, { ...pagination, subscriptionType }, { schoolId, id: classId });
            let lastPlayTimes = [];
            if (students && students.length) {
                lastPlayTimes = yield call(statistic.getStatisticsByPost, { studentIds: students.map(student => student.id), currentUnit, schoolClassId: classId }, false);
            }
            const formattedStudents = fmtStudents(students, lastPlayTimes);
            yield put(reload({ students: formattedStudents, origStudents: JSON.parse(JSON.stringify(formattedStudents)), total, loading: false }));
        },
        *getClassInfo({ payload: params }, { call, put, select, all }, { school, schoolClass, user }: Services, { push, pathParse, pathStringify }): any {
            const locationState = params.locationState;
            const userId = ContextHelper.getUserLoginInfo().profile.sub;
            let roles = null;
            if (locationState && locationState.fromClasses) {
                roles = yield call(school.getSchoolUserRoles, { id: params.schoolId, userId: userId });
            }
            const { data: { schoolClasses } } = yield call(schoolClass.getItemsBy, { ...params, id: params.classId, offset: 0, limit: 1 });
            if (schoolClasses.length == 0) {
                return
            }
            const list = formatLicenseTypeUnit(schoolClasses);
            yield put(reload({ list }));
            const teacherIds = schoolClasses.map(d => d.teacherId).concat(schoolClasses.map(d => d.substituteTeacherId)).filter(id => id);
            if (teacherIds.length > 0) {
                const { data: teachers } = yield call(user.getUsersByPost, { ids: teacherIds });
                yield put(reload({ list: formatTeachers(list, teachers) }));
            }
            const classInfo = schoolClasses[0];
            /* here use undefined condition to avoid render None text to UI, when request return, the value would be not undefined */
            if (!classInfo.teacher) { classInfo.teacher = null; }
            if (!classInfo.subTeacher) { classInfo.subTeacher = null; }
            //  if (isGrapeSEED(classInfo.licenseType)) { // GL-105
            //getting all students on student listing page
            yield put(getStudentsBy({ ...params, pagination: { limit: null, offset: null }, currentUnit: classInfo.currentUnit }));
            yield put(setIsStudentRegistered({ isStudentRegistered: false }));
            yield put(setIsOnInvitationTab({ isInvitationTab: false }));
        },
        *deleteStudent({ payload }, { select, call, put }, { schoolClass }: Services): any {
            payload.id = payload.classId;
            yield call(schoolClass.deleteStudentsBy, { schoolId: payload.schoolId, id: payload.classId, studentId: payload.studentId });
            yield put(getStudentsBy(payload));
            yield put(getLicense({ schoolId: payload.schoolId, classId: payload.classId }));
        },
        *changeStudentSubscription({ payload: { query, classId } }, { call, put }, { studentSubscription }: Services) {
            var params = { schoolId: query.schoolId, classId }
            yield call(studentSubscription.changeStudentSubscription, query);
            yield put(getStudentsBy({ ...params }));
            yield put(getLicense({ ...params }));

        },
        *moveStudent({ payload }, { call, put, select }, { schoolClass }: Services) {
            try {
                yield call(schoolClass.moveStudents, payload);
                MessageHelper.Message(NotificationType.Success, GLGlobal.intl.formatMessage({ id: SchoolLocale.MoveStudentSuccessMessage }))
            }
            catch (resp) {
                MessageHelper.Message(NotificationType.Failed, resp.body.error_description);
            }
        },
        *getClassesForMoveStudents({ payload: { schoolId, campusId } }, { call, put, select }, { schoolClass }: Services) {
            yield put(reload({ loading: true }));
            const { schoolClasses } = yield call(schoolClass.getClassesForMoveStudent, { schoolId: schoolId, campusId: campusId });
            yield put(reload({ list: schoolClasses, loading: false }));
        },
        *cpUnitPlans({ payload }, { select, put }) {
            const school = yield select((state: StateType) => state.school.current);
            yield put(loadUnitPlans({ unitPlans: formatAppStatus(formatUnitPlan(payload, false), school, true), autoFillUnitPlans: Date.now() }));
        },
        *setFutureAnnualOuter({ payload }, { call, put, all }, { schoolClass, campus }: Services) {
            yield put(maskMain(true))
            const [campusModel, schoolClassModel] = yield all([
                call(campus.get, payload),
                call(schoolClass.get, { ...payload, id: payload.classId }),
            ]);
            const ignore = GLGlobal.isActionValid(GSSchoolAction.IgnoreCampusAnnualPrepComplete)
            const futureAnnualOuter = campusModel.annualPrepComplete && new Date(schoolClassModel.startDate) > new Date() && !ignore
            yield put(reload({ futureAnnualOuter, model: schoolClassModel }));
            yield put(maskMain(false))
        },
        *initBulkEditor({ payload }, { call, put, all, delay }, { schoolClass, campus, school }: Services) {
            yield put(maskMain(true))
            const [schoolModel, campusModel, schoolClasses] = yield all([
                call(school.get, { ...payload, id: payload.schoolId }),
                call(campus.get, payload),
                call(schoolClass.getBulkEditClasses, payload),
            ]);

            yield put(reloadSchool(schoolModel));
            yield put(reloadCampus({ model: campusModel }));
            yield delay(50)
            yield put(reloadClasses({ bulkClasses: schoolClasses }));

            yield put(maskMain(false))
        },
        *setPermissionGSConnect({}, { call,select, put }, {user}: Services) {
            const isAllow: boolean =  yield call(user.getRemoteTsiPermission);
            yield put(reload({ hasGSConnectPermission: isAllow}));
        },
    },
    services: {
        campus: CampusService,
        school: SchoolService,
        schoolClass: SchoolClassService,
        user: UserService,
        statistic: StatisticService,
        studentSubscription: StudentSubscriptionService
    }
}
function fixUnitPlanUnitAndLockState(sc, schoolAppLockState) {
    sc.unitPlan.forEach(up => {
        if (sc.curriculumType === CurriculumType.LittleSEED) {
            up.unit = -Math.abs(up.unit);
        }
        if (up.id == CONSTS.EmptyGuid) {
            up.lockState = schoolAppLockState;
        }
    });

    return sc;
}
function hasExecutedSuccess(error, data) {
    if (!error.isSuccess) {
        const notExistUnitPlans = error.processResult && error.processResult.filter(err => err.exceptionCode == BulkClassEditErrorCode.BulkEditClassNotExistStartUnitPlanException);
        if (notExistUnitPlans && notExistUnitPlans.length > 0) {
            const localId = SchoolLocale[BulkClassEditErrorCode[BulkClassEditErrorCode.BulkEditClassNotExistStartUnitPlanException]];
            const schoolClassIds = notExistUnitPlans.map(d => d.classId);
            MessageHelper.Message(NotificationType.Failed, localId ? fmtMsg(localId, {
                classname: data.filter(c => schoolClassIds.some(cid => cid == c.id)).map(c => c.className).join(', ')
            }) : error.exceptionDescription);
        } else if (error.exceptionCode) {
            MessageHelper.Message(NotificationType.Failed, error.exceptionDescription);
        } else {
            error.processResult.forEach((error) => {
                if (!error.isSuccess) {
                    BulkErrorMessage(error, data.find(c => c.id === error.classId))
                }
            })
        }

    }
    return error.isSuccess
}
function BulkErrorMessage(error, data) {
    const localId = SchoolLocale[BulkClassEditErrorCode[error.exceptionCode]]
    MessageHelper.Message(NotificationType.Failed, localId ? fmtMsg(localId, { classname: data && data.className }) : error.exceptionDescription)
}
export function isFutureAnnualOuter(campus, schoolClass) {
    const ignore = GLGlobal.isActionValid(GSSchoolAction.IgnoreCampusAnnualPrepComplete)
    return campus.annualPrepComplete && new Date(schoolClass.startDate) > new Date() && !ignore
}
export function isGrapeSEED(type) {
    return type === CurriculumType[CurriculumType.GrapeSEED];
}
function fmtStudents(students: { id, name, englishName, gender, birthday, lastLoginDate, subscriptionType, isActivated, groupName }[], lastPlayTimes: { studentId, playListCovered, lastPlayTime, sessionDays, sessionTime }[]) {
    const dateReg = /(\d{4})-(\d{2})-(\d{2})/;
    return students.map(({ id, name, englishName, gender, birthday, lastLoginDate, subscriptionType, isActivated, groupName }) => {
        const { playListCovered, lastPlayTime, sessionDays, sessionTime } = lastPlayTimes.find(time => time.studentId === id);
        const birthdayMatch = birthday ? birthday.match(dateReg) : null;
        const birthdayDate = birthdayMatch ? new Date(birthdayMatch[1], birthdayMatch[2] - 1, birthdayMatch[3]) : null;
        const lastPlayTimeMatch = lastPlayTime ? lastPlayTime.match(dateReg) : null;
        const lastPlayTimeDate = lastPlayTimeMatch ? new Date(lastPlayTimeMatch[1], lastPlayTimeMatch[2] - 1, lastPlayTimeMatch[3]) : null;
        return {
            id,
            name,
            englishName,
            birthday: DateHelper.formatDate2Local(birthdayDate),
            playListCovered,
            lastPlayTime: lastPlayTime && DateHelper.formatDate2Local(lastPlayTimeDate),
            gender,
            subscriptionType,
            isActivated,
            groupName,
            sessionDays,
            sessionTime 
        }
    })
}
function fmtLicense(licenseType, subType, usage, { sub, license }) {
    if (licenseType === CurriculumType.LittleSEED) {
        return {
            newStudentCount: sub.newCnt || 0,
            newTextbookLicenses: sub.newCnt || 0,
            newDigitalLicenses: sub.newCnt || 0,
            digitalLicenseEditNote: subType === SubscriptionType.Digital ? sub.note : '',
            textbookLicenseEditNote: subType === SubscriptionType.Textbook ? sub.note : ''
        }
    }
    if (usage === subscriptionTypeUsage.Dual) {
        //if (subType === SubscriptionType.Textbook) {
        return {
            newStudentCount: license.newCnt || 0,
            newTextbookLicenses: license.newCnt || 0,
            newDigitalLicenses: license.newCnt || 0,
            digitalLicenseEditNote: subType === SubscriptionType.Digital ? license.note : '',
            textbookLicenseEditNote: subType === SubscriptionType.Textbook ? license.note : ''
        }
        // }
        // return {
        //     newStudentCount: sub.newCnt || 0,
        //     newTextbookLicenses: sub.newCnt || 0,
        //     newDigitalLicenses: sub.newCnt || 0,
        //     digitalLicenseEditNote: subType === SubscriptionType.Digital ? sub.note : '',
        //     textbookLicenseEditNote: subType === SubscriptionType.Textbook ? sub.note : ''
        // }
    } else if (usage === subscriptionTypeUsage.Single) {
        if (subType === SubscriptionType.Digital) {
            return {
                newStudentCount: sub.newCnt || 0,
                newTextbookLicenses: 0,
                newDigitalLicenses: sub.newCnt || 0,
                digitalLicenseEditNote: sub.note,
                textbookLicenseEditNote: ''
            }
        } else if (subType === SubscriptionType.Textbook) {
            return {
                newStudentCount: sub.newCnt || 0,
                newTextbookLicenses: sub.newCnt || 0,
                newDigitalLicenses: 0,
                digitalLicenseEditNote: '',
                textbookLicenseEditNote: sub.note
            }
        }
    } else {
        if (subType === SubscriptionType.Digital) {
            return {
                newStudentCount: sub.newCnt || 0,
                newTextbookLicenses: license.newCnt || 0,
                newDigitalLicenses: sub.newCnt || 0,
                digitalLicenseEditNote: sub.note,
                textbookLicenseEditNote: license.note
            }
        } else if (subType === SubscriptionType.Textbook) {
            return {
                newStudentCount: sub.newCnt || 0,
                newTextbookLicenses: sub.newCnt || 0,
                newDigitalLicenses: license.newCnt || 0,
                digitalLicenseEditNote: license.note,
                textbookLicenseEditNote: sub.note
            }
        }
    }

}
function fmtPeriod(period, billingDay) {
    const fmtMsg = GLGlobal.intl.formatMessage
    if (!period) {
        return { lastBilledPeriod: () => '', lastPeriod: '', period: () => currentPeriod(billingDay), billedFrom: () => localeDate(nextBilled(getBilledFrom(billingDay))) };
    }
    return { lastBilledPeriod: () => localeDate(nextBilled(new Date(period))), lastPeriod: lastPeriod(period, billingDay), period: () => currentPeriod(billingDay), billedFrom: () => localeDate(nextBilled(getBilledFrom(billingDay))) }
}
function localeDate(date) {
    return DateHelper.formatDate2Local(date);
    // return moment(date).format('MM/DD/YYYY');
    // return new Date(date).toLocaleDateString();
}
function nextBilled(date) {
    date.setDate(date.getDate() + 1);
    return date;
}
function getBilledFrom(billingDay) {
    const today = new Date();
    if (today.getDate() > billingDay) today.setMonth(today.getMonth() + 1);
    today.setDate(billingDay);
    return today;
}
function getByOrigin(period, billingDay) {
    const origin = new Date(period);
    return new Date(origin.getFullYear(), origin.getMonth(), billingDay || origin.getDate());
}
function lastPeriod(period, billingDay?) {
    const end = getByOrigin(period, billingDay);
    const begin = new Date(end);
    begin.setMonth(begin.getMonth() - 1);
    begin.setDate(begin.getDate() + 1);
    return `${localeDate(begin)} ~ ${localeDate(end)}`;
}
function currentPeriod(billingDay) {
    const end = new Date(getBilledFrom(billingDay));
    const begin = new Date(end);
    begin.setMonth(end.getMonth() - 1);
    begin.setDate(begin.getDate() + 1);
    return `${localeDate(begin)} ~ ${localeDate(nextBilled(end))}`;
}
function getCnts(subType, currentStudentCount, newStudentCount, currentTextbookLicenses, newTextbookLicenses, currentDigitalLicenses, newDigitalLicenses, digitalNote, textNote) {
    if (subType === SubscriptionType.Digital) {
        return {
            sub: { cnt: currentStudentCount || 0, preNewCnt: newStudentCount, newCnt: newStudentCount, note: digitalNote },
            license: { cnt: currentTextbookLicenses || 0, preNewCnt: newTextbookLicenses, newCnt: newTextbookLicenses, note: textNote }
        }
    } else if (subType === SubscriptionType.Textbook) {
        return {
            sub: { cnt: currentStudentCount || 0, preNewCnt: newStudentCount, newCnt: newStudentCount, note: textNote },
            license: { cnt: currentDigitalLicenses || 0, preNewCnt: newDigitalLicenses, newCnt: newDigitalLicenses, note: digitalNote }
        }
    }
}
function getNewCnt(newCnt, cnt) {
    return newCnt || cnt || 0;
}
export const UnitPlanIndexPrefix = 'up';
export function getUnitPlanIndex(index) {
    return `${UnitPlanIndexPrefix}${index}`;
}
function excludeOutRangeUnitPlans(unitPlans: any[], startUnit, maxUnit) {
    startUnit = Math.abs(startUnit);
    maxUnit = Math.abs(maxUnit);
    return unitPlans.filter(up => {
        const unit = Math.abs(up.unit);
        return unit >= startUnit && unit <= maxUnit;
    });
}
export function setUnitPlanFieldForGrid(schoolClass) {
    schoolClass.unitPlan.forEach((up, index) => {
        schoolClass[getUnitPlanIndex(index)] = up.startDate ? up.startDate.subtract(moment.duration(moment().utcOffset(), "minutes")).toDate() : up.startDate;
    });
}
function updateSchoolClassForBulk(data) {
    return data.map(classData => {
        classData.unitPlan = classData.unitPlan.map(unitPlan => {
            return formatUnitPlanStartDate(unitPlan);
        });
        return classData;
    });
}
function formatUnitPlanStartDate(unitPlan) {
    if (
        unitPlan.startDate &&
        (unitPlan.startDate.milliseconds() != 0 ||
            unitPlan.startDate.seconds() != 0 ||
            unitPlan.startDate.minutes() != 0 ||
            unitPlan.startDate.hours() != 0)
    ) {
        unitPlan.startDate = moment(unitPlan.startDate).add(
            moment.duration(moment().utcOffset(), "minutes")
        );
    }
    return unitPlan;
}
function formatSchoolClassForBulk(campus) {
    return (schoolClass) => {
        schoolClass.unitPlan = formatUnitPlan(excludeOutRangeUnitPlans(schoolClass.unitPlan, schoolClass.startUnit, schoolClass.maxUnit), true);
        setUnitPlanFieldForGrid(schoolClass);
        schoolClass.startUnit = Math.abs(schoolClass.startUnit);
        schoolClass.isfutureAnnualOuter = isFutureAnnualOuter(campus, schoolClass);
    }
}
function updateStartDate(date){
    if((date.milliseconds() == 0 && date.seconds() == 0 && date.minutes() == 0 && date.hours() == 0)){
        return moment(date).utc(true); 
    }
    
    let modifiedDate = date.clone();
    modifiedDate.subtract(moment.duration(modifiedDate.utcOffset(), 'minutes'));
    if((modifiedDate.milliseconds() == 0 && modifiedDate.seconds() == 0 && modifiedDate.minutes() == 0 && modifiedDate.hours() == 0)){
        return date.clone().utc(true); 
    }
    
    date.subtract(moment.duration(date.utcOffset(), 'minutes'));
    return moment(date).utc(true);
}
function formatUnitPlan(unitPlans, getOrUpdateUnitPlan: boolean, fmt = 'MM/DD/YYYY', toString?, licenseType?, includedLocalStartDate?: boolean) {
    toString = toString ? (date) => {
        var d = moment(date);
        d.set("hour", 0)
        d.set("minute", 0)
        d.set("second", 0)
        d.set("millisecond", 0)
        return d.toISOString()
    } : (date) => {
        date = moment(date).local(true);
        if(getOrUpdateUnitPlan) date = updateStartDate(date);
        return date;
    }
    let result = toString ? unitPlans.map(plan => { return { ...plan } }) : unitPlans;
    result.forEach(plan => {
        plan.editable = !isPastDate(plan.startDate);
        if(includedLocalStartDate) {
            if (plan.startDate && typeof plan.startDate.toISOString === 'function') {
                plan.localStartDate = plan.startDate.toISOString(true);
            } else {
                plan.localStartDate = null;
            }
        }
        if (plan.startDate) plan.startDate = toString(plan.startDate);
        if (plan.unlockDate) plan.unlockDate = toString(plan.unlockDate);
        if (licenseType) {
            if (licenseType == CurriculumType.LittleSEED) {
                plan.unit = -Math.abs(plan.unit);
            }
        }
        else {
            plan.unit = Math.abs(plan.unit);
        }
    });
    return result;
}
function formatProgressionHistory(unitPlanProgression: any[]) {
    let result = [];
    unitPlanProgression.forEach(upp => {
        const unitPlans = formatUnitPlan(upp.unitPlan, false);
        result.push(...unitPlans.map((p, index) => {
            return {
                index,
                schoolClassId: upp.id,
                schoolClassIsLittleSEED: upp.licenseType == CurriculumType.LittleSEED,
                schoolClassName: upp.name,
                schoolClassStartDate: upp.startDate,
                schoolClassDisabled: upp.disabled,
                campusId: upp.campusId,
                campusName: upp.campusName,
                campusDisabled: upp.campusDisabled,
                unitPlanId: p.id,
                unit: p.unit,
                unitStartDate: p.startDate
            }
        }));
    })
    return result;
}
function formatLicenseTypeUnit(data: SchoolClassModel[]) {
    return data.map(d => {
        d.curriculumType = CurriculumTypeEnum[d.curriculumType];
        d.currentUnit = d.currentUnit ? Math.abs(d.currentUnit) : null;
        d.maxUnit = Math.abs(d.maxUnit);
        return d;
    })
}
function formatTeachers(data: SchoolClassModel[], teachers: { id, name }[]) {
    return data.map(d => {
        teachers.forEach(teacher => {
            if (teacher.id === d.teacherId) {
                d.teacher = teacher.name;
            }
            if (teacher.id === d.substituteTeacherId) {
                d.subTeacher = teacher.name;
            }
        })
        /* here use undefined condition to avoid render None text to UI, when request return, the value would be not undefined */
        if (!d.teacher) { d.teacher = null; }
        if (!d.subTeacher) { d.subTeacher = null; }
        return d;
    })
}
function formatAppStatus(unitPlans: any[], school, resetLockState?) {
    const { appLockState } = school;
    return unitPlans.map(plan => {
        if (plan.startDate) {
            let lockState = plan.lockState == null ? appLockState : plan.lockState;
            if (plan.lockState == null) plan.lockState = lockState;
            plan.lockState4Display = lockState == AppLockState.Lock;
            plan.appLockStateDisabled = appLockState == AppLockState.Lock && isFutureDate(plan.startDate);
            if (resetLockState && appLockState == AppLockState.Lock && plan.appLockStateDisabled) {

                plan.lockState = AppLockState.Lock;
                plan.lockState4Display = true;
                lockState = AppLockState.Lock;
            }
            plan.appContentStatus = ClassHelper.getUnitAppContentStatus(lockState, 0, plan.startDate);
        }
        return plan;
    });
}
export function reload(state) {
    return { type: 'schoolClass/reload', payload: state }
}
export function change(state) {
    return { type: 'schoolClass/change', payload: state }
}
export function getItemsBy(state?) {
    return { type: 'schoolClass/getItemsBy', payload: state }
}
export function get(state) {
    return { type: 'schoolClass/get', payload: state }
}
export function getPromotion(state) {
    return { type: 'schoolClass/getPromotion', payload: state }
}
export function getAccessibleCampus(state) {
    return { type: 'schoolClass/getAccessibleCampus', payload: state }
}
export function update(state) {
    return { type: 'schoolClass/update', payload: state }
}
export function create(state) {
    return { type: 'schoolClass/create', payload: state }
}
export function promotestudents(state) {
    return { type: 'schoolClass/promotestudents', payload: state }
}
export function createPromotion(state) {
    return { type: 'schoolClass/createPromotion', payload: state }
}
export function remove(state) {
    return { type: 'schoolClass/remove', payload: state }
}
export function goToList(state?) {
    return { type: 'schoolClass/goToList', payload: state }
}
export function goToStudents(state?) {
    return { type: 'schoolClass/goToStudents', payload: state }
}
export function fillList(state) {
    return { type: 'schoolClass/fillList', payload: state }
}
export function getUnitPlans(state) {
    return { type: 'schoolClass/getUnitPlans', payload: state }
}
export function getUnitPlansForBulk(state) {
    return { type: 'schoolClass/getUnitPlansForBulk', payload: state }
}
export function updateUnitPlansForBulk(state) {
    return { type: 'schoolClass/updateUnitPlansForBulk', payload: state }
}
export function loadUnitPlans(state) {
    return { type: 'schoolClass/loadUnitPlans', payload: state }
}
export function generateUnitPlans(state) {
    return { type: 'schoolClass/generateUnitPlans', payload: state }
}
export function cpUnitPlans(state) {
    return { type: 'schoolClass/cpUnitPlans', payload: state }
}
export function updateUnitPlans(state) {
    return { type: 'schoolClass/updateUnitPlans', payload: state }
}
export function getLicense(state) {
    return { type: 'schoolClass/getLicense', payload: state }
}
export function setLicense(state) {
    return { type: 'schoolClass/setLicense', payload: state }
}
export function setPromoteHistories(state) {
    return { type: 'schoolClass/setPromoteHistories', payload: state }
}
export function updateLicense(state) {
    return { type: 'schoolClass/updateLicense', payload: state }
}
export function updateLicenseCounts(state) {
    return { type: 'schoolClass/updateLicenseCounts', payload: state }
}
export function getStudentsBy(state) {
    return { type: 'schoolClass/getStudentsBy', payload: state }
}
export function updateLicenseWithMaterial(state) {
    return { type: 'schoolClass/updateLicenseWithMaterial', payload: state }
}
export function getClassInfo(state) {
    return { type: 'schoolClass/getClassInfo', payload: state }
}
export function deleteStudent(state) {
    return { type: 'schoolClass/deleteStudent', payload: state }
}
export function getLicenseHistoryBy(state) {
    return { type: 'schoolClass/getLicenseHistoryBy', payload: state }
}
export function changeStudentSubscription(state) {
    return { type: 'schoolClass/changeStudentSubscription', payload: state }
}
export function moveStudent(state: MoveStudentRequestModel) {
    return { type: 'schoolClass/moveStudent', payload: state }
}
export function getClassesForMoveStudents(state) {
    return { type: 'schoolClass/getClassesForMoveStudents', payload: state }
}
export function setFutureAnnualOuter(state) {
    return { type: 'schoolClass/setFutureAnnualOuter', payload: state }
}
export function initBulkEditor(state) {
    return { type: 'schoolClass/initBulkEditor', payload: state }
}
export function setIsStudentRegistered(state) {
    return { type: 'schoolClass/setIsStudentRegistered', payload: state }
}
export function setIsOnInvitationTab(state) {
    return { type: 'schoolClass/setIsOnInvitationTab', payload: state }
}

export function setPermissionGSConnect() {
    return { type: 'schoolClass/setPermissionGSConnect' }
}
