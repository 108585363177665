export class DashboardLocale {
    static HeaderMenuAdmin = "dashboard.headermenu.admin";
    static HeaderMenuAdminReport = "dashboard.headermenu.adminreport";
    static HeaderMenuAdminRegion = "dashboard.headermenu.adminregion";
    static HeaderMenuAdminGlobalProduct = "dashboard.headermenu.adminglobalproduct";
    static HeaderMenuAdminLocalProduct = "dashboard.headermenu.adminlocalproduct";
    static HeaderMenuAdminUser = "dashboard.headermenu.adminuser";
    static HeaderMenuAdminInvitationTemplate = "dashboard.headermenu.admininvitationtemplate";
    static HeaderMenuCodeLookup = "dashboard.headermenu.codelookup";
    static HeaderMenuPendingChanges = "dashboard.headermenu.pendingchanges";
    static HeaderMenuExternal = "dashboard.headermenu.external";
    static HeaderMenuExternalSurvey = "dashboard.headermenu.externalsurvey";
    static HeaderMenuTraining = "dashboard.headermenu.training";
    static HeaderMenuTrainingDevelopment = "dashboard.headermenu.trainingdevelopment";
    static HeaderMenuTrainingTeacher = "dashboard.headermenu.trainingteacher";
    static HeaderMenuTrainingTrainer = "dashboard.headermenu.trainingtrainer";
    static HeaderMenuKoreaOrder = "dashboard.headermenu.koreaorder";
    static HeaderMenuCurriculum = "dashboard.headermenu.curriculum";
    static HeaderMenuConnect = "dashboard.headermenu.connect";
    static HeaderSiteTitleGlobal = "dashboard.header.site.title.global";
    static HeaderMenuStudents = "dashboard.headermenu.students";
    static HeaderMenuStudentREP = "dashboard.headermenu.studentrep";
    static HeaderMenuChatHistory = "dashboard.headermenu.chathistory";

    static ListStatesActive = "dashboard.common.liststatesactive";
    static ListStatesAll = "dashboard.common.liststatesall";
    static ListStatesInactive = "dashboard.common.liststatesinactive";

    static LandingTabGlobalHeadText = "landingtab.globalhead.text";
    static LandingTabSystemAdminText = "landingtab.systemadmin.text";
    static LandingTabRegionAdminText = "landingtab.regionadmin.text";
    static LandingTabAccountManagerText = "landingtab.accountmanager.text";
    static LandingTabTrainingAdminText = "landingtab.trainingadmin.text";
    static LandingTabTrainerAdminText = "landingtab.traineradmin.text";
    static LandingTabTrainingManagerText = "landingtab.trainingmanager.text";
    static LandingTabSchoolAdminText = "landingtab.schooladmin.text";
    static LandingTabCampusAdminText = "landingtab.campusadmin.text";
    static LandingTabTeacherText = "landingtab.teacher.text";
    static LandingTabNoRegionResource = "landingtab.no.regionresource";
    static LandingTabRegionSetupResource = "landingtab.setup.regionresource";
    static LandingTabNoSchoolResource = "landingtab.no.schoolresource";
    static LandingTabSchoolSetupResource = "landingtab.setup.schoolresource";
    static LandingTabNoCampusResource = "landingtab.no.campusresource";
    static LandingTabCampusSetupResource = "landingtab.setup.campusresource";
    static LandingTabNoClassResource = "landingtab.no.classresource";
    static LandingTabClassSetupResource = "landingtab.setup.classresource";

    static SearchPlaceHolderRegion = "search.placeholder.regionfilter";
    static SearchPlaceHolderSchool = "search.placeholder.schoolfilter";
    static SearchPlaceHolderSchoolCampusClass = "search.placeholder.schoolcampusclassfilter";

    static LandingTabExportText = "landingtab.export.text";
    static LandingTabDragColumnsText = "landingtab.dragcolumns.text";
    static LandingTabPagination = "landingtab.pagination.text";
    static LandingTabGroupSwitch = "landingtab.groupswitch.text";
}

export class SchoolLocale {
    static EditClassLicenceDescError = "class_editing.desc.error";
    static EditClassLicenceDescDualError = "class_editing.desc.errordual";
    static EditClassDescDualLicence = "class_editing.descdual.label";
    static EditClassIncTxtLicence = "class_editing.inctxt.label";
    static EditClassDescTxtLicence = "class_editing.desctxt.label";
    static EditClassIncDigLicence = "class_editing.incdig.label";
    static EditClassDescDigLicence = "class_editing.descdig.label";
    static EditClassDescBy = "class_editing.descby.label";
    static EditClassIncBy = "class_editing.incby.label";
    static EditClassCheckStudents = "class_editing.checkStudents";
    static EditClassReduceNote = "class_editing.reducenote";
    static EditClassIncNote = "class_editing.incnote";
    static EditClassAlsoDelete = "class_editing.alsoDecrease";
    static EditClassNewTotal = "class_editing.newTotal";
    static EditClassAdjustLicence = "class_editing.adjustLicence";
    static EditClassLastBillingPeriod = "class_editing.lastbillingperiod";
    static EditClassCurrentBillingPeriod = "class_editing.currentbillingperiod";
    static EditClassNextBillingPeriod = "class_editing.nextbillingperiod";
    static EditClassAdjustment = "class_editing.adjustment";
    static EditClassBilling = "class_editing.billing";
    static EditClassBillingDate = "class_editing.billing.date";
    static EditClassAutomaticAdjustment = "class_editing.automaticAdjustment";
    static EditClassManualAdjustment = "class_editing.manualAdjustment";
    static EditClassAdjustmentSaveSuccess = "class_editing.adjustmentsave.success";
    static EditClassAdjustmentSaveFail = "class_editing.adjustmentsave.fail";
    static SchoolLicenseHistoryAdjustments = "school.license.adjustments";
    static SchoolLicenseHistoryAdjustedBy = "school.license.adjustedby";
    static SchoolLicenseHistoryDate = "school.license.date";
    static SchoolLicenseHistorySelectCampus = "school.license.selectCampus";
    static SchoolVisitationHistoryTitle = "school.visitationhistory.title";
    static SchoolVisitationHistorySchoolLabel = "school.visitationhistory.schoollabel";
    static SchoolVisitationHistoryCampusTitleLabel = "school.visitationhistory.campustitlelabel";
    static SchoolVisitationHistoryOnSiteTitleLabel = "school.visitationhistory.onsitetitlelabel";
    static SchoolVisitationHistoryOnSiteVisitLabel = "school.visitationhistory.onsitevisitlabel";
    static SchoolVisitationHistoryOnSiteRemainingLabel = "school.visitationhistory.onsiteremaininglabel";
    static SchoolVisitationHistoryLVATitleLabel = "school.visitationhistory.lvatitlelabel";
    static SchoolVisitationHistoryLVALabel = "school.visitationhistory.lvalabel";
    static SchoolVisitationHistoryLVARemainingLabel = "school.visitationhistory.lvaremaininglabel";
    static SchoolVisitationHistoryNoDataTitleLabel = "school.visitationhistory.nodatatitlelabel";
    static SchoolVisitationHistoryCoachColumn = "school.visitationhistory.coachcolumn";
    static SchoolVisitationHistoryTeacherColumn = "school.visitationhistory.teachercolumn";
    static SchoolVisitationHistoryTeacherTitleParticipated = "school.visitationhistory.teachertitleparticipated";
    static SchoolVisitationHistoryVisitDateColumn = "school.visitationhistory.visitedatecolumn";
    static SchoolVisitationHistoryCompleteDateColumn = "school.visitationhistory.completedatecolumn";
    static SchoolVisitationHistoryViewDetailsText = "school.visitationhistory.viewdetailstext";
    static SchoolVisitationHistorySchoolColumn = "school.visitationhistory.schoolcolumn";
    static SchoolVisitationHistoryCampusColumn = "school.visitationhistory.campuscolumn";
    static SchoolCoachFeedbackReviewDate = "school.coachfeedback.reviewdate";
    static SchoolVisitationHistoryTypeColumn = "school.visitationhistory.typecolumn";
    static EditClassManualAdjustmentNote = "class_editing.manualadjustnote";
    static EditClassDropStudentHere = "class_license.item.dropStudentHere";
    static EditClassMoveToDual = "class_license.item.confirmMoveDual";
    static EditClassMoveToStandard = "class_license.item.confirmMoveStandard";
    static EditClassActualLicenseLabel = "class_editing.actuallicenseslabel";
    static SaveAdjustmentInvoiceUnavailable = "class_editing.adjustment.invoiceunavailable";
    static InvoiceUnaffectedNote = "class_editing.adjustment.invoiceunaffected";
    static EditSchool = "school.text.edit";

    static Location = "school.common.location";
    static Children = "school.common.children";
    static AdminsAccountManager = "school.common.accountmanager";
    static AdminsAdmin = "school.common.administrator";
    static AdminsPending = "school.common.pending";
    static UserSearchMessage = "school.common.userSearchMessage";
    static RegionCoachSchools = "school_trainer.schoolscolumn.text";
    static SchoolTeacherLink = "school.common.schoolteacherlink";
    static SchoolTeacherTitle = "school_teacher.title";
    static SchoolTeacherName = "school_teacher.namecolumn.text";
    static SchoolTeacherClass = "school_teacher.classcolumn.text";
    static SchoolTeacherTags = "school_teacher.tagscolumn.text";
    static SchoolTeacherEmail = "school_teacher.emailcolumn.text";
    static SchoolTeacherPhone = "school_teacher.phonecolumn.text";
    static SchoolTeacherInviteModalTitle = "school_teacher.invitemodal.title";
    static SchoolTeacherDeleteModalTitle = "school_teacher.deletemodal.title";
    static SchoolTrainerDeleteModelTitle = "school_trainer.deletemodal.title";
    static RegionTrainerDeleteModelTitle = "region_trainer.deletemodal.title";
    static RegionTrainingManagerDeleteModelTitle = "region_trainer.deletemodal.title.manager";
    static SchoolTrainerLastDeleteNote = "school_trainer.remove.prompt.note"
    static SchoolTeacherRevokeModalTitle = "school_teacher.revokemodal.title";
    static SchoolTeacherPendingDeleteMessage = "school_teacher.message.deletependingteacher";
    static SchoolTeacherDeleteMessage = "school_teacher.message.deleteschoolteacher";
    static SchoolClassTeacherDeleteMessage = "school_teacher.message.deleteclassteacher";
    static SchoolClassTeacherDeleteForceMessage = "school_teacher.message.deleteclassteacherforce";
    static SchoolTeacherCannotDeleteMessage = "school_teacher.message.cannotdeleteschoolteacher";
    static BreadMenuEdit = "breadmenu.text.edit";
    static BreadMenuLicense = "breadmenu.text.licenses";
    static BreadMenuMaterialCart = "breadmenu.text.materialcart";
    static BreadMenuMaterialOrder = "breadmenu.text.materialorder";
    static BreadMenuMaterialRequest = "breadmenu.text.materialrequestmenu";
    static BreadMenuModalTitle = "breadmenu.text.modaltitle";
    static BreadMenuSchools = "breadmenu.text.schools";
    static BreadMenuSettings = "breadmenu.text.settingsmenu";
    static BreadMenuTeacher = "breadmenu.text.teacher";
    static BreadMenuVisitation = "breadmenu.text.visitation";
    static BreadMenuNewVisitation = "breadmenu.text.newvisitation";
    static BreadMenuTemplate = "breadmenu.text.template";
    static BreadMenuSchoolTeacher = "breadmenu.contextmenu.schoolteacher";
    static BreadMenuUnitPlan = "breadmenu.text.unitplanmenu";
    static BreadMenuManageStudentLogin = "breadmenu.text.managestudentloginmenu"
    static BreadMenuGroupManager = "breadmenu.text.groupmanagermenu";
    static BreadTextAccountManagers = "breadmenu.text.accountmanagers";
    static BreadTextAdmins = "breadmenu.text.admins";
    static BreadTextTrainers = "breadmenu.text.trainers";
    static BreadTextCampuses = "breadmenu.text.campuses";
    static BreadTextCart = "breadmenu.text.cart";
    static BreadTextClasses = "breadmenu.text.classes";
    static BreadTextEdit = "breadmenu.text.edit";
    static BreadTextEditTmpl = "breadmenu.text.edittmpl";
    static BreadTextHelp = "breadmenu.text.help";
    static BreadTextHome = "breadmenu.text.home";
    static BreadTextLicense = "breadmenu.text.license";
    static BreadTextMaterialRequest = "breadmenu.text.materialrequest";
    static BreadTextNewRegion = "breadmenu.text.newregion";
    static BreadTextNewSchool = "breadmenu.text.newschool";
    static BreadTextNewCampuse = "breadmenu.text.newcampuse";
    static BreadTextPromoteClass = "breadmenu.text.newclass";
    static BreadTextNewTmpl = "breadmenu.text.newtmpl";
    static BreadTextNotifications = "breadmenu.text.notifications";
    static BreadTextOrders = "breadmenu.text.orders";
    static BreadTextRegionOrders = "breadmenu.text.regionorders";
    static BreadTextSearch = "breadmenu.text.search";
    static BreadTextInvitations = "breadmenu.text.invitations";
    static BreadTextStudentManualRegistration = "breadmenu.text.student.manualRegistration";
    static BreadTextMyActivity = "breadmenu.text.myactivity";
    static Search = "breadmenu.text.search";
    static BreadTextSettings = "breadmenu.text.settings";
    static BreadTextStudents = "breadmenu.text.students";
    static BreadTextMergeStudents = "breadmenu.text.mergeStudents";
    static BreadTextTeacher = "breadmenu.text.teacher";
    static BreadTextTemplates = "breadmenu.text.templates";
    static BreadTextSchoolTeachers = "breadmenu.text.schoolteachers";
    static BreadTextUnitPlan = "breadmenu.text.unitplan";
    static BreadTextPromoteStudents = "breadmenu.text.promotestudents";
    static BreadTextGroupManager = "breadmenu.text.groupmanager";
    static BreadTextMoveStudents = "breadmenu.text.movestudents";
    static BreadTextSupportInfo = "breadmenu.text.supportinfo";
    static BreadTextSchoolChangeHistory = "breadmenu.text.schoolchangehistory";
    static BreadTextSchoolVisitationHistory = "breadmenu.text.schoolvisitationhistory";
    static BreadTextTalkTimeAccess = "breadmenu.text.talktime";
    static BreadTextRewardPoints = "breadmenu.text.rewardpoints";
    static BreadTextTagManager = "breadmenu.text.tag-manager";
    static BreadTextTermManager = "breadmenu.text.term-manager";
    static BreadTextTeachers = "breadmenu.text.teachers";
    static BreadTextManageStudents = "breadmenu.text.managestudents";
    static BreadTextVerifyStudents = "breadmenu.text.studenverifystudents";
    static BtnBackList = "school.common.backlist";
    static BtnDelete = "school.common.delete";
    static BtnInfo = "school.common.info";
    static BtnRegister = "school.common.register";
    static BtnSave = "school.common.save";
    static BtnSaveCampusCart = "campus_cart.save.text";
    static BtnSubmit = "school.common.submit";
    static BtnUpdate = "school.common.update";
    static BtnSubmitCampusCart = "campus_cart.submit.text";
    static CampusAdd = "campus_adding.add.text";
    static CampusAddress = "campus_editing.address.text";
    static CampusAddress1 = "campus_editing.address1.text";
    static CampusAddress2 = "campus_editing.address2.text";
    static CampusAdministrator = "search.campusadmin.subtitle";
    static CampusCity = "campus_editing.city.text";
    static CampusDelete = "campus_editing.delete.text";
    static CampusDeleteError = "campus_editing.message.deleteerror";
    static CampusDisabled = "campus_editing.disabled.text";
    static CampusDisabledPrompt = "campus_editing.msg.disabledprompt";
    static CampusDisabledMessage = "campus_editing.msg.disabled";
    static CampusEnabledMessage = "campus_editing.msg.enabled";
    static CampusEditTitle = "campus_editing.edit.text";
    static CampusFax = "campus_editing.fax.text";
    static CampusIconAdmins = "campus.icon.admins";
    static CampusIconCart = "campus.icon.cart";
    static CampusIconOrder = "campus.icon.order";
    static CampusName = "campus_editing.name.text";
    static CampusGSNumber = "campus_editing.gscampusnumber.text";
    static CampusNewTitle = "campus_adding.new.text";
    static CampusPhone = "campus_editing.phone.text";
    static CampusPostalCode = "campus_editing.postalcode.text";
    static CampusSchoolSelect = "campus.schoolselect.text";
    static CampusState = "campus_editing.state.text";
    static CampusTitle = "campus.title";
    static CampusNameTitle = "campus.text.nametitle";
    static EditCampus = "campus.text.edit";
    static ClassMaterialRequest = "class.text.materialrequest";
    static ClassAdd = "class_adding.add.text";
    static ClassPromote = "class_adding.promote.text";
    static ClassBulkEdit = "class_adding.bulk.text";
    static BulkAction = "class.bulk.action";
    static BulkClassName = "class.bulk.classname";
    static BulkTerm = "class.bulk.term";
    static BulkStartDate = "class.bulk.startdate";
    static BulkEndDate = "class.bulk.enddate";
    static BulkCloseDate = "class.bulk.closedate";
    static BulkKeepTeachers = "class.bulk.keepteachers";
    static BulkKeepStudents = "class.bulk.keepstudents";
    static BulkLicenses = "class.bulk.licenses";
    static BulkLicenseType = "class.bulk.licensetype";
    static BulkCurriculumType = "class.bulk.curriculumtype";
    static BulkStudentAgeGrade = "class.bulk.studentagegrade";
    static BulkStartUnit = "class.bulk.startunit";
    static BulkUnitColumnTooltip = "class.bulk.unitcolumntooltip"
    static BulkMaxUnit = "class.bulk.maxunit";
    static BulkHasStartUnitPlan = "class.bulk.hasstartunitplan";
    static BulkKeepClassTimes = "class.bulk.keepclasstimes";
    static BulkClassTimes = "class.bulk.classtimes";
    static BulkTSIMinutesPerWeek = "class.bulk.tsiminutesperweek";
    static BulkTSILessonsPerWeek = "class.bulk.tsilessonsperweek";
    static BulkTSILessonsPerYear = "class.bulk.tsilessonsperyear";
    static BulkREPMinutesPerWeek = "class.bulk.repminutesperweek";
    static BulkREPLessonsPerWeek = "class.bulk.replessonsperweek";
    static BulkREPLessonsPerYear = "class.bulk.replessonsperyear";
    static BulkRevert = "class.bulk.revert";
    static BulkNone = "class.bulk.none";
    static BulkNew = "class.bulk.new";
    static BulkPromote = "class.bulk.promote";
    static BulkPromoted = "class.bulk.promoted";
    static BulkDuplicate = "class.bulk.duplicate";
    static BulkDuplicated = "class.bulk.duplicated";
    static BulkClose = "class.bulk.close";
    static BulkExecute = "class.bulk.execute";
    static BulkNewClass = "class.bulk.newclass";
    static BulkNotify = "class.bulk.notify";
    static BulkUnique = "class.bulk.unique";
    static BulkClassTimeException = "class.bulk.classtimeexception";
    static BulkClassLicenseTypeException = "class.bulk.licensetypeexception";
    static BulkEditClassSchoolNotExistException = "class.bulk.schoolnotexist";
    static BulkEditClassCampusNotExistException = "class.bulk.campusnotexist";
    static BulkEditClassNotExistException = "class.bulk.classnotexist";
    static BulkEditClassNameIsNullException = "class.bulk.nameisnull";
    static BulkEditClassInvalidLicenseTypeException = "class.bulk.invalidlicensetype";
    static BulkEditClassInvalidUnitRangeException = "class.bulk.invalidunitrange";
    static BulkEditClassInvalidAgeException = "class.bulk.invalidage";
    static BulkEditClassInvalidREPMinutesPerWeekException = "class.bulk.invalidrepminutesperweek";
    static BulkEditClassInvalidTSIMinutesPerWeekException = "class.bulk.invalidtsiminutesperweek";
    static BulkEditClassInvalidLicenseTypeChangeException = "class.bulk.invalidlicensetypechange";
    static BulkEditClassInvalidCloseDateException = "class.bulk.invalidclosedate";
    static BulkEditClassCampusAnnualPreparationCompleteException = "class.bulk.annualpreparationcomplete";
    static BulkEditClassInvalidLicenseCountException = "class.bulk.invalidlicensecount";
    static BulkEditClassNotExistStartUnitPlanException = "class.bulk.notexiststartunitplan";
    static BulkSaveandSubmit = "class.bulk.saveandsubmit";
    static BulkSave = "class.bulk.save";
    static BulkGoToUnitPlanManager = "class.bulk.gotounitplanmanager";
    static BulkSubmitConfirm = "class.bulk.submitconfirm";
    static BulkAnnualPreparationCompleteCheck = "class.bulk.annualpreparationcompletecheck";
    static BulkResetAnnualPreparation = "class.bulk.resetannualpreparation";
    static BulkResetAnnualPreparationTitle = "class.bulk.resetannualpreparationtitle";
    static ClassAddClassTime = "class_adding.addclasstime.text";
    static ClassEditClassTime = "class_editing.edit.class.time.text";
    static ClassAge = "class_editing.age.text";
    static ClassCampusNull = "class.msg.campusnull";
    static ClassCampusSelect = "class.campusselect.text";
    static ClassLicenseCount = "class.licensecount.text";
    static ClassLicenseCountContent = "class.licensecount.content";
    static ClassClassTime = "class_editing.classtime.text";
    static ClassCnt = "class_editing.cnt.text";
    static ClassDaysofWeek = "class_editing.daysofweek.text";
    static ClassDaysofWeekValid = "class_editing.daysofweekvalid";
    static ClassDelete = "class_editing.msg.deletetext";
    static ClassDeleteValid = "class_editing.msg.deletevalid";
    static ClassDigital = "class_editing.digitallicenses.text";
    static ClassDisabled = "class_editing.disabled.text";
    static ClassEditBackBtn = "region.invite.back";
    static ClassEditTitle = "class_editing.edittitle";
    static ClassManage = "class.text.manage";
    static ClassIconCart = "class.icon.cart";
    static EditClass = "class.text.edit";
    static ClassLessonsPerWeek = "class_editing.lessonsperweek";
    static ClassLessonsPerYear = "class_editing.lessonsperyear";
    static ClassLicence = "class_editing.licence";
    static ClassLicenseLimitInvalid = "class_license.licenselimitinvalid";
    static ClassLicenseTypeInvalid = "class_license.licensetypeinvalid";
    static ClassMaxUnit = "class_editing.maxunit";
    static ClassMaxUnitError4GS = "class.msg.maxunitoverflow4gs";
    static ClassMaxUnitError4LS = "class.msg.maxunitoverflow4ls";
    static ClassLicenseTypeError = "class.msg.invalidlicensetype";
    static ClassNexusLicenseTypeError = "class.msg.invalidlicensetypefornexus";
    static ClassMeetingCode = "class_editing.meetingcode";
    static ClassName = "class_editing.name";
    static ClassTerm = "class_editing.term";
    static ClassLicenseType = "class_editing.licenseType";
    static ClassCurriculumType = "class_editing.curriculumType";
    static ClassTermDateEmpty = "class_editing.term.empty";
    static ClassNewEditTitle = "class_adding.new";
    static ClassNewPromoteExisting = "class_adding.new.promoteexisting";
    static ClassNewSelect = "class_adding.new.select";
    static ClassNewSelectClassTip = "class_adding.new.selectclasstip";
    static ClassNewSource = "class_adding.new.source";
    static ClassNewSourceClassSelect = "class_adding.new.sourceclass.select";
    static ClassNewSourceCampusSelect = "class_adding.new.sourcecampus.select";
    static ClassNewDestination = "class_adding.new.destination";
    static ClassNewDestinationCampusSelect = "class_adding.new.destinationcampus.select";
    static ClassNewDestinationClassSelect = "class_adding.new.destinationclass.input";
    static ClassNewSelectSourceCampusTip = "class_adding.new.selectsourcecampustip";
    static ClassNewSelectSourceClassTip = "class_adding.new.selectsourceclasstip";
    static ClassNewSelectDestinationCampusTip = "class_adding.new.selectdestinationcampustip";
    static ClassNewStudentCount = "class_adding.new.studentcount";
    static ClassNewStartDate = "class_adding.new.startdate";
    static ClassNewStartDateTip = "class_adding.new.startdatetip";
    static ClassNewEndDate = "class_adding.new.enddate";
    static ClassPromoteTitle = "class_adding.new.promotetitle";
    static ClassSubstituteTeachers = "class_adding.new.promote_substituteteachers";
    static ClassMainTeacher = "class_adding.new.promote_mainteacher";
    static ClassPromoteSelectTeacher = "class_adding.new.promote_selectteachers";
    static ClassPromoteAllStudents = "class_adding.new.promoteallstudents";
    static ClassPromoteStudentsTitle = "class_adding.new.promotestudentstitle";
    static ClassPromoteBaseClassError = "class_adding.message.promptbaseclasserror";
    static ClassNewTitle = "class_adding.newtitle";
    static ClassNotes = "class_editing.notes";
    static ClassNotesForSchool = "class_editing.notesforschool";
    static ClassStartUnit = "class_editing.startunit";
    static ClassTeacher = "class_editing.teacher";
    static ClassTime = "class_editing.time";
    static ClassTimeDuration = "class_editing.time.duration";
    static ClassTimeEnd = "class_editing.time.end";
    static ClassTimeErrorEnd = "class_editing.time.errorend";
    static ClassTimeErrorStart = "class_editing.time.errorstart";
    static ClassTimeRepDuration = "class_editing.time.repduration";
    static ClassTimeStart = "class_editing.time.start";
    static ClassTimeTsiDuration = "class_editing.time.tsiduration";
    static ClassTimeTsiRepDuration = "class_editing.time.tsirepduration";
    static ClassTimeTsiRepDurationLessonPerWeek = "class_editing.time.tsirepdurationlessonperweek";
    static ClassTimeTsiRepDurationLessonPerYear = "class_editing.time.tsirepdurationlessonperyear";
    static ClassTimeType = "class_editing.time.type";
    static ClassTimeTypeOther = "class_editing.time.typeother";
    static ClassTimeTypeRep = "class_editing.time.typerep";
    static ClassTimeTypeTsi = "class_editing.time.typetsi";
    static ClassTimeTypeGrapeSEEDConnect = "class_editing.time.typeremotetsi";
    static ClassTitle = "class_editing.title";
    static ClassType = "class_editing.type";
    static ClassUnit = "class_editing.unit";
    static ClassUnitValid = "class_editing.unit.valid";
    static ClassUnitValidOverflow = "class_editing.unit.validoverflow";
    static ClassUnitValidOverflowCommon = "class_editing.unit.validoverflowcommon";
    static ClassUnitValidMax = "class_editing.unit.validmax";
    static ClassUnitValidInConsecutive = "class_editing.unit.validinconsecutive";
    static ClassUpClassTime = "class_editing.upclasstime";
    static ClassValidConnectClassTime = "class_editing.connect.classtime";
    static ClassValidNonConnectClassTime = "class_editing.nonconnect.classtime";
    static ClassWeekdays = "class_editing.weekdays";
    static ClassTimeDuplicate = "class_editing.time.duplicate";
    static ClassesColumnAgeGrade = "classlist.column.agegrade";
    static ClassesColumnClass = "classlist.column.class";
    static ClassesColumnCount = "classlist.column.count";
    static ClassesColumnDays = "classlist.column.days";
    static ClassesColumnDaysTSI = "classlist.column.daystsi";
    static ClassesColumnDaysREP = "classlist.column.daysrep";
    static ClassesColumnDuration = "classlist.column.duration";
    static ClassesColumnDurationTSI = "classlist.column.durationtsi";
    static ClassesColumnDurationREP = "classlist.column.durationrep";
    static ClassesColumnSchool = "classlist.column.school";
    static ClassesColumnStudentCount = "classlist.column.studentcount";
    static ClassesColumnTrainerName = "classlist.column.trainerName";
    static ClassesColumnTeacher = "classlist.column.teacher";
    static ClassesColumnTSIREP = "classlist.column.tsirep";
    static ClassesColumnUnit = "classlist.column.unit";
    static ClassesListCount = "classlist.list.count";
    static ClassesListDuration = "classlist.list.duration";
    static ClassesTitle = "classlist.title";
    static ClassTeacherLastDeleteMessage = "class_teacher.remove.prompt";
    static SchoolTrainerLastDeleteMessage = "school_trainer.remove.prompt";
    static ClassTeacherDeleteModalYesText = "class_teacher.removeteacher.removecompletely";
    static SchoolTrainerDeleteModalYesText = "school_trainer.removetrainer.removecompletely";
    static SchoolTrainerDeleteModalNoText = "school_trainer.removetrainer.keepinregion";
    static ClassTeacherDeleteModalNoText = "class_teacher.removeteacher.keepinschool";
    static ClassTeacherNoneDeleteMessage = "class_teacher.message.deletenotexists";
    static CodeHasBeenUsed = "invitation_start.msg.usedcode";
    static CommonErrorInvalidVisitation = "school.common.errorinvalidvisitation";
    static CommonErrorSurveyHasSubmitted = "school.common.errorsurveyhassubmitted";
    static CommonErrorSurveyNotFound = "school.common.errorsurveynotfound";
    static CommonErrorItemHasSubmitted = "school.common.erroritemhassubmitted";
    static CommonErrorNoPermission = "school.common.errornopermission";
    static CommonErrorVideoExisted = "school.common.videoexisted";
    static CommonErrorVideoTypeNotMatch = "school.common.videotypenotmatch";
    static CommonErrorAttachmentFileTypeNotMatch = "school.common.attachmentfiletypenotmatch";
    static CommonErrorVideoSizeExceed = "school.common.videsizeexceed";
    static CommonErrorAttachmentFileSizeExceed = "school.common.attachmentfilesizeexceed";
    static CommonErrorVideoTotalSizeExceed = "school.common.videtotalsizeexceed";
    static CommonErrorInvalidArgument = "school.common.errorinvalidargument";
    static CommonErrorNoTarget = "school.common.errornopermissionupdate";
    static CommonErrorNoPermissionNew = "school.common.errornotarget";
    static CommonErrorNoPermissionUpdate = "school.common.errornopermissionnew";
    static CommonErrorTargetSaveFailed = "school.common.errortargetsavefailed";
    static EditProfile = "school.common.editprofile";
    static EmailHasExisted = "invitation_registernew.msg.emailhasexisted";
    static EmailInvalid = "invitation_registernew.msg.emailinvalid";
    static GoToHome = "invitation_start.getstartbutton.text";
    static HomeBulletinBoard = "home.item.bulletinboard";
    static HomeUpcomingEvents = "home.item.upcomingevents";
    static HomeCoachDashboard = "home.item.coachdashboard";
    static HomeTeacherDashboard = "home.item.teacherdashboard";
    static VisitationAcceptInvitation = "visitation.accept.invitation";
    static VisitationAcceptContent = "visitation.accept.content";
    static VisitationSuggestAlternate = "visitation.suggest.alternate";
    static VisitationDeleteInvitation = "visitation.delete.invitation";
    static VisitationUploadVideoExceedMaxCount = "visitation.video.exceed.count";
    static VisitationRequestComments = "visitation.request.comments";
    static CoachSchoolSelectPlaceholder = "coach.select.placeholder";
    static CoachText = "coach.text";
    static CoachSchool = "coach.school";
    static CoachDaysSinceLastReview = "coach_days.since.lastreview";
    static CoachState = "coach.state";
    static CoachTotal = "coach.total";
    static CoachRemaining = "coach.remaining";
    static CoachInvitationSent = "coach.invitation.sent";
    static CoachSendReminder = "coach.send.reminder";
    static CoachGenerateAgain = "coach.generate.again";
    static CoachVisiting = "coach.visiting";
    static CoachViewDetails = "coach.view.details";
    static CoachCompleteVisit = "coach.complete.visit";
    static CoachReviewDue = "coach.review.due";
    static CoachDate = "coach.date";
    static CoachDownloadVideo = "coach.download.video";
    static VisitationRequestVisitation = "visitation_request.visitation";
    static VisitationRequestText = "visitation_request.text";
    static VisitationRequestSubject = "visitation_request.subject";
    static VisitationRequestTentativeDates = "visitation_request.tentative.dates";
    static VisitationRequestExpiryDate = "visitation_request.expiry.date";
    static VisitationRequestRespondBy = "visitation_request.respond.by";
    static VisitationRequestSetDate = "visitation_request.set.date";
    static VisitationRequestFixDate = "visitation_request.fix.date";
    static VisitationRequestSendInvitations = "visitation_request.send.invitations";
    static VisitationRequestTeacherSchedule = "visitation_request.teacher.schedule";
    static VisitationRequestToText = "visitation_request.to.text";
    static VisitationRequestCCText = "visitation_request.cc.text";
    static VisitationRequestDayText = "visitation_request.day.text";
    static VisitationRequestStartText = "visitation_request.start.text";
    static VisitationRequestEndText = "visitation_request.end.text";
    static VisitationRequestToSectionTip = "visitation_request.tosection.tip";
    static VisitationRequestOnsite = "visitation_request.onsite";
    static VisitationRequestLVA = "visitation_request.lva";
    static VisitationRequestSelect = "visitation_request.select";
    static VisitationRequestCampus = "visitation_request.campus";
    static VisitationRequestLastVisited = "visitation_request.last.visited";
    static VisitationRequestVisit = "visitation_request.visit";
    static VisitationRequestSelectTeachers = "visitation_request.select.teachers";
    static VisitationRequestSelectTeachersRequireMsg = "visitation_request.select.teachers.requiremsg";
    static VisitationRequestViewClassSchedule = "visitation_request.view.classschedule";
    static VisitationRequestInformSchool = "visitation_request.inform.school";
    static VisitationRequestOnsiteExceedLimit = "visitation_request.onsite.exceedlimit";
    static VisitationRequestLVAExceedLimit = "visitation_request.lva.exceedlimit";
    static VisitationRequestNotSelectCmapus = "visitation_request.notselect.campus";
    static VisitationRequestNoToAdminTip = "visitaiton_request.notoadmin.tip";
    static VisitationRequestNoCCAdminTip = "visitaiton_request.noccadmin.tip";
    static VisitationRequestNoClass = "visitation_request.no.class";
    static VisitationRequestNoTeacher = "visitation_request.no.teacher";
    static VisitationCanNotDeleteTeacher = "visitation.cannotdelete.teacher";
    static VisitationTeacherNotExist = "visitation.teacher.notexist";
    static VisitationNotSendSupportQuestionnaire = "visitation.notsend.supportquestionnaire";
    static VisitationCanNotRemoveCoach = "visitation.cannotremove.coach";
    static VisitationScheduleVisit = "visitation_schedule.visit.text";
    static VisitationScheduleExpiryDate = "visitation_schedule.expiry.date";
    static VisitationScheduleAccept = "visitation_schedule.accept.text";
    static VisitationScheduleProposeNewTime = "visitation_schedule.propose.newtime";
    static VisitationSendFeedbackTips = "visitation.sendfeedback.tips";
    static VisitationUndeterminedBack = "visitation.undetermined.back";
    static OngoingVisit = "ongoing.visit";
    static VisitationMoveNextText = "visitation.move_next.text";
    static VisitationCompleteText = "visitation.complete.text";
    static VisitationSendPrevisitationForm = "visitation.send.previsitation_form";
    static VisitationTeacherResponseHeader = "visitation.teacherresponse.header";
    static VisitationTeacherResponseExport = "visitation.teacherresponse.export";
    static VisitationTeacherResponsePdfTeacherSupport = "visitation.teacherresponse.pdf.teachersupport";
    static VisitationTeacherResponsePdfSupportDetails = "visitation.teacherresponse.pdf.supportdetails";
    static VisitationTeacherResponsePdfStartDate = "visitation.teacherresponse.pdf.startdate";
    static VisitationTeacherResponsePdfSchool = "visitation.teacherresponse.pdf.school";
    static VisitationTeacherResponsePdfCampus = "visitation.teacherresponse.pdf.campus";
    static VisitationTeacherResponsePdfClass = "visitation.teacherresponse.pdf.class";
    static VisitationTeacherResponsePdfTeacherName = "visitation.teacherresponse.pdf.teachername";
    static VisitationTeacherResponsePdfSupportQuestionnaire = "visitation.teacherresponse.pdf.supportquestionnaire";
    static VisitationTeacherResponsePdfSubmittedOn = "visitation.teacherresponse.pdf.submittedon";
    static VisitationViewResponse = "visitation.view.response";
    static VisitationYetToSend = "visitation.yet.send";
    static VisitationIgnoreTeacher = "visitation.ignore.teacher";
    static VisitationIgnoredText = "visitation.ignored.text";
    static VisitationReplied = "visitation.replied";
    static VisitationPending = "visitation.pending";
    static VisitationFormStatus = "visitation.form.status";
    static VisitationStatus = "visitation.status";
    static VisitationAction = "visitation.action";
    static VisitationChat = "visitation.chat";
    static VisitationTeacher = "visitation.teacher";
    static VisitationClasses = "visitation.classes";
    static VisitationSaveDraft = "visitation.save.draft";
    static VisitationTeacherFeedbackSendText = "visitation.teacher_feedback.send.text";
    static VisitationTeacherQuestionnaireSendText = "visitation.teacher_questionnaire.send.text";
    static VisitationCoachExperienceSurveySendText = "visitation.coach_experience_survey.send.text";
    static VisitationSendText = "visitation.send.text";
    static VisitationCampusText = "visitation.campus.text";
    static VisitationInvitationAcceptDate = "visitation.invitation.accept_date";
    static VisitationSchoolNotes = "visitation.school.notes";
    static VisitationSchoolNotesNew = "visitation.school.notesnew";
    static VisitationPostForm = "visitation.post.form";
    static VisitationNotesForAdmins = "visitation.notes.for_admins";
    static VisitationNotesForSchool = "visitation.notes.for_school";
    static VisitationForInternalStaff = "visitation.internal.staff";
    static VisitationForExternalStaff = "visitation.external.staff";
    static VisitationCompleteVisit = "visitation.complete.visit";
    static VisitationSendFeedback = "visitation.send.feedback";
    static VisitationViewFeedback = "visitation.view.feedback";
    static VisitationOneToOneFeedback = "visitation.one_to_one.feedback";
    static VisitationLastFeedback = "visitation.last.feedback";
    static VisitationIncludeLastVisit = "visitation.include.last_visit";
    static VisitationNoteText = "visitation.note.text";
    static VisitationQuestionnaireNote = "visitation.questionnaire.note";
    static VisitationOnsiteNoteText = "visitation.onsite.notetext";
    static VisitationLVANoteText = "visitation.lva.notetext";
    static VisitationLVALinkText = "visitation.lva.linktext";
    static VisitationLVALinkNote = "visitation.lva.linknote";
    static VisitationLVAResourceTypeVideo = "visitation.lva.videotype";
    static VisitationLVAResourceTypeLink = "visitation.lva.linktype";
    static VisitationVideoText = "visitation.video.text";
    static VisitationVideoDescription = "visitation.video.description";
    static VisitationVideoNote = "visitation.video.note";
    static VisitationLVAAttachmentText = "visitation.lva.attachment.text";
    static VisitationVideoTableNote = "visitation.video.tablenote";
    static VisitationVideoTableAttachment = "visitation.video.table_attachment";
    static VisitationVideoUploadTime = "visitation.video.uploadtime";
    static VisitationVideoSize = "visitation.video.size";
    static VisitationDateOfVisit = "visitation.date.visit";
    static VisitationResponseText = "visitation.response.text";
    static VisitationFeedbackText = "visitation.feedback.text";
    static VisitationGiveCoachFeedback = "visitation.give_coach_feedback";
    static VisitationCoachName = "visitation.coach.name";
    static VisitationDateOfCompletion = "visitation.date_of_completion";
    static VisitationCompleteMessage = "visitation.complete.message";
    static VisitationCompleteDescription = "visitation.complete.description";
    static VisitationComfirmTitle = "visitation.confirm.title";
    static VisitationVideoUploadFailed = "visitation.videoupload.failed";
    static VisitationVideoUploadFailedText = "visitation.videoupload.failedtext";
    static VisitationVideoUploadSuccessText = "visitation.videoupload.successtext";
    static VisitationNotAllClassesHasVideos = "visitation.notallclasses.hasvideos";
    static VisitationVideoStop = "visitation.video.stop";
    static VisitationUploadAtLeastOneVideo = "visitation.upload.atleastonevideo";
    static VisitationNotAllSendFeedbacks = "visitation.notall.sendfeedbacks";
    static VisitationCoachFeedbackWarnTips = "visitation.coachfeedback.warntips";
    static VisitationSurveyAnswerPlaceHolder = "visitation.survey.answer.placeholder";
    static VisitationAddResourcesTips = "visitation.addresources.tips";
    static VisitationNoUpcomingEventsTips = "visitation.noupcomingevents.tips";
    static VisitationDayText = "visitation.day.text";
    static VisitationDaysText = "visitation.days.text";
    static VisitationDayOverdue = "visitation.day.overdue";
    static VisitationDaysOverdue = "visitation.days.overdue";
    static VisitationDueToday = "visitation.due.today";
    static VisitationSendReminderTips = "visitation.sendreminder.tips";
    static VisitationOnsiteContent = "visitation.onsite.content";
    static VisitationLVAContent = "visitation.lva.content";
    static VisitationRequestTypeText = "visitation_request.type.text";
    static VisitationRequestCampusText = "campus_cart.item.campus";
    static VisitationChannelPrep = "visitation.channel.prep";
    static VisitationChannelObservation = "visitation.channel.observation";
    static VisitationChannelReview = "visitation.channel.review";
    static VisitationScheduleDate = "visitation.schedule.date";
    static VisitationPrevisitationForm = "visitation.previsitation.form";
    static VisitationPrevisitationSurveySubmit = "visitation.previsitation.survey.submit";
    static VisitationVisitSchool = "visitation.visit.school";
    static VisitationVideoManager = "visitation.video.manager";
    static VisitationTeacherReview = "visitation.teacher.review";
    static VisitationTeacherReviewSurveySubmit = "visitation.teacher.review.survey.submit";
    static VisitationSchoolReview = "visitation.school.review";
    static VisitationFeedback = "visitation.feedback";
    static VisitationCoachFeedback = "visitation.coach.feedback";
    static VisitationSendForm = "visitation.send.form";
    static VisitationSendReminder = "visitation.send.reminder";
    static VisitationViewResponseText = "visitation.viewresponse.text";
    static VisitationRemindSendVideo = "visitation.remind.sendvideo";
    static VisitationHasSent = "visitation.has.sent";
    static VisitationHasViewed = "visitation.has.viewed";
    static VisitationAcceptanceDate = "visitation.acceptance.data";
    static VisitationPrevisitReminderTitle = "visitation.previsitreminder.title";
    static VisitationPrevisitReminderContent = "visitation.previsitreminder.content";
    static VisitationVideoReminderContent = "visitation.videoreminder.content";
    static VisitationTeachersTitle = "breadmenu.text.teacher";
    static VisitationTeacherMainTag = "visitation.teacher.maintag";
    static VisitationVideosText = "visitation.videos.text";
    static VisitationVideosRetentionText = "visitation.videos.retentiontxt";
    static VisitationVideoIsDeleted = "visitation.video.isdeleted";
    static VisitationQuestionIsRequired = "visitation.question.isrequired";
    static VisitationAnswerIsRequired = "visitation.answer.isRequired";
    static VisitationSelectClassTips = "visitation.selectclass.tips";
    static VisitationUploadVideoTips = "visitation.uploadvideo.tips";
    static VisitationClickOrDragTips = "visitation.click_drag.tips";
    static VisitationClickOrDragAttachmentTips = "visitation.click_drag.attachment_tips";
    static VisitationInProgress = "visitation.in.progress";
    static VisitationNotSendPrevisit = "visitation.notsend.previsit";
    static VisitationMessageFromCoach = "visitation.message.fromcoach";
    static VisitationCheckLicenseAgreement = "visitation.check.licenseagreement";
    static VisitationVideoCopyRight = "visitation.video.copyright";
    static VisitationNotGiveFeedback = "visitation.notgive.feedback";
    static VisitationNotGiveCoachFeedback = "visitation.notgive.coachfeedback";
    static VisitationViewFeedbackHistory = "visitation.viewfeedback.history";
    static VisitationSchoolStatusCreate = "class.modal.buttoncreate";
    static VisitationShoolStatusNew = "visitation.schoolstatus.create";
    static VisitationSupportedVideoType = "visitation.supported.videotype";
    static VisitationCoachVisitationText = "visitation.coachvisitation.text";
    static VisitationLVAVisitationText = "visitation.lvavisitation.text";
    static VisitationOngoingVisit = "visitation.ongoing.visit";
    static VisitationPendingText = "visitation.pending.text";
    static VisitationSentText = "visitation.sent.text";
    static VisitationReceivedText = "visitation.received.text";
    static VisitationNotExist = "visitation.notexist";
    static VisitationTeacherClassName = "visitation.teacherClass.nameCol";
    static VisitationTeacherClassAge = "visitation.teacherClass.ageCol";
    static VisitationTeacherClassUnit = "visitation.teacherClass.unitCol";
    static VisitationTeacherClassStudents = "visitation.teacherClass.studentsCol";
    static VisitationTeacherClassClassHours = "visitation.teacherClass.classHourCol";
    static VisitationTeacherClassLessonNumber = "visitation.teacherClass.lessonNumberCol";
    static VisitationTeacherClassSelect = "visitation.teacherClass.select";
    static VisitationTeacherClassTeachingClass = "visitation.teacherClass.teachingClass";
    static VisitationCoachShare = "visitation.coach.share";
    static UpcomingEventExpiryEarlierStart = "upcomingevent.expiry_earlier_start";
    static UpcomingEventStartLaterExpiry = "upcomingevent.start_later_expiry";
    static UpcomingEventStartEarlierRespondBy = "upcomingevent.start_earlier_respondby";
    static UpcomingEventRespondByLaterStart = "upcomingevent.respondby_later_start";
    static UpcomingEventShowExpiredEvents = "upcomingevent.show_expired_events";
    static HomeClasses = "home.item.classes";
    static HomeInvitation = "home.item.invitation";
    static HomeLMS = "home.item.lms";
    static HomeMaterial = "home.item.material";
    static HomeResources = "home.item.resources";
    static HomeSchools = "home.item.schools";
    static HomeSetting = "home.item.setting";
    static HomeWelcome = "home.item.welcome";
    static IconDelete = "school.icon.delete";
    static IconEdit = "school.icon.edit";
    static InvalidNameOrPassword = "invitation_useexisting.msg.invalid";
    static InvitationPortalInvitations = "invitation.portal.invitation";
    static InvitationUnderscoreEmailWarn = "invitation.msg.underscorewarn";
    static InvitationAccountManagerTitle = "invitation_useexistingfinish.accountmanagersubtitle";
    static InvitationCampusAdminTitle = "invitation_useexistingfinish.campusadminsubtitle";
    static InvitationSchoolAdminTitle = "invitation_useexistingfinish.schooladminsubtitle";
    static InvitationSchoolInvitation = "campus.school.invitation";
    static InvitationSchoolInvitationHistory = "campus.school.invitationhistory";
    static InvitationSchoolInvitationHistoryCode = "campus.school.invitationhistorycode";
    static InvitationSchoolInvitationHistoryName = "campus.school.invitationhistoryname";
    static InvitationSchoolInvitationHistoryStudent = "campus.school.invitationhistorystudent";
    static InvitationSchoolInvitationHistoryType = "campus.school.invitationhistorytype";
    static InvitationSchoolInvitationHistoryCampus = "campus.school.invitationhistorycampus";
    static InvitationSchoolInvitationHistoryClass = "campus.school.invitationhistoryclass";
    static InvitationSchoolInvitationHistoryClassType = "campus.school.invitationhistoryclasstype";
    static InvitationSchoolInvitationHistoryLanguage = "campus.school.invitationhistorylanguage";
    static InvitationSchoolInvitationHistoryStatus = "campus.school.invitationhistorystatus";
    static InvitationSchoolInvitationHistoryAcceptedDate = "campus.school.invitationhistoryaccepteddate";
    static InvitationSchoolInvitationHistoryCreatedDate = "campus.school.invitationhistorycreateddate";
    static InvitationSchoolInvitationHistoryExpirationDate = "campus.school.invitationhistoryexpirationdate";
    static InvitationSchoolInvitationHistoryPrintedStatus = "campus.school.invitationhistoryprintedstatus";
    static InvitationSchoolInvitationHistoryPrint = "campus.school.invitationhistoryprint";
    static InvitationSchoolInvitationHistorySendEmail = "campus.school.invitationhistorysendemail";
    static InvitationSchoolInvitationHistoryResendEmail = "campus.school.invitationhistoryemailresend";
    static InvitationSchoolInvitationHistoryRenew = "campus.school.invitationhistoryrenew";
    static InvitationSchoolInvitationHistoryPrintButton = "campus.school.invitationhistoryprintbutton";
    static InvitationSchoolInvitationHistoryEmailButton = "campus.school.invitationhistoryemailbutton";
    static InvitationSchoolInvitationHistoryPrintOrEmail = "campus.school.invitationhistoryprintoremail";
    static InvitationSchoolInvitationHistoryEmail = "campus.school.invitationhistoryemail";
    static InvitationSchoolInvitationHistoryPhone = "campus.school.invitationhistoryphone";
    static InvitationSchoolInvitationHistoryTypeTeacher = "campus.school.invitationhistorytypeteacher";
    static InvitationSchoolInvitationHistoryTypeParent = "campus.school.invitationhistorytypeparent";
    static InvitationSchoolInvitationHistoryTypeSchool = "campus.school.invitationhistorytypeschool";
    static InvitationSchoolInvitationHistoryTypeCampus = "campus.school.invitationhistorytypecampus";
    static InvitationSchoolInvitationHistoryCreatedDate7 = "campus.school.invitationhistorycreateddate7";
    static InvitationSchoolInvitationHistoryCreatedDate30 = "campus.school.invitationhistorycreateddate30";
    static InvitationSchoolInvitationHistoryCreatedDate60 = "campus.school.invitationhistorycreateddate60";
    static InvitationSchoolInvitationHistoryCreatedDate90 = "campus.school.invitationhistorycreateddate90";
    static InvitationSchoolInvitationHistorySearch = "campus.school.invitationhistorysearch";
    static InvitationSchoolInvitationHistoryStatusAll = "campus.school.invitationhistorystatusall";
    static InvitationSchoolInvitationHistoryStatusPending = "campus.school.invitationhistorystatuspending";
    static InvitationSchoolInvitationHistoryStatusAccepted = "campus.school.invitationhistorystatusaccepted";
    static InvitationSchoolInvitationHistoryStatusExpired = "campus.school.invitationhistorystatusexpired";
    static InvitationSchoolInvitationHistoryField = "campus.school.invitationhistoryfield";
    static InvitationSchoolInvitationHistoryValue = "campus.school.invitationhistoryvalue";
    static InvitationSchoolInvitationHistoryEmailTitle = "campus.school.invitationhistoryemailtitle";
    static InvitationSchoolInvitationHistoryPrintTitle = "campus.school.invitationhistoryprinttitle";
    static InvitationSchoolInvitationHistorySameTypeErrorMsg = "campus.school.invitationhistorysametypeerrormsg";
    static InvitationHistorySendSMS = "campus.school.invitationhistorysendSMS";
    static InvitationHistoryResendSMS = "campus.school.invitationhistorySMSresend";
    static InvitationHistorySMSInvitations = "campus.school.invitationhistorySMSInvitations";
    static InvitationHistoryGridHeadPhone = "campus.school.invitationhistoryGridHeadPhone";
    static InvitationHistoryGridFilterPhone = "campus.school.invitationhistoryGridFilterPhone";
    static InvitationHistoryModalSendSMS = "campus.school.invitationhistoryModalSendSMS";
    static InvitationHistoryModalSMSTitle = "campus.school.invitationhistoryModalSMSTitle";
    static InvitationHistoryPreModalPhoneTitle = "campus.school.invitationhistoryPreviewModalPhoneTitle";
    static InvitationHistoryPreModalEmailTitle = "campus.school.invitationhistoryPreviewModalEmailTitle"
    static InvitationPortalEmailInvitationHeading = "invitations.portal.emailinvitationheading";
    static InvitationPortalPrintInvitationHeading = "invitations.portal.printinvitationheading";
    static InvitationPortalEmailButtonText = "invitations.portal.emailbuttontext";
    static InvitationPortalPreviewButtonText = "invitations.portal.previewbuttontext";
    static InvitationPortalDownloadButtonText = "invitations.portal.downloadbuttontext";
    static InvitationPortalCancelButtonText = "invitations.portal.cancelbuttontext";
    static InvitationSchoolInvitationTemplates = "campus.school.invitationtemplates";
    static InvitationTeacherTitle = "invitation_useexistingfinish.teachersubtitle";
    static InvitationAccountNull = "invitation_useexisting.msg.notfound";
    static InvitationCodeInvalidConsumed = "invitation_start.msg.usedcode";
    static InvitationCodeTitle = "invitation_start.msg.coderequired";
    static InvitationCurrentAccount = "invitation_userselect.usecurrent.text";
    static InvitationCurrentText = "invitation_userselect.usecurrent.desc";
    static InvitationDisabled = "invitation_useexisting.msg.disabled";
    static TeaherAlreadyExists = "invitation_useexisting.msg.alreadyexists";
    static InvitationEmail = "class_teacher.email.text";
    static InvitationPhone = "class_teacher.phone.text";
    static InvitationEmailPhone = "class_teacher.emailphone.text";
    static InvitationExistingAccount = "invitation_userselect.useexisted.text";
    static InvitationExistingText = "invitation_userselect.useexisted.desc";
    static InvitationHasBeenRemoved = "invitation_start.msg.coderdeleted";
    static InvitationHasInvited = "invitation.msg.hasinvited";
    static InvitationHasPrimary = "invitation.msg.hasprimary";
    static InvitationHasSubstitue = "invitation.msg.hassubtitue";
    static InvitationIconEmail = "invitation.common.iconemail";
    static InvitationInviteAccountManagers = "invitation.common.accountmanagerstext";
    static InvitationInviteAdmins = "invitation.common.admintext";
    static InvitationInviteButton = "invitation.common.invitebuttontext";
    static InvitationInviteTeacher = "class_teacher.inviteteacher.text";
    static InvitationInviteTemp = "invitation.common.templatetext";
    static InvitationInviteTitleAccountManagers = "account_manager.inviteadmin.text";
    static InvitationInviteTitleAdmin = "school_admin/campus_admin.inviteadmin.text";
    static InvitationInviteSchoolTrainer = "school_trainer.text";
    static InvitationInviteRegionTrainer = "region_trainer.text";
    static RegionTrainingManager = "region_trainer.trainingmanager.text";
    static RegionTrainerSchoolAssociationError = "region_trainer.coachschool.association.error";
    static InvitationInviteTitleTeacher = "class_teacher.inviteteachermodal.text";
    static InvitationName = "invitation.common.nametext";
    static InvitationNext = "invitation.common.nexttext";
    static InvitationPlaceHoldEmail = "invitation.common.emailplaceholder";
    static InvitationPlaceHoldName = "invitation.common.nameplaceholder";
    static InvitationRegisterText = "invitation_userselect.registernew.desc";
    static InvitationRemoveInvitation = "invitation.msg.removeinvitation";
    static InvitationRemoveInvitationTitle = "invitation.msg.removeinvitationtitle";
    static InvitationRemoveUser = "invitation.msg.removeuser";
    static InvitationSame = "invitation.msg.invitationsame";
    static InvitationSendEmail = "invitation.msg.sendemail";
    static InvitationSendFail = "invitation.msg.sendfail";
    static InvitationSendPhone = "invitation.msg.sendphone";
    static InvitationSubject = "invitation_useexistingfinish.title";
    static InvitationMsgSuccess = "invitation.msg.success";
    static InvitationTemplate = "class.modal.templatelabel";
    static InvitationTemplateErrorCannotDelete = "invitationtemplatelist.msg.delete";
    static InvitationTemplateErrorCannotUpdateInvitationType = "invitationtemplate_editing.msg.update";
    static InvitationTemplateErrorCannotUpdateRegion = "invitationtemplate_editing.msg.updateregion";
    static InvitationTemplateErrorCannotUpdateSchool = "invitationtemplate_editing.msg.updateschool";
    static InvitationTemplateFieldContent = "invitationtemplate_editing.emailbody.text";
    static InvitationTemplateFieldContentTag = "invitationtemplate_editing.tags.text";
    static InvitationTemplateFieldContentInvalid = "invitationtemplate_editing.msg.emailbodytags";
    static InvitationTemplateFieldBackgroundImage = "invitationtemplate_editing.backgroundImage.text";
    static InvitationTemplateFieldBackgroundImageSize = "invitationtemplate_editing.msg.backgroundimagesize";
    static InvitationTemplateFieldBackgroundImageLayout = "invitationtemplate_editing.backgroundImagelayout.text";
    static InvitationTemplateFieldDisabled = "invitationtemplate_editing.disabled.text";
    static InvitationTemplateFieldRegion = "invitationtemplate_editing.language.region";
    static InvitationTemplateFieldSchool = "invitationtemplate_editing.language.school";
    static InvitationTemplateFieldLanguage = "invitationtemplate_editing.language.text";
    static InvitationTemplateFieldLanguageArabic = "invitationtemplate_editing.language.arabic";
    static InvitationTemplateFieldLanguageChinese = "invitationtemplate_editing.language.chinese";
    static InvitationTemplateFieldLanguageEnglish = "invitationtemplate_editing.language.english";
    static InvitationTemplateFieldLanguageJapanese = "invitationtemplate_editing.language.japanese";
    static InvitationTemplateFieldLanguageKorean = "invitationtemplate_editing.language.korean";
    static InvitationTemplateFieldLanguageMalaysia = "invitationtemplate_editing.language.malaysia";
    static InvitationTemplateFieldLanguageMongolian = "invitationtemplate_editing.language.mongolian";
    static InvitationTemplateFieldLanguageRussian = "invitationtemplate_editing.language.russian";
    static InvitationTemplateFieldLanguageSpanish = "invitationtemplate_editing.language.spanish";
    static InvitationTemplateFieldLanguageVietnamese = "invitationtemplate_editing.language.vietnamese";
    static InvitationTemplateFieldLanguageThailand = "invitationtemplate_editing.language.thailand";
    static InvitationTemplateFieldName = "invitationtemplate_editing.name.text";
    static InvitationTemplateFieldSubject = "invitationtemplate_editing.subject.text";
    static InvitationTemplateFieldType = "invitationtemplate_editing.type.text";
    static InvitationTemplateFieldChannel = "invitationtemplate_editing.channel.text";
    static InvitationTemplateFieldChannelEmail = "invitation.template.invitationchannelemail";
    static InvitationTemplateFieldChannelPhone = "invitation.template.invitationchannelphone";
    static InvitationTemplateFieldTypeCampusAdmin = "invitationtemplate_editing.type.campusadmin";
    static InvitationTemplateFieldTypeGlobalHead = "invitationtemplate_editing.type.globalhead";
    static InvitationTemplateFieldTypeParent = "invitationtemplate_editing.type.parent";
    static InvitationTemplateFieldTypeRegionAdmin = "invitationtemplate_editing.type.regionadmin";
    static InvitationTemplateFieldTypeAccountManager = "invitationtemplate_editing.type.accountmanager";
    static InvitationTemplateFieldTypeSchoolAdmin = "invitationtemplate_editing.type.schooladmin";
    static InvitationTemplateFieldTypeSystemAdmin = "invitationtemplate_editing.type.systemadmin";
    static InvitationTemplateFieldTypeTeacher = "invitationtemplate_editing.type.teacher";
    static InvitationTemplateFieldTypeTrainer = "invitationtemplate_editing.type.trainer";
    static InvitationTemplateFieldTypeTrainingAdmin = "invitationtemplate_editing.type.trainingadmin";
    static InvitationTemplatePageTitleEdit = "invitationtemplate_editing.title";
    static InvitationTemplatePageTitleList = "invitationtemplatelist.title";
    static InvitationTemplatePageSearchPlaceHolderMultiRegion = "invitationtemplatelist.search.placeholdermultiregion";
    static InvitationTemplatePageSearchPlaceHolderSingleRegion = "invitationtemplatelist.search.placeholdersingleregion";
    static InvitationTemplatePageTitleNew = "invitationtemplate_adding.title";
    static InvitationTemplatePromptDeleteMessage = "invitationtemplate_editing.msg.delete";
    static InvitationTemplatePromptDeleteTitle = "invitationtemplate_editing.msg.deletetitle";
    static InvitationTemplatePreviewDialogTitle = "invitationtemplate_editing.previewdialog.title";
    static InvitationTemplateFieldTypeContentAdmin = "invitationtemplate_editing.type.contentAdmin";
    static InvitationTitleAccountManages = "account_manager.title";
    static InvitationTitleCampus = "campus_admin.title";
    static InvitationTitleSchool = "school_admin.title";
    static InvitationTitleTeacher = "class_teacher.title";
    static InvitationType = "";
    static InvitationValidCodes = "invitation_start.code.text";
    static InvitationValidErrMsgClassInvalid = "student.msg.classinvalid";
    static InvitationValidErrMsgClassTeacherInvalid = "student.msg.teacherinvalid";
    static InvitationValidErrMsgCodeInvalid = "student.msg.invitationcodeinvalid";
    static InvitationValidErrMsgGenerateCode = "student.msg.codegenerateerror";
    static InvitationValidErrMsgTemplateNone = "student.msg.templatenoexists";
    static InvitationValidErrMsgTemplateType = "student.msg.templatetypeinvalid";
    static InvitationValidErrMsg = "invitation_start.msg.codeinvalid";
    static InvitationValidErrMsgIsApplied = "invitation_start.msg.codeisApplied";
    static InvitationFailedTitle = "invitation_start.msg.oops";
    static InvitationFailed = "invitation_start.msg.failed";
    static InvitationSuccessTitle = "invitation_useexisting_finished.item.title";
    static InvitationSuccess = "invitation_useexisting_finished.item.successcontent";
    static LicenseBtnSaveClose = "class_license.button.saveclose";
    static LicenseBtnSaveMaterial = "class_license.button..savematerial";
    static LicenseChange = "class_license.item.change";
    static LicenseChangeHistory = "class_license.item.changehistory";
    static LicenseDigital = "class_license.item.digital";
    static LicenseDigitalAccount = "class_license.item.studentcountlabel";
    static LicenseDigitalAccountCnt = "class_license.item.studentcount";
    static LicenseDigitalUpper = "class_license.item.digitalupper";
    static LicenseDualUpper = "class_license.item.dualupper";
    static LicenseStudentName = "class_license.student.name";
    static LicenseStudentEnglishName = "class_license.student.englishname";
    static LicenseStudentBirthday = "class_license.student.birthday";
    static LicenseStudentGender = "class_license.student.gender";
    static LicenseStudentRegisteredDate = "class_license.student.registereddate";
    static LicenseStudentTips = "class_license.student.tips";
    static LicenseHistoryDates = "class_licensehistory.item.updatedate";
    static LicenseHistoryDigitalUpper = "class_licensehistory.item.digital";
    static LicenseHistoryNotes = "class_licensehistory.item.notes";
    static LicenseHistoryTitle = "class_licensehistory.title";
    static LicenseHistoryPromote = "class_licensehistory.item.promotehistory";
    static LicenseHistoryClass = "class_licensehistory.item.class";
    static LicenseHistoryCampus = "class_licensehistory.item.campus";
    static LicenseHistoryEndDate = "class_licensehistory.item.enddate";
    static LicenseIncreaseNotePlaceholderText = "class_license.increase.note.placeholder.text";
    static LicenseDecreaseNotePlaceholderText = "class_license.decrease.note.placeholder.text";
    static LicenseIncreaseNotePlaceholderTextJapan = "class_license.increase.note.placeholder.text.japan";
    static LicenseDecreaseNotePlaceholderTextJapan = "class_license.decrease.note.placeholder.text.japan";
    static BreadMenuLicenseHistory = "class_licensehistory.title";
    static BreadTextLicenseHistory = "class_licensehistory.title";
    static LicenseHistoryUser = "class_licensehistory.item.updateby";
    static AdjustmentRemovedSuccessMsg = "class_licensehistory.removeadjustment.success";
    static AdjustmentRemovedFailureMsg = "class_licensehistory.removeadjustment.failure";
    static RemoveAdjustmentConfirmText = "class_licensehistory.removeadjustment.confirmtext";
    static ManyStudentRemoveSystemText = "class_licensehistory.notes.manystudentremove.additionalnote";
    static OneStudentRemoveSystemText = "class_licensehistory.notes.onestudentremove.additionalnote";
    static LicenseNewCnt = "class_license.item.countnew1";
    static LicenseNewOtherCnt = "class_license.item.countnew2";
    static LicenseNewOtherTypeCnt = "class_license.item.countnew3";
    static LicenseCurrentInvoiceDate = "class_license.item.currentinvoicedate";
    static LicenseNote = "class_license.item.note";
    static LicenseOpen = "class_license.item.open";
    static LicenseClosed = "class_license.item.closed"
    static LicensePending = "class_license.item.pending";
    static LicenseOfPeriod = "class_license.item.fromperiod";
    static LicenseOtherCnt = "class_license.item.billedcount";
    static LicensePeriod = "class_license.item.currentperiod";
    static LicenseStudentCnt = "class_license.item.currentstudentcount";
    static LicenseStudentCntPeriod = "class_license.item.period";
    static LicenseSub = "class_license.item.subscription";
    static LicenseSubDual = "class_license.item.dualsubscription";
    static LicenseSubscriptionTypeDigital = "class_license.item.subscriptiontypedigital";
    static LicenseSubscriptionTypeTextbook = "class_license.item.subscriptiontypetextbook";
    static LicenseText = "class_license.item.textbook1";
    static LicenseTextUpper = "class_license.item.textbook2";
    static LicenseTitle = "class_license.title";
    static LicenseType = "class_license.item.licensetype";
    static LicenseTypeLicense = "class_license.item.subscriptionlicense";
    static LicenseTypeUsage = "class_license.item.subscriptiontypeusage";
    static LicenseTypeUsageDual = "class_license.item.subscriptiontypeusagedual";
    static LicenseTypeUsageDualText = "class_license.item.subscriptiontypeusagedualtext";
    static LicenseTypeUsageDualTextLower = "class_license.item.subscriptiontypeusagedualtextlower";
    static LicenseTypeUsageSingle = "class_license.item.subscriptiontypeusagesingle";
    static LicenseTypeUsageStandard = "class_license.item.subscriptiontypeusagestandard";
    static LicenseAdjustmentValidCountMsg = "class_license.adjustmentvalidcountmsg";
    static LicenseHistoryAdjustmentDetails = "class_licensehistory.adjustmentdetailstitle";
    static LicenseHistoryBillingDateCol = "class_licensehistory.adjustmentdetails.billingdate";
    static LicenseHistoryAdjustmentCol = "class_licensehistory.adjustmentdetails.adjustment";
    static LicenseHistoryUpdateDateCol = "class_licensehistory.adjustmentdetails.date";
    static LicenseHistoryAdjustmentsLoading = "class_licensehistory.adjustmentdetails.loading";
    static LicenseHistoryNoDataMsg = "class_licensehistory.adjustmentdetails.nodatamsg";
    static ListStatesActive = "school.common.liststatesactive";
    static ListStatesAll = "school.common.liststatesall";
    static ListStatesInactive = "school.common.liststatesinactive";
    static ListStatesPromotion = "school.common.liststatespromotion";
    static ListStatesFuture = "school.common.liststatesfuture";
    static LoginFirstTitle = "invitation_userselect.msg.loginfirst";
    static MaterialOrderErrorOrderNull = "campus_cart.msg.ordernull";
    static MaterialOrderErrorSubmitProductNull = "campus_cart.msg.orderhavenoproduct";
    static MaterialOrderErrorStatus = "campus_cart.msg.orderinvalidstatus";
    static MaterialOrderErrorEdit = "campus_cart.msg.orderinvalidoperation";
    static MaterialOrderPagerButtonReject = "campus_order.item.reopenbutton";
    static MaterialOrderPagerButtonDownload = "campus_order.item.download";
    static MaterialOrderPagerPDFMessage = "campus_order.item.pdf_message";
    static MaterialOrderPagerRejectModalTitle = "campus_order.rejectmodal.title";
    static MaterialOrderPagerRejectModalNotificationRecipients = "campus_order.rejectmodal.notificationrecipients";
    static MaterialOrderPagerRejectModalRequestParty = "campus_order.rejectmodal.requestparty";
    static MaterialOrderPagerRejectModalShippingContact = "campus_order.rejectmodal.shippingcontact";
    static MaterialOrderPagerRejectModalOtherCampusAdmins = "campus_order.rejectmodal.othercampusadmins";
    static MaterialOrderPagerRejectModalOtherSchoolAdmins = "campus_order.rejectmodal.otherschooladmins";
    static MaterialOrderPagerRejectModalFieldNotificationRecipientRequired = "campus_order.rejectmodal.notificationrecipientrequired";
    static MaterialOrderPagerRejectModalFieldReopenNote = "campus_order.rejectmodal.reopennotes";
    static MaterialOrderPagerRejectModalFieldReopenNotePlaceHolder = "campus_order.rejectmodal.reopennotesplaceholder";
    static MaterialOrderPagerRejectModalFieldReopenNoteRequired = "campus_order.rejectmodal.reopennotesrequired";
    static MaterialOrderPagerFieldGSNumber = "campus_order.item.gsnumber";
    static MaterialOrderPagerFieldOrderId = "campus_order.item.orderid";
    static MaterialOrderPagerFieldShipDate = "campus_order.item.shippingdate";
    static MaterialOrderPagerFieldSubmissionDate = "campus_order.item.submissiondate";
    static MaterialOrderPagerFieldSubscription = "campus_order.item.subscription";
    static MaterialOrderPagerFieldUsage = "campus_order.item.usage";
    static MaterialOrderPagerFieldShippedDate = "campus_order.item.shippeddate";
    static MaterialOrderPagerFieldShippedNotes = "campus_order.item.shippednotes";
    static MaterialOrderPageCommonFieldShippedQuantity = "campus_order.common.shippedquantity";
    static MaterialOrderShippedQuantityErrorEmpty = "campus_order.message.shippedquantityempty";
    static MaterialOrderShippedDateErrorEmpty = "campus_order.message.shippeddateempty";
    static MaterialOrderPageTitle = "campus_order.title";
    static MaterialOrderStatusDraft = "campus_orderlist.item.orderstatusdraft";
    static MaterialOrderStatusSubmitted = "campus_orderlist.item.orderstatussubmitted";
    static MaterialOrderStatusInProgress = "campus_orderlist.item.orderstatusinprogress";
    static MaterialOrderStatusShipped = "campus_orderlist.item.orderstatusshipped";
    static MaterialOrderStatusClosed = "campus_orderlist.item.orderstatusclosed";
    static MaterialOrdersIconDetail = "campus_orderlist.icon.detail";
    static MaterialOrdersItemFieldNotes = "campus_orderlist.item.notes";
    static MaterialOrdersItemFieldOrderDate = "campus_orderlist.orderidcolumn.text";
    static MaterialOrdersItemFieldStatus = "campus_orderlist.orderstatuscolumn.text";
    static MaterialOrdersItemFieldProduct = "campus_orderlist.orderproductcolumn.text";
    static MaterialOrdersPageTitle = "campus_orderlist.title";
    static MaterialPageTitle = "campus_cart.title";
    static MaterialPurchaseItemFieldClass = "campus_cart.modal.class";
    static MaterialPurchaseItemFieldPrice = "campus_cart.modal.priceeach";
    static MaterialPurchaseItemFieldProduct = "campus_cart.modal.product";
    static MaterialPurchaseItemFieldQuantity = "campus_cart.modal.quantity";
    static MaterialPurchaseItemFieldQuantityError = "campus_cart.modal.quantitymessage";
    static MaterialPurchaseItemFieldPriceWithColon = "campus_cart.modal.productprice";
    static MaterialPurchaseItemFieldProductTotal = "campus_cart.modal.producttotal";
    static MaterialPurchaseItemFieldTotalWithColon = "campus_cart.modal.total";
    static MaterialPurchaseItemFieldUnit = "campus_cart.modal.unit";
    static MaterialPurchaseItemFieldTotalPrice = "campus_cart.modal.totalPrice";
    static MaterialPurchaseItemFieldNote = "campus_cart.modal.note";
    static MaterialPurchaseItemListIconDelete = "campus_cart.icon.deletepurchase";
    static MaterialPurchaseItemListIconEdit = "campus_cart.icon.editpurchase";
    static MaterialPurchaseItemListTitle = "campus_cart.modal.purchasetitle";
    static MaterialCumulativeItemListTitle = "campus_cart.cumulativegrid.cumulativelisttitle";
    static MaterialCumulativeItemListTitleWithOrderId = "campus_cart.cumulativegrid.cumulativelisttitle.orderid";
    static MaterialCumulativeProductItemFieldUnit = "campus_cart.cumulativegrid.unit";
    static MaterialCumulativeProductItemFieldProduct = "campus_cart.cumulativegrid.product";
    static MaterialCumulativeProductItemFieldQuantity = "campus_cart.cumulativegrid.quantity";
    static MaterialCumulativeProductItemFieldPriceEach = "campus_cart.cumulativegrid.priceeach";
    static MaterialCumulativeProductItemFieldTotalPrice = "campus_cart.cumulativegrid.totalprice";
    static MaterialPurchaseItemModalSaveButton = "campus_cart.modal.buttonsavetext";
    static MaterialRequestFieldCampus = "campus_cart.item.campus";
    static MaterialRequestFieldEmail = "campus_cart.item.email";
    static MaterialRequestFieldName = "campus_cart.item.name";
    static MaterialRequestFieldNote = "campus_cart.item.notes";
    static MaterialRequestFieldAgreeToMaterialRequestLicense = "campus_cart.item.agreetomaterialrequestlicense";
    static MaterialRequestFieldViewTermsAndConditions = "campus_cart.item.viewtermsandconditions";
    static MaterialRequestFieldPhone = "campus_cart.item.phonenumber";
    static MaterialRequestFieldPostCode = "campus_cart.item.postcode";
    static MaterialRequestFieldShipDate = "campus_cart.item.requestshipdate";
    static MaterialRequestFieldShipTitle = "campus_cart.item.shippingaddress";
    static MaterialRequestFieldShippingAddress = "campus_cart.item.address";
    static MaterialRequestFieldSubmissionEmail = "campus_cart.item.submissionemail";
    static MaterialRequestFieldSubmissionPhone = "campus_cart.item.submissionphonenumber";
    static MaterialRequestFieldSubmissionTitle = "campus_cart.item.ordercontact";
    static MaterialRequestFieldSubmissionUser = "campus_cart.item.submissionname";
    static MaterialRequestTermsModalTermsAndConditions = "campus_cart.termsmodal.termsandconditions";
    static MaterialRequestTermsModalClose = "campus_cart.termsmodal.close";
    static MaterialRequestItemModalDeletePrompt = "campus_cart.modal.deletemessage";
    static MaterialRequestItemModalTitleDelete = "campus_cart.modal.deletesubscriptiontitle";
    static MaterialRequestItemModalTitleEdit = "campus_cart.modal.editpurchasetitle";
    static MaterialRequestItemModalTitleEditNote = "campus_cart.modal.editpurchasenotetitle";
    static MaterialRequestItemModalTitleEditClass = "campus_cart.modal.editsubscriptiontitle";
    static MaterialRequestItemModalTitleNew = "campus_cart.modal.addpurchasetitle";
    static MaterialRequestItemModalTitleNewClass = "campus_cart.modal.addsubscriptiontitle";
    static MaterialRequestItemModalUnitNotSet = "campus_cart.modal.unitnotset";
    static MaterialRequestItemNew = "campus_cart.addpurchasetitle";
    static MaterialRequestItemNewClass = "campus_cart.addsubscriptiontitle";
    static MaterialRequestMessageSubmitted = "campus_cart.msg.ordersubmitted";
    static MaterialRequestMessageSubmittedSuccessfully = "campus_cart.msg.ordersubmittedsuccessfully";
    static MaterialSubscriptionClassItemLicenseCount = "class_order.item.license";
    static MaterialSubscriptionClassItemCurrentLicensePeriod = "class_order.item.currentperiod";
    static MaterialSubscriptionClassItemNextUnit = "class_order.item.nextunit";
    static MaterialSubscriptionClassItemNextUnitStartDate = "class_order.item.nextunitstartdate";
    static MaterialSubscriptionClassItemLicenseStudentCnt = "class_order.item.studentcount";
    static MaterialSubscriptionClassItemLicenseStudentTextBookCnt = "class_order.item.textbookcount";
    static MaterialSubscriptionItemFieldClass = "class_order.item.class";
    static MaterialSubscriptionItemFieldDetail = "class_order.item.detail";
    static MaterialSubscriptionItemFieldProduct = "class_order.item.product";
    static MaterialSubscriptionItemFieldQuantity = "class_order.quantitycolumn.text";
    static MaterialSubscriptionItemFieldRequestBy = "class_order.requestbycolumn.text";
    static MaterialSubscriptionItemFieldRequestDate = "class_order.requestdatecolumn.text";
    static MaterialSubscriptionItemFieldNote = "class_order.notecolumn.text";
    static MaterialSubscriptionItemFieldUnit = "class_order.unitcolumn.text";
    static MaterialSubscriptionItemListDeletePrompt = "class_order.msg.delete";
    static MaterialSubscriptionItemListIconDelete = "campus_cart.icon.deletesubscription";
    static MaterialSubscriptionItemListIconEdit = "campus_cart.icon.editsubscription";
    static MaterialSubscriptionItemListTitle = "campus_cart.modal.subscriptiontitle";
    static NotificationsNoData = "notificationlist.nodata";
    static NotificationsShowMore = "notificationlist.show.more";
    static NotificationsTitle = "notificationlist.title";
    static NotificationArchivedCreated = "notificationarchived.create.archived";
    static NotificationArchivedShow = "notificationarchived.create.showarchived";
    static NotificationArchivedCreatedMsg = "notificationarchived.create.msg";
    static NoUser = "invitation_useexisting.msg.nonexisted";
    static PasswordInvalid = "invitation_useexisting.msg.invalidpassword";
    static Preview = "school.common.invitationbuttonpreview";
    static Parent = "school.common.parent";
    static RedeemExistingEmail = "invitation_useexisting.email.text";
    static RedeemExistingPassword = "invitation_useexisting.password.text";
    static RedeemExistingTitle = "invitation_useexisting.title";
    static RedeemBtn = "invitation_useexisting.redeem.text";
    static RegisterAccount = "invitation_userselect.registernew.text";
    static RegisterConfirmPassword = "invitation_registernew.passwordconfirm.text";
    static RegisterEmail = "invitation_registernew.email.text";
    static RegisterHeader = "invitation_useexisting.registernew.text";
    static RegisterName = "invitation_registernew.name.text";
    static RegisterEnglishName = "invitation_registernew.englishname.text";
    static RegisterEnglishNamePlaceHolder = "invitation_registernew.englishname.placeholder";
    static RegisterPassword = "invitation_registernew.password.text";
    static RegisterSuccessFirst = "invitation_registernewfinish.subtitle.email";
    static RegisterSuccessSecond = "invitation_registernewfinish.title.emailbox";
    static ReportCount = "student.playcount.text";
    static ReportCountTimes = "student.times.text";
    static ReportDaily = "student.dailyview.text";
    static ReportTime = "student.playtime.text";
    static ReportTimeMinutes = "student.minutes.text";
    static ReportTitle = "student.title";
    static ReportWeekly = "student.weeklyview.text";
    static SearchRegions = "search.regions.subtitle";
    static SearchSchools = "search.schools.subtitle";
    static SearchCampuses = "search.campuses.subtitle";
    static SearchClasses = "search.classes.subtitle";
    static SearchContents = "search.contents.subtitle";
    static SearchLessonVideo = "search.lessonvideo.subtitle";
    static SchoolAdministrator = "search.schooladmin.subtitle";
    static SearchText = "search.search.text";
    static NoResult = "search.msg.noresult";
    static ResultTitle = "search.msg.hasresult";
    static SearchStudents = "search.student.subtitle";
    static SearchUsers = "search.user.subtitle";
    static SearchParents = "search.parent.subtitle";
    static SearchOrders = "search.order.subtitle";
    static SearchResultUserType = "search.result.usertype";
    static SettingsAllowParentMailToTeacher = "school_editing.item.allowparentmailtoteacher";
    static SettingsAllowTeachersAccessParentInfo = "school_editing.item.allowteachersaccessparentinfo";
    static SettingsAllowLessonPlanView = "school_editing.item.allowLessonPlanView";
    static SettingsAvatarSize = "school_editing.msg.avatarsize";
    static SettingsAvatarUploadButton = "school_editing.avataruploadbutton.text";
    static SettingsAvatarType = "school_editing.msg.avatartype";
    static SettingsEnName = "school_editing.item.enname";
    static SettingsName = "school_editing.item.name";
    static SettingsTitle = "school_editing.title";
    static PrivacyPolicy = "invitation_registernew.policy.text";
    static PrivacyPolicyAgreement = "invitation_registernew.policy.agreement";
    static PrivacyPolicyAcceptance = "invitation.policy.acceptance"
    static PrivacyPolicyCheckboxText = "inviatation_registernew.policy.checkboxtext";
    static PrivacyPolicyReadMore = "invitation-registernew.policy.readmore";
    static PrivacyPolicyAccept = "invitation-registernew.policy.accept";
    static PrivacyPolicyReject = "invitation-registernew.policy.reject";
    static PrivacyPolicyDecline = "invitation.policy.decline";
    static PrivacyPolicyAcceptBtn = "invitation.policy.acceptbtn";
    static PrivacyPolicyRejectionMessage = "invitation-registernew.policy.rejectionmessage";
    static PrivacyPolicyDeclineMessageForNewUser = "invitation.policy.declinemessagefornewuser";
    static PrivacyPolicyDeclineMessageForExistingUser = "invitation.policy.declinemessageforexistinguser";
    static PrivacyPolicyDeclineModalOkBtn = "invitation.policy.declinemodalokbtn";
    static TermsAgreementDecline = "invitation-registernew.termsagreement.decline";
    static TermsAgreementAcceptBtn = "invitation-registernew.termsagreement.acceptbtn";
    static TermsAgreementAcceptance = "invitation-registernew.termsagreement.acceptance";
    static TermsAgreementDeclineMessageForNewUser = "invitation-registernew.termsagreement.declinemessagefornewuser";
    static StudentDataUniqueMessage = "student.data.uniquemessage";
    static StudentDataFile = "student.data.file";
    static StudentDataFileLoad = "student.data.fileload";
    static StudentDataFileSize = "student.data.filesize";
    static StudentDataFileType = "student.data.filetype";
    static StudentBirthdayValidation = "student.birthday.validation.text";
    static StudentGenderValidation = "student.gender.validation.text";
    static StudentParentEmail = "student.parentemailcolumn.text";
    static StudentParentPhone = "student.parentPhoneColumn.text";
    static StudentRegistrationRemainingLicensesLabel = "student.registration.remaininglicenses.label";
    static StudentRegistrationEmailValidation = "student.registration.email.validation";
    static StudentRegistrationUploadSupportedFormat = "student.registration.upload.supportedformat";
    static StudentRegistrationUploadNote = "student.registration.upload.note";
    static StudentRegistrationUploadButtonText = "student.registration.upload.buttontext";
    static StudentRegistrationUploadFormatLinkText = "student.registration.upload.formatlinktext";
    static StudentRegistrationParentInvitationNote = "student.registration.parent.invitationnote";
    static StudentRegistrationParentInvitationPhoneNote = "student.registration.parent.invitationPhoneNote";
    static StudentRegistrationRegisterLinkText = "student.registration.register.linktext";
    static StudentRegistrationPrintText = "student.registration.print.linktext";
    static StudentRegistrationInvitationLinkText = "student.registration.invitation.linktext";
    static StudentRegistrationManualSecHeading = "student.registration.manual.sec.heading";
    static StudentRegistrationGenericSecHeading = "student.registration.generic.sec.heading";
    static StudentRegistrationManualTypeSingleText = "student.registration.manualtype.singletext";
    static StudentRegistrationManualTypeBulkText = "student.registration.manualtype.bulktext";
    static StudentRegistrationChangeViewConfirmTitle = "student.registration.changeview.confirmtitle";
    static StudentRegistrationChangeViewConfirmMessage = "student.registration.changeview.confirmmessage";
    static StudentRegistrationStudentMale = "student.registration.studentmale";
    static StudentRegistrationStudentFemale = "student.registration.studentfemale";
    static StudentRegistrationBulkUploadEmptyValidationMsg = "student.registration.bulkupload.emptyvalidationmsg";
    static StudentRegistrationNavigationNextText = "student.registration.navigation.nexttext";
    static StudentRegistrationNavigationSaveText = "student.registration.navigation.savetext";
    static StudentRegistrationNavigationCancelText = "student.registration.navigation.canceltext";
    static StudentRegistrationNavigationPreviousText = "student.registration.navigation.previoustext";
    static StudentRegistrationNavigationCloseText = "student.registration.navigation.closetext";
    static StudentRegistrationNavigationNewStudRegText = "student.registration.navigatoin.newstudregtext";
    static StudentRegistrationNavigationBeforeMergeStudInfo = "student.registration.navigatoin.beforeMergeStudInfo";
    static StudentRegistrationNavigationAfterMergeStudInfo = "student.registration.navigatoin.afterMergeStudInfo";
    static StudentRegistrationNavigationMatchExtUserText = "student.registration.navigatoin.matchExtUserText";
    static StudentRegistrationNavigationOtherMatchesText = "student.registration.navigatoin.otherMatchesText";
    static StudentRegistrationSuggestionNote1 = "student.registration.suggestion.note1";
    static StudentRegistrationSuggestionNote2 = "student.registration.suggestion.note2";
    static StudentRegistrationSuggestionNewStudentCountText = "student.registration.suggestion.newstudentcount.text";
    static StudentRegistrationSuggestionExactMatchCountText = "student.registration.suggestion.exactmatchcount.text";
    static StudentRegistrationSuggestionAllMatchCountText = "student.registration.suggestion.allmatchcount.text";
    static StudentRegistrationSuggestionPotentialMatchCountText = "student.registration.suggestion.potentailmatchcount.text";
    static StudentRegistrationSuggestionImportedHeading = "student.registration.suggestion.importedheading";
    static StudentRegistrationSuggestionPotentialHeading = "student.registration.suggestion.potentialheading";
    static StudentRegistrationSuggestionOverwriteCheckLabel = "student.registration.suggestion.overwritecheck.label";
    static StudentRegistrationSuggestionParentEmailLabel = "student.registration.suggestion.parentemail.label";
    static StudentRegistrationSuggestionParentEmailTextLabel = "student.registration.suggestion.parentemailTxt.label";
    static StudentRegistrationSuggestionParentEmailPending = "student.registration.suggestion.parentemail.pending";
    static StudentRegistrationSuggestionParentPhoneLabel = "student.registration.suggestion.parentPhone.label";
    static StudentRegistrationSuggestionParentPhoneGridCol = "student.registration.bulkRegistration.parentPhone.gridCol";
    static StudentRegistrationSuggestionCountryCodeGridCol = "student.registration.bulkRegistration.countryCode.gridCol";
    static StudentRegistrationBulkRegisterUsing = "student.registration.bulkRegistration.registerUsing";
    static StudentRegistrationBulkRegisterUsingEmail = "student.registration.bulkRegistration.registerUsingEmail";
    static StudentRegistrationBulkRegisterUsingPhone = "student.registration.bulkRegistration.registerUsingPhone";
    static StudentRegistrationBulkRegisterChangeCfm = "student.registration.bulkRegisteration.changeConfirm";
    static StudentRegistrationPhoneValidationMsg = "student.registration.phone.validationMsg";
    static StudentRegistrationPhoneLabel = "student.registration.phone.label";
    static StudentRegistrationGenGridParentPhone = "student.registration.generateGrid.parentPhone";
    static StudentRegistratinoGenSMS = "student.registration.generate.sms";
    static StudentRegistratinoGenEmail = "student.registration.generate.email";
    static StudentRegistratinoGenPrint = "student.registration.generate.print";
    static StudentRegistratinoGenPreviewSMS = "student.registration.generate.previewSMS";
    static StudentRegistrationSuggestionStudentOverwriteCheckLabel = "student.registration.suggestion.studentoverwritecheck.label";
    static StudentRegistrationSuggestionCreateNewLabel = "student.registration.suggestion.createnew.label";
    static StudentRegistrationSuggestionGetErrorMsg = "student.registration.suggestion.get.errormsg";
    static StudentRegistrationSuggestionSaveErrorMsg = "student.registration.suggestion.save.errormsg";
    static StudentRegistrationSuggestionErrorMsg = "student.registration.suggestion.errormsg";
    static StudentRegistrationSuggestionMatchErrorMsg = "student.registration.suggestion.match.errormsg";
    static StudentRegistrationInviteNewStudentsCountText = "student.registration.invite.newstudentscount.text";
    static StudentRegistrationInviteExistingStudentsCountText = "student.registration.invite.existingstudentscount.text";
    static StudentRegistrationInvitePreviewTemplateButtonText = "student.registration.invite.preview.buttontext";
    static StudentRegistrationInvitePreviewEmailButtonText = "student.registration.invite.previewemail.buttontext";
    static StudentRegistrationCloseButtonText = "student.registration.invite.close.buttontext";
    static StudentRegistrationInviteEmailTitle = "student.registration.invite.email.title";
    static StudentRegistrationInvitePrintButtonText = "student.registration.invite.print.buttontext";
    static StudentRegistrationInviteEmailButtonText = "student.registration.invite.email.buttontext";
    static StudentRegistrationInvitePhoneButtonText = "student.registration.invite.phone.buttontext";
    static StudentRegistrationInviteEmailErrorMsg = "student.registration.invite.email.errormsg";
    static StudentRegistrationEmailSubject = "student.registration.email.subject";
    static StudentRegistrationEmailTo = "student.registration.email.to";
    static StudentRegistrationInviteDownloadErrorMsg = "student.registration.invite.download.errormsg";
    static StudentRegistrationNoData = "student.registration.nodata";
    static StudentRegistrationNoNewData = "student.registration.noNewData";
    static StudentRegistrationNoExactData = "student.registration.noExactData";
    static StudentRegistrationNoPotentialData = "student.registration.noPotentialData";
    static StudentRegistrationUploadConfirmationTitle = "student.registration.upload.confirmation.title";
    static StudentRegistrationUploadConfirmationMessage = "student.registration.upload.confirmation.message";
    static StudentRegistrationRemoveConfirmationTitle = "student.registration.remove.confirmation.title";
    static StudentRegistrationRemoveConfirmationMessage = "student.registration.remove.confirmation.message";
    static StudentRegistrationRemoveErrorMessage = "student.registration.remove.error.message";
    static StudentRegistrationAccessDenied = "student.registration.accessDenied";
    static StudentRegistrationAccessDeniedDescription = "student.registration.accessDenied.description";
    static StudentRegistrationImportStep = "student.registration.importStep";
    static StudentRegistrationMatchStep = "student.registration.matchStep";
    static StudentRegistrationGenerateStep = "student.registration.generateStep";
    static StudentRegistrationClassLicensesLabel = "student.registration.classLicenseLabel";
    static StudentRegistrationTotalLicenses = "student.registration.totalLicenses";
    static StudentRegistrationAvailableLicenses = "student.registration.availableLicenses";
    static StudentRegistrationAddLicenses = "student.registration.addLicenses";
    static StudentRegistrationNewStudentsLabel = "student.registration.newStudentsLabel";
    static StudentRegistrationNewNote1 = "student.registration.newNote1";
    static StudentRegistrationNewNote2 = "student.registration.newNote2";
    static StudentRegistrationExactNote1 = "student.registration.exactNote1";
    static StudentRegistrationExactNote2 = "student.registration.exactNote2";
    static StudentRegistrationPotentialNote1 = "student.registration.potentialNote1";
    static StudentRegistrationPotentialNote2 = "student.registration.potentialNote2";
    static StudentRegistrationPotentialNote3 = "student.registration.potentialNote3";
    static StudentRegistrationSchoolLabel = "student.registration.schoolLabel";
    static StudentRegistrationCampusLabel = "student.registration.campusLabel";
    static StudentRegistrationClassLabel = "student.registration.classLabel";
    static StudentRegistrationCurrentUnitLabel = "student.registration.currentUnitLabel";
    static StudentRegistrationCurrentUnitStartDateLabel = "student.registration.currentUnitStartDateLabel";
    static StudentRegistrationFullInviteMsg = "student.registration.fullInviteMsg";
    static StudentRegistrationResetRowTitle = "student.registration.resetRowTitle";
    static StudetnBirthday = "student.birthdaycolumn.text";
    static StudentEnName = "student.ennamecolumn.text";
    static StudetnFemale = "student.gendercolumn.female";
    static StudentGender = "student.gendercolumn.text";
    static StudentUploadAvatar = "student.avatar.upload.text";
    static StudentUploadAvatarMaxSize = "student.avatar.maxsize";
    static StudentUploadAvatarImageType = "student.avatar.imagetype";
    static StudentAccessDeniedMessage = "student.accessdenied.message";
    static StudentEditTitle = "student.edit.title";
    static StudentEditEnterName = "student.edit.entername";
    static StudentEditEnterEnglishName = "student.edit.enterenglishname";
    static StudentEditUpdateFailed = "student.edit.updatefailed";
    static StudetnInvite = "class.icon.edittip";
    static StudetnInviteCnt = "class.modal.invitecountlabel";
    static StudentTemplatePlaceholder = "class.invite.template.placeholder";
    static StudetnInviteDownload = "class.modal.buttoncreate";
    static StudetnInviteTemplatePreviewTitle = "class.modal.templatepreviewtitle";
    static StudetnInviteTemplatePreviewSubject = "class.modal.templatepreviewsubject";
    static StudetnInviteFull = "class.msg.invitestudentoverflow";
    static StudetnInviteStudent = "class.item.addstudents";
    static StudetnInviteTitle = "class.modal.title";
    static StudetnInviteUnkownError = "class.modal.messageerror";
    static StudentPlayListCovered = "student.playlistcoveredcolumn.text";
    static StudentLastLogin = "student.lastlogincolumn.text";
    static StudentActivated = "student.activatedcolumn.text";
    static StudentMale = "student.gendercolumn.male";
    static StudentName = "student.namecolumn.text";
    static StudentStus = "class.item.students";
    static StudentUnit = "student.report.unit";
    static StudentLastUnit = "student.last.unit";
    static StudentGracePeriod = "student.report.gracePeriod";
    static StudentReportNotAny = "student.report.na";
    static StudentSubTeacher = "class.item.substitueteacher";
    static StudentTeacher = "class.item.teacher";
    static StudentDetailNote = "student.detail.note";
    static StudentDetailNotePlaytime = "student.detail.note.playtime";
    static StudentIncludePlaylistTime = "student.includetime.playlist";
    static StudentIncludeLibraryTime = "student.includetime.library";
    static ClassSchoolNotes = "class.item.notes";
    static ClassInternalNotes = "class.item.internalnotes";
    static ClassInternalNotesPlaceholder = "class.item.internalnotesplaceholder";
    static TeacherAccepted = "class_teacher.item.accepted";
    static TeacherName = "class_teacher.namecolumn.text";
    static TeacherPending = "class_teacher.item.pending";
    static TeacherPrimary = "class_teacher.mainteachercolumn.text";
    static TeachingClasses = "search.teacher.teachingclassessubtitle";
    static TextAdd = "class_adding.modal.buttonadd";
    static TextNone = "school.common.textnone";
    static TextSureDel = "class_edit.msg.delete";
    static UnitPlanAppUnlockDate = "class_unitplan.unlockdatecolumn.text";
    static UnitPlanAppUnlockState = "class_unitplan.unlockmodecolumn.text";
    static UnitPlanAppUnlockStateLock = "class_unitplan.unlockmodecolumn.locktext";
    static UnitPlanAppUnlockStateUnlock = "class_unitplan.unlockmodecolumn.unlocktext";
    static UnitPlanAppContentStatus = "class_unitplan.appcontentstatuscolumn.text";
    static UnitPlanAppContentStatusAccessible = "class_unitplan.appcontentstatuscolumn.accessible";
    static UnitPlanAppContentStatusInAccessible = "class_unitplan.appcontentstatuscolumn.inaccessible";
    static UnitPlanAppContentStatusWaiting = "class_unitplan.appcontentstatuscolumn.waiting";
    static UnitPlanCurrent = "class_unitplan.item.currentunitlabel";
    static UnitPlanLocked = "class_unitplan.item.currentunittext";
    static UnitPlanLocked4LS = "class_unitplan.item.currentlsunittext";
    static UnitPlanName = "class_unitplan.unitcolumn.text";
    static UnitPlanStartDate = "class_unitplan.startdatecolumn.text";
    static UnitPlanLessonNumber = "class_unitplan.lessonnumber.text";
    static UnitPlanStartDateDisabled = "class_unitplan.startdatecolumn.disabled";
    static UnitPlanTitle = "class_unitplan.title";
    static UnitPlanUnit2Generate = "class_unitplan.unit2generate.text";
    static UnitPlanUnitsRangeSeparator = "class_unitplan.unitsrangeseparator.text";
    static UnitPlanClassEndDate = "class_unitplan.classenddate.text";
    static UnitPlanGeneration = "class_unitplan.generate.button";
    static UnitPlanGenerationTitle = "class_unitplan.generate.title";
    static UnitPlanCopy = "class_unitplan.copy.button";
    static UnitPlanCopyTitle = "class_unitplan.copy.title";
    static UnitPlanAdjustLicenseTitle = "class_unitplan.adjustlicense.title";
    static UnitPlanGenerateErrorDate = "class_unitplan.generate.errordate";
    static UnitPlanGenerateErrorPlanData = "class_unitplan.generate.errorplandata";
    static UnitPlanGenerateErrorMM = "class_unitplan.generate.errorMM";
    static UnitPlanGenerateErrorMS = "class_unitplan.generate.errorMS";
    static UnitPlanGenerateErrorSS = "class_unitplan.generate.errorSS";
    static UnitPlanGenerateErrorSM = "class_unitplan.generate.errorSM";
    static UnitPlanValid = "class_unitplan.msg.error1";
    static UnitPlanValidClass = "class_unitplan.msg.error2";
    static UnitPlanValidMinimumDays = "class_unitplan.msg.error3";
    static UnitPlanValidMinimumDay = "class_unitplan.msg.error31";
    static UnitPlanValidMinimumDaysCommon = "class_unitplan.msg.error4";
    static UnitPlanValidStartDate = "class_unitplan.msg.error5";
    static UnitPlanValidUnlockDate = "class_unitplan.msg.error6";
    static UnitPlanValidEx = "class_unitplan.msg.error7";
    static UnitPlanValidStartAndUnlockDate = "class_unitplan.msg.error8";
    static UnitPlanValidStartDateEmpty = "class_unitplan.msg.error9";
    static UnitPlanProgressionHistory = "class_unitplan.item.progressionhistory";
    static UnitPlanProgressionHistoryUnitColumn = "class_unitplan.progressionhistorycolumn.unit";
    static UnitPlanProgressionHistoryUnitStartDateColumn = "class_unitplan.progressionhistorycolumn.unitstartdate";
    static UnitPlanProgressionHistoryCampusNameColumn = "class_unitplan.progressionhistorycolumn.campusname";
    static UnitPlanProgressionHistoryClassNameColumn = "class_unitplan.progressionhistorycolumn.classname";
    static UnitPlanProgressionHistoryClassStartDateColumn = "class_unitplan.progressionhistorycolumn.classstartdate";
    static UnitPlanBulkTitle = "class_unitplan.bulk.title";
    static UnitPlanBulkGenerate = "class_unitplan.bulk.generate";
    static UnitPlanBulkGenerateErrorMsg = "class_unitplan.bulk.generateerrorplandata";
    static UnitPlanBulkValidStartDateEarlier = "class_unitplan.bulk.error1";
    static UnitPlanBulkValidStartDateLater = "class_unitplan.bulk.error2";
    static UnitPlanBulkValidStartDateMissing = "class_unitplan.bulk.error3";
    static UnitPlanBulkValidStartDateLaterClass = "class_unitplan.bulk.error4";
    static UnitPlanBulkUnitColumn1 = "class_unitplan.bulk.unitcolumn1";
    static UnitPlanBulkUnitColumn2 = "class_unitplan.bulk.unitcolumn2";
    static UnitPlanBulkUnitColumn3 = "class_unitplan.bulk.unitcolumn3";
    static UnitPlanBulkUnitColumn4 = "class_unitplan.bulk.unitcolumn4";
    static UnitPlanBulkUnitColumn5 = "class_unitplan.bulk.unitcolumn5";
    static UnitPlanBulkUnitColumn6 = "class_unitplan.bulk.unitcolumn6";
    static UnitPlanBulkUnitColumn7 = "class_unitplan.bulk.unitcolumn7";
    static UnitPlanBulkUnitColumn8 = "class_unitplan.bulk.unitcolumn8";
    static UnitPlanBulkCancelMsg = "class_unitplan.bulk.cancelmsg";
    static GroupManagerSave = "class_groupmanager.save";
    static GroupManagerCancel = "class_groupmanager.cancel";
    static GroupManagerNone = "class_groupmanager.none";
    static GroupManagerCreate = "class_groupmanager.create";
    static GroupManagerGroupName = "class_groupmanager.group_name";
    static GroupManagerTimeSlot = "class_groupmanager.time_slot";
    static GroupManagerDelete = "class_groupmanager.delete";
    static GroupManagerGroup = "class_groupmanager.group";
    static GroupManagerValidateTimeSlot = "class_groupmanager.validate_timeslot";
    static GroupManagerValidateStudent = "class_groupmanager.validate_student";
    static GroupManagerNoGroupMessage1 = "class_groupmanager.no_group_message1";
    static GroupManagerNoGroupMessage2 = "class_groupmanager.no_group_message2";
    static GroupManagerPageTitle = "class_groupmanager.page_title";
    static GroupManagerGroupTableTitle = "class_groupmanager.group_table_title";
    static GroupManagerAddGroup = "class_groupmanager.add_group";
    static GroupManagerSelectPlaceHolder = "class_groupmanager.select_place_holder";
    static GroupManagerMessageSuccess = "class_groupmanager.message_success";
    static GroupManagerMessageFail = "class_groupmanager.message_fail";
    static GroupManagerUnregisteredStudent = "class_groupmanager.unregistered_student";
    static ValidMsg = "invitation_start.message";
    static ClassListExport = "classlist.export";
    static ChangeClassMessage = "move_student.change_class.message";
    static MoveStudentSubmit = "move_student.submit.message";
    static NoStudentMessage = "move_student.no_student.message";
    static MoveStudentCancel = "move_student.cancel.message";
    static LicenseCount = "move_student.license_count";
    static StudentCount = "move_student.student_count";
    static MoveStudentButton = "move_student.move.button";
    static MoveStudentText = "move_student.move_student.text";
    static MoveStudentSuccessMessage = "move_student.submit.success";
    static MoveStudentTotalLicence = "move_student.total_licence";
    static MoveStudentUnRegisteredStudents = "move_student.unregistered_students";
    static MoveStudentsRegisteredStudents = "move_student.registered_students";
    static MoveStudentCount = "move_student.count";
    static MoveStudentSaveMessage = "move_student.save_message";
    static MoveStudentUnregisteredInfo = "move_student.unregistered_info";
    static MoveStudentsNote = "move_student.note";
    static MainShippingContact = "main_shipping_contact";
    static MainSupportContact = "main_support_contact";
    static MainBillingContact = "main_billing_contact";
    static ShippingContact = "shipping_contact";
    static SupportContact = "support_contact";
    static MainContact = "main_contact";
    static BillingContact = "billing_contact";
    static AdminPopoverSchoolContacts = "admin.popover.schoolcontacts";
    static AdminPopoverCampusContacts = "admin.popover.campuscontacts";
    static AdminPopoverType = "admin.popover.type";
    static AdminPopoverEmail = "admin.popover.email";
    static AdminPopoverPhone = "admin.popover.phone";
    static AdminPopoverShipping = "admin.popover.shipping";
    static AdminPopoverSupport = "admin.popover.support";
    static AdminPopoverBilling = "admin.popover.billing";
    static AdminPopoverContact = "admin.popover.contact";

    static WijmoGroupPanelDragColumnsText = "wijmo.grouppanel.dragcolumntext";

    static CIMSEventTypeAddClass = "cims.eventtype.addclass";
    static CIMSEventTypePromoteClass = "cims.eventtype.promoteclass";
    static CIMSEventTypeRemoveClass = "cims.eventtype.removeclass";
    static CIMSEventTypeDisableClass = "cims.eventtype.disableclass";
    static CIMSEventTypeClassLicenseChange = "cims.eventtype.classlicensechange";
    static CIMSEventTypeClassUnitPlanChange = "cims.eventtype.classunitplanchange";
    static CIMSEventTypeClassMandatoryDataChange = "cims.eventtype.classmandatorydatachange";
    static CIMSEventTypeRemoveStudent = "cims.eventtype.removestudent";
    static CIMSEventTypeMoveStudent = "cims.eventtype.movestudent";
    static CIMSEventTypeEnableClass = "cims.eventtype.enableclass";
    static CIMSEventTypeMoveUnregisteredStudent = "cims.eventtype.moveunregisteredstudent";
    static CIMSSchoolLogTitle = "cims.schoollog.title";
    static CIMSSchoolLogSearchPlaceholder = "cims.schoollog.searchplaceholder";
    static CIMSSchoolLogDenySwitch = "cims.schoollogdenyswitch.text";
    static CIMSSchoolLogCampus = "cims.schoollogcampuscolumn.text";
    static CIMSSchoolLogClass = "cims.schoollogclasscolumn.text";
    static CIMSSchoolLogEventType = "cims.schoollogeventtypecolumn.text";
    static CIMSSchoolLogEventStatus = "cims.schoollogeventstatuscolumn.text";
    static CIMSSchoolLogEventStatusPending = "cims.schoollogeventstatus.pending";
    static CIMSSchoolLogEventStatusAcknowledged = "cims.schoollogeventstatus.acknowledged";
    static CIMSSchoolLogEventStatusReverted = "cims.schoollogeventstatus.reverted";
    static CIMSSchoolLogUpdateBy = "cims.schoollogupdatebycolumn.text";
    static CIMSSchoolLogUpdateDate = "cims.schoollogupdatedatecolumn.text";
    static CIMSCampusLogSearchPlaceholder = "cims.campuslog.searchplaceholder";
    static CIMSAddClassEventTilte = "cims.addclassevent.title";
    static CIMSPromoteClassEventTilte = "cims.promoteclassevent.title";
    static CIMSDisableClassEventTilte = "cims.disableclassevent.title";
    static CIMSEnableClassEventTilte = "cims.enableclassevent.title";
    static CIMSChangeEventClassName = "cims.changeevent.classnametext";
    static CIMSChangeEventCurriculumType = "cims.changeevent.curriculumtypetext";
    static CIMSChangeEventLicenseType = "cims.changeevent.licensetypetext";
    static CIMSChangeEventName = "cims.changeevent.nametext";
    static CIMSChangeEventAgeGrade = "cims.changeevent.agegradetext";
    static CIMSChangeEventTSIPerweek = "cims.changeevent.tsiperweeektext";
    static CIMSChangeEventREPPerweek = "cims.changeevent.repperweektext";
    static CIMSChangeEventStudentCount = "cims.changeevent.studentcounttext";
    static CIMSChangeEventDigitalLicense = "cims.changeevent.digitallicensetext";
    static CIMSChangeEventTextbookLicense = "cims.changeevent.textbooklicensetext";
    static CIMSChangeEventStartUnit = "cims.changeevent.startunittext";
    static CIMSChangeEventEndUnit = "cims.changeevent.endunittext";
    static CIMSChangeEventStartDate = "cims.changeevent.startdatetext";
    static CIMSChangeEventEndDate = "cims.changeevent.enddatetext";
    static CIMSChangeEventCreateby = "cims.changeevent.createbytext";
    static CIMSChangeEventCreateDate = "cims.changeevent.createdatetext";
    static CIMSChangeEventUpdateby = "cims.changeevent.updatebytext";
    static CIMSChangeEventUpdateDate = "cims.changeevent.udpatedatetext";
    static CIMSChangeEventModifyby = "cims.changeevent.modifybytext";
    static CIMSChangeEventModifyDate = "cims.changeevent.modifydatetext";
    static CIMSChangeEventStatusText = "cims.changeevent.eventStatusText";
    static CIMSChangeEventClassText = "cims.changeevent.eventClassText";
    static CIMSChangeEventApprovedText = "cims.changeevent.eventApprovedText";
    static CIMSChangeEventPendingText = "cims.changeevent.eventPendingText";
    static CIMSLicenseUpdateEventTilte = "cims.licenseupdateevent.title";
    static CIMSLicenseChangeEventTilte = "cims.licensechangeevent.title";
    static CIMSLicenseChangeChangeTypeColumn = "cims.licensechange.changetypecolumntext";
    static CIMSLicenseChangeDetailColumn = "cims.licensechange.detailcolumntext";
    static CIMSLicenseChangeNotesColumn = "cims.licensechange.notescolumntext";
    static CIMSChangeEventCurrent = "cims.changeevent.currenttext";
    static CIMSChangeEventPrevious = "cims.changeevent.previoustext";
    static CIMSChangeEventSourceClass = "cims.changeevent.sourceclasstext";
    static CIMSChangeEventTargetClass = "cims.changeevent.targetclasstext";
    static CIMSChangeEventCurrentUnitPlan = "cims.changeevent.currentunitplantext";
    static CIMSChangeEventPreviousUnitPlan = "cims.changeevent.previousunitplantext";
    static CIMSUnitPlanChangeEventTitle = "cims.unitplanchangeevent.title";
    static CIMSMandatoryAttributeChangeEventTilte = "cims.mandatoryattributechangeevent.title";
    static CIMSMoveStudentEventTitle = "cims.movestudentevent.title";
    static CIMSMoveStudentEventMessage = "cims.movestudentevent.message";
    static CIMSStudentRemovedEventTitle = "cims.studentremovedevent.title";
    static CIMSStudentRemovedEventMessage = "cims.studentremovedevent.message";
    static CIMSSubscriptionChangeEventStudent = "cims.subscriptionchangeevent.studenttext";
    static CIMSSubscriptionChangeEventCurrentSubscription = "cims.subscriptionchangeevent.currentsubscriptiontext";
    static CIMSSubscriptionChangeEventPreviousSubscription = "cims.subscriptionchangeevent.previoussubscriptiontext";
    static CIMSMoveUnregisteredStudentEventTitle = "cims.moveunregisteredstudentevent.title";
    static CIMSMoveUnregisteredStudentEventMessage = "cims.moveunregisteredstudentevent.message";
    static CIMSPendingChangesPageTitle = "cims.pendingchanges.pagetitle";
    static CIMSPendingChangesSchoolTabTitle = "cims.pendingchanges.schooltabtitle";
    static CIMSPendingChangesCampusTabTitle = "cims.pendingchanges.campustabtitle";
    static CIMSPendingChangesSchoolClassTabTitle = "cims.pendingchanges.schoolclasstabtitle";
    static CIMSPendingChangesRegionColumn = "cims.pendingchanges.regioncolumntext";
    static CIMSPendingChangesNameColumn = "cims.pendingchanges.namecolumntext";
    static CIMSPendingChangesEnglishNameColumn = "cims.pendingchanges.englishnamecolumntext";
    static CIMSPendingChangesCaseNoColumn = "cims.pendingchanges.casenocolumntext";
    static CIMSPendingChangesOwnerColumn = "cims.pendingchanges.ownercolumntext";
    static CIMSPendingChangesOwnerSelectPlaceholder = "cims.pendingchanges.ownerselectplaceholder";
    static CIMSPendingChangesOwnerAssignedSuccessMessage = "cims.pendingchanges.ownerassigned.successmessage";
    static CIMSPendingChangesOwnerAssignedErrorMessage = "cims.pendingchanges.ownerassigned.errormessage";
    static CIMSPendingChangesOwnerRemovedSuccessMessage = "cims.pendingchanges.ownerremoved.successmessage";
    static CIMSPendingChangesOwnerRemovedErrorMessage = "cims.pendingchanges.ownerremoved.errormessage";
    static CIMSPendingChangesSchoolColumn = "cims.pendingchanges.schoolcolumntext";
    static CIMSPendingChangesCampusColumn = "cims.pendingchanges.campuscolumntext";
    static CIMSPendingChangesClassColumn = "cims.pendingchanges.classcolumntext";
    static CIMSPendingChangesLicenseTypeColumn = "cims.pendingchanges.licensetypecolumntext";
    static CIMSPendingChangesGradeColumn = "cims.pendingchanges.gradecolumntext";
    static CIMSPendingChangesStudentCountColumn = "cims.pendingchanges.studentcountcolumntext";
    static CIMSPendingChangesStartDateColumn = "cims.pendingchanges.startdatecolumntext";
    static CIMSPendingChangesTsiPerWeekColumn = "cims.pendingchanges.tsiperweekcolumntext";
    static CIMSPendingChangesRepPerWeekColumn = "cims.pendingchanges.repperweekcolumntext";
    static CIMSPendingChangesStartUnitColumn = "cims.pendingchanges.startunitcolumntext";
    static CIMSPendingChangesEndUnitColumn = "cims.pendingchanges.endunitcolumntext";
    static CIMSPendingChangesUnitColumn1 = "cims.pendingchanges.unitcolumn1text";
    static CIMSPendingChangesUnitColumn2 = "cims.pendingchanges.unitcolumn2text";
    static CIMSPendingChangesUnitColumn3 = "cims.pendingchanges.unitcolumn3text";
    static CIMSPendingChangesUnitColumn4 = "cims.pendingchanges.unitcolumn4text";
    static CIMSPendingChangesUnitColumn5 = "cims.pendingchanges.unitcolumn5text";
    static CIMSPendingChangesUnitColumn6 = "cims.pendingchanges.unitcolumn6text";
    static CIMSPendingChangesUnitColumn7 = "cims.pendingchanges.unitcolumn7text";
    static CIMSPendingChangesUnitColumn8 = "cims.pendingchanges.unitcolumn8text";
    static CIMSPendingChangesGrapeSeedUnitColumn = "cims.pendingchanges.grapeseedunitcolumntext";
    static CIMSPendingChangesLittleSeedUnitColumn = "cims.pendingchanges.littleseedunitcolumntext";
    static CIMSPendingChangesChangeColumn = "cims.pendingchanges.changecolumntext";
    static CIMSPendingChangesUpdateByColumn = "cims.pendingchanges.updatebycolumntext";
    static CIMSPendingChangesUpdateDateColumn = "cims.pendingchanges.updatedatecolumntext";
    static CIMSPendingChangesApproveAction = "cims.pendingchanges.approveactiontext";
    static CIMSPendingChangesDenyAction = "cims.pendingchanges.denyactiontext";
    static CIMSPendingChangesCancelAction = "cims.pendingchanges.cancelactiontext";
    static CIMSPendingChangesApproveDenyActionTip = "cims.pendingchanges.approvedenyactiontip";
    static CIMSPendingChangesDenyTitle = "cims.pendingchanges.denytitle";
    static CIMSPendingChangesDenyTitle4School = "cims.pendingchanges.schooldenytitle";
    static CIMSPendingChangesDenyTitle4Campus = "cims.pendingchanges.campusdenytitle";
    static CIMSPendingChangesDenyReasonLabel = "cims.pendingchanges.denyreasonlabel";
    static CIMSPendingChangesDenyReasonDefaultText = "cims.pendingchanges.denyreasondefaulttext";
    static CIMSPendingChangesDenyReasonRequired = "cims.pendingchanges.denyreasonrequired";
    static CIMSPendingChangesDenyNotifyUser = "cims.pendingchanges.denynotifyuser";
    static CIMSPendingChangesDenyNotifyAdmin = "cims.pendingchanges.denynotifyadmin";
    static CIMSPendingChangesDenyNotifySchoolAdmin = "cims.pendingchanges.denynotifyschooladmin";
    static CIMSPendingChangeSearchPlaceholder = "cims.pendingchanges.searchplaceholder";
    static CIMSPendingChangeSearchPlaceholder4School = "cims.pendingchanges.searchplaceholder4school";
    static CIMSPendingChangeSearchPlaceholder4Campus = "cims.pendingchanges.searchplaceholder4campus";
    static CIMSPendingChangeSearchPlaceholder4Class = "cims.pendingchanges.searchplaceholder4backend";
    static CIMSPendingChangesEnableGroupSwitch = "cims.pendingchanges.enablegroupswitch.text";
    static CIMSPendingChangesFailedApproveDeny = "cims.pendingchanges.failedBulkApproveDeny";
    static CIMSPendingChangesFailedApproveDenyModalOk = "cims.pendingchanges.failedBulkApproveDenyOk";
    static CIMSClassChangesSearchPlaceHolder = "cims.classchanges.searchplaceholder";
    static CIMSClassChangesExportAction = "cims.classchanges.exportaction";
    static CIMSInvalidApproveActionException = "cims.message.invalidapproveexception";
    static CIMSInvalidDenyActionException = "cims.message.invaliddenyexception";
    static CIMSInvalidLicenseDenyActionException = "cims.message.invalidlicensedenyexception";
    static CIMSInvalidMovePromoteDenyActionException = "cims.message.invalidmovepromotedenyexception";
    static CIMSInvalidLicenseDenyNotEnoughLicenseException = "cims.message.notenoughlicenseexpception";
    static CIMSMsgMaterialShipped = "cims.message.materialShipped";
    static CIMSMaterialOrderId = "cims.material.orderId";
    static CIMSMaterialCampus = "cims.material.campus";
    static CIMSMaterialEmail = "cims.material.email";
    static CIMSMaterialPhone = "cims.material.phone";
    static CIMSListStateCurrent = "cims.listState.current";
    static CIMSApproveSchoolChangesPromptMessage = "cims.message.approveschoolchangespromptmessage";
    static CIMSChangeEventSchoolSubTitle = "cims.changeevent.schoolsubtitle";
    static CIMSChangeEventCampusSubTitle = "cims.changeevent.campussubtitle";
    static CIMSChangeEventCampusMaterialSubTitle = "cims.changeevent.materialsubtitle";
    static CIMSEventTypeSchoolMandatoryDataChange = "cims.eventtype.schoolmandatorydatachange";
    static CIMSEventTypeMovePromoteStudents = "cims.eventtype.movepromotestudents";
    static CIMSEventTypeAddCampus = "cims.eventtype.addcampus";
    static CIMSEventTypeCampusAddMaterialOrder = "cims.eventtype.campusmaterialorder";
    static CIMSEventTypeDisableCampus = "cims.eventtype.disablecampus";
    static CIMSEventTypeCampusMandatoryDataChange = "cims.eventtype.campusmandatorydatachange";
    static CIMSEventTypeEnableCampus = "cims.eventtype.enablecampus";
    static CIMSSchoolMandatoryAttributeChangeEventTilte = "cims.schoolmandatoryattributechangeevent.title";
    static CIMSSchoolMovePromoteStudentsEventTilte = "cims.movepromotestudentseventtitle.title";
    static CIMSAddCampusEventTilte = "cims.addcampusevent.title";
    static CIMSDisableCampusEventTilte = "cims.disablecampusevent.title";
    static CIMSDisableCampusRevertMessage = "cims.disablecampusrevert.mesage";
    static CIMSEnableCampusRevertMessage = "cims.enablecampusrevert.message";
    static CIMSCampusMandatoryAttributeChangeEventTilte = "cims.campusmandatoryattributechangeevent.title";
    static CIMSCampusMaterialOrderEventTitle = "cims.campusmaterialorderevent.title";
    static CIMSEnableCampusEventTilte = "cims.enablecampusevent.title";
    static CIMSChangeEventSchoolName = "cims.changeevent.schoolnametext";
    static CIMSChangeEventSchoolEnglishName = "cims.changeevent.schoolenglishnametext";
    static CIMSChangeEventCampusName = "cims.changeevent.campusnametext";
    static CIMSChangeEventCampusPhone = "cims.changeevent.campusphonetext";
    static CIMSChangeEventCampusFax = "cims.changeevent.campusfaxtext";
    static CIMSChangeEventCampusState = "cims.changeevent.campusstatetext";
    static CIMSChangeEventCampusCity = "cims.changeevent.campuscitytext";
    static CIMSChangeEventCampusPostalCode = "cims.changeevent.campuspostalcodetext";
    static CIMSChangeEventCampusFullAddress = "cims.changeevent.campusfulladdresstext";
    static CIMSPendingAcknowledgeCurrentFuture = "cims.pendingchanges.akcurfu";
    static CIMSPendingDenyCurrentFuture = "cims.pendingchanges.dcurfu";
    static CIMSPendingAcknowledgeCurrentFutureAll = "cims.pendingchanges.akcurfuall";
    static CIMSPendingDenyCurrentFutureAll = "cims.pendingchanges.dcurfuall";
    static CIMSPendingAcknowledgeAll = "cims.pendingchanges.akall";
    static CIMSPendingDenyAll = "cims.pendingchanges.dnall";
    static CIMSPendingAcknowledgeAllTxt = "cims.pendingchanges.akallText";
    static CIMSPendingDenyAllTxt = "cims.pendingchanges.dnallText";
    static CIMSPendingAcknowledgeSelectedTxt = "cims.pendingchanges.akselectedText";
    static CIMSPendingDenySelectedTxt = "cims.pendingchanges.dnselectedText";
    static CIMSPendingorTxt = "cims.pendingchanges.orText";
    static CIMSStudentMovePromoteCurrentClassColumn = "cims.studentmovepromote.currentclasscolumn";
    static CIMSStudentMovePromotePreviousClassColumn = "cims.studentmovepromote.previousclasscolumn";
    static CIMSStudentMovePromoteParentClassColumn = "cims.studentmovepromote.parentclasscolumn";
    static CIMSStudentMovePromoteStudentNameColumn = "cims.studentmovepromote.studentnamecolumn";
    static CIMSStudentMovePromoteStudentEnglishNameColumn = "cims.studentmovepromote.studentenglishnamecolumn";
    static CIMSStudentMovePromoteNoteColumn = "cims.studentmovepromote.notecolumn";
    static CIMSStudentMovePromoteOperationTypeColumn = "cims.studentmovepromote.operationtypecolumn";
    static CIMSStudentMovePromoteOperationTypeMove = "cims.studentmovepromote.operationtypemove";
    static CIMSStudentMovePromoteOperationTypePromoted = "cims.studentmovepromote.operationtypepromoted";
    static CIMSStudentMovePromoteOperationTypeMovePromoted = "cims.studentmovepromote.operationtypemovepromoted";
    static CIMSStudentMovePromoteOperationTypeRevertPromoted = "cims.studentmovepromote.operationtyperevertpromoted";
    static CIMSStudentMovePromoteCreatedDateColumn = "cims.studentmovepromote.createddatecolumn";

    static RegenerateInvoiceText = "regenerateinvoice.text";
    static RegenerateInvoiceActualBefore = "regenerateinvoice.actualbefore";
    static RegenerateInvoiceAdjustmentBefore = "regenerateinvoice.adjustmentbefore";
    static RegenerateInvoiceTotalBefore = "regenerateinvoice.totalbefore";
    static RegenerateInvoiceActualAfter = "regenerateinvoice.actualafter";
    static RegenerateInvoiceAdjustmentAfter = "regenerateinvoice.adjustmentafter";
    static RegenerateInvoiceTotalAfter = "regenerateinvoice.totalafter";
    static RegenerateInvoiceSuccessMsg = "regenerateinvoice.success";
    static RegenerateInvoiceFailureMsg = "regenerateinvoice.failure";
    static RegenerateInvoiceLoading = "regenerateinvoice.loading";
    static RegenerateInvoiceInProgress = "regenerateinvoice.inprogress";
    static EditClassLicenseModalSaveButton = "class_license.modal.button.save";
    static EditClassLicenseModalUpdateButton = "class_license.modal.button.update";
    static EditClassLicenseModalConfirmButtonOk = "class_license.modal.confirm.button.ok";
    static MoveStudentSaveButton = "move_student.button.save";
    static MoveStudentConfirmButtonOk = "move_student.confirm.button.ok";

    static SchoolTeacherFoundationTrainingTitle = "school.teacher.foundationtrainingtitle";
    static SchoolTeacherReferesherTrainingTitle = "school.teacher.refereshertrainingtitle";
    static SchoolTeacherVideoReviewTitle = "school.teacher.videoreviewtitle";
    static SchoolTeacherVideoReviewCountPart1 = "school.teacher.videoreviewcountpart1";
    static SchoolTeacherVideoReviewCountPart2 = "school.teacher.videoreviewcountpart2";
    static SchoolTeacherVideoReviewTimePart1 = "school.teacher.videoreviewtimepart1";
    static SchoolTeacherVideoReviewTimePart2 = "school.teacher.videoreviewtimepart2";
    static SchoolTeacherViewCourseHistoryTip = "school.teacher.viewcoursehistorytip";
    static SchoolTeacherCourseHistoryTitle = "school.teacher.coursehistorytitle";
    static SchoolTeacherCourseHistoryItemColumn = "school.teacher.coursehistoryitemcolumn";
    static SchoolTeacherCourseHistoryCourseColumn = "school.teacher.coursehistorycoursecolumn";
    static SchoolTeacherCourseHistorySeriesColumn = "school.teacher.coursehistoryseriescolumn";
    static SchoolTeacherCourseHistoryDateColumn = "school.teacher.coursehistorydatecolumn";
    static SchoolTeacherCourseHistoryCourseLabel = "school.teacher.coursehistorycourselabel";
    static SchoolTeacherCourseHistorySeriesLabel = "school.teacher.coursehistoryserieslabel";
    static SchoolTeacherVisitationNoteTitle = "school.teacher.visitationnotetitle";
    static SchoolTeacherVisitationNoteTip = "school.teacher.visitationnotetip";
    static SchoolTeacherVisitationHistoryTitle = "school.teacher.visitationhistorytitle";
    static SchoolTeacherVisitationHistoryCoachColumn = "school.teacher.visitationhistorycoachcolumn";
    static SchoolTeacherVisitationHistoryReviewDateColumn = "school.teacher.visitationhistoryreviewdatecolumn";
    static SchoolTeacherVisitationHistoryTypeColumn = "school.teacher.visitationhistorytypecolumn";
    static SchoolTeacherVisitationHistorySchoolColumn = "school.teacher.visitationhistoryschoolcolumn";
    static SchoolTeacherVisitationHistoryDetailsColumn = "school.teacher.visitationhistorydetailscolumn";
    static SearchPlaceholderClassName = "search.placeholder.class.name";

    static StudentReportMonthlyView = "student.report.monthlyview";
    static StudentReportMonthlyViewLast6 = "student.report.monthlyviewlast6";
    static StudentReportYearlyView = "student.report.yearlyview";
    static StudentReportDailyView = "student.report.dailyview";
    static StudentReportButtonY = "student.report.button.y";
    static StudentReportButtonM = "student.report.button.m";
    static StudentReportButtonD = "student.report.button.d";
    static StudentReportLabelParent = "student.report.label.parent";
    static StudentReportLabelParentPhone = "student.report.label.parentPhone";
    static StudentReportDailyLast30 = "student.report.dailyviewlast30";

    static SchoolCancelEventBtn = "school.cancel.event.btn";
    static SchoolCancelEventModalTitle = "school.cancel.event.modal.title";
    static SchoolCancelEventModalMessage = "school.cancel.event.modal.message";
    static SchoolCancelEventModalYes = "school.cancel.event.modal.yes";
    static SchoolCancelEventModalNo = "school.cancel.event.modal.no";

    static ManageStudentLoginPageHeader = "managestudentlogin.pageheader";
    static ManageStudentLoginCancel = "managestudentlogin.cancel";
    static ManageStudentLoginSave = "managestudentlogin.save";
    static ManageStudentLoginExport = "managestudentlogin.export";
    static ManageStudentLoginExportedFileName = "managestudentlogin.export.filename";
    static ManageStudentLoginColumnEnglishName = "managestudentlogin.column.englishname";
    static ManageStudentLoginColumnNativeName = "managestudentlogin.column.nativename";
    static ManageStudentLoginColumnCode = "managestudentlogin.column.code";
    static ManageStudentLoginColumnPassword = "managestudentlogin.column.password";
    static ManageStudentLoginColumnUsername = "managestudentlogin.column.username";
    static ManageStudentLoginGenerateCode = "managestudentlogin.generatecode";
    static ManageStudentLoginGeneratePassword = "managestudentlogin.generatepassword";
    static ManageStudentLoginCodeLengthInvalid = "managestudentlogin.code.lengthinvalid";
    static ManageStudentLoginCodeDuplicate = "managestudentlogin.code.duplicate";
    static ManageStudentLoginInvalidPassword = "managestudentlogin.password.invalid";
    static ManageStudentLoginSuccessMsg = "managestudentlogin.success.message";
    static ManageStudentSchoolCodeError = "managestudent.schoolcodeerror.message"
    static ManageStudentErrorCodeExists = "managestudentlogin.error.codeexists";
    static ManageStudentGenerateCodeInfo = "managestudentlogin.generatecode.infomessage";
    static ManageStudentErrorInvalidUserName = "managestudentlogin.error.invalidusername";
    static ManageStudentErrorUserNameExists = "managestudentlogin.error.usernameexists";
    static ManageStudentErrorGeneric = "managestudentlogin.error.generic";
    static PlaylistsPerWeek = "playlists.per.week";
    static ReportStartDate = "child.startdate.text";
    static ReportCalLegend = "child.report.callengend";

    static ManageClassAssociation = "manageclassassociation.modal.title";
    static ManageSchoolAssociation = "manageschoolassociation.modal.title";
    static AssociationModalAssignButton = "associationmodal.assign.button";
    static AssociationModalSearchClass = "associationmodal.search.class.text";
    static AssociationModalSearchSchool = "associationmodal.search.school.text";
    static ClassAssociationModalNote = "classassociation.modal.note";
    static AssociationModalSelectAll = "associationmodal.selectall.text";
    static AssociationModalNoResult = "associationmodal.noresult.text";
    static AssociationModalCancelButton = "associationmodal.cancel.button";
    static ClassAssociationSuccessful = "classassociation.successful.message";
    static ClassAssociationUnsuccessful = "classassociation.unsuccessful.message";
    static SchoolAssociationSuccessful = "schoolassociation.successful.message";
    static SchoolAssociationUnsuccessful = "schoolassociation.unsuccessful.message";

    static LicenseHistoryAttachmentUploadFileValidationSize = "licensehistory.attachment.uploadfile.validation.size";
    static LicenseHistoryAttachmentUploadFileValidationType = "licensehistory.attachment.uploadfile.validation.type";
    static LicenseHistoryAttachmentUploadFileValidationMissing = "licensehistory.attachment.uploadfile.validation.missing";
    static LicenseHistoryAttachmentUploadFileFailed = "licensehistory.attachment.uploadfile.failed";
    static LicenseHistoryAttachmentSuccess = "licensehistory.attachment.success";
    static LicenseHistoryAttachmentDeleteSuccess = "licensehistory.attachment.deletesuccess";
    static LicenseHistoryAttachmentDeleteConfirm = "licensehistory.attachment.deleteconfirm";
    static LicenseHistoryAttachmentUploadInvoiceTitle = "licensehistory.attachment.uploadinvoice.title";
    static LicenseHistoryAttachmentUploadInvoiceDragDrop = "licensehistory.attachment.uploadinvoice.dragdrop";
    static LicenseHistoryAttachmentUploadInvoiceFileNote = "licensehistory.attachment.uploadinvoice.file.note";
    static LicenseHistoryAttachmentUploadInvoiceLinkName = "licensehistory.attachment.uploadinvoice.link.name";
    static LicenseHistoryAttachmentUploadInvoiceLinkLink = "licensehistory.attachment.uploadinvoice.link.link";
    static LicenseHistoryAttachmentUploadInvoiceLinkNote = "licensehistory.attachment.uploadinvoice.link.note";

    static GSConnectAccess="teacher.gs_connect_access"

    static StudentAveragePlaylists = "student.averageplaylistperweekcolumn.text"
    static StudentAverageDays = "student.averagedaysperweekcolumn.text"
    static StudentAverageTime = "student.averagetimeperlogincolumn.text"
    static averageDaysInDays = "student.averagedaysindays.text"
    static averageTimeInMinutes = "student.averagetimeinminutes.text"
    static averagePlaylistsMessage = "student.averageplaylistsmessage.text"
    static averageDaysMessage = "student.averagedaysmessage.tooltip"
    static averageTimeMessage = "student.averagetimemessage.tooltip"
    static noActivityDataMessage = "student.noactivitydatamessage.text"

    static OnCampusDisableLineOne = "campus_editing.disablewarning.text.lineone";
    static OnCampusDisableLinetwo = "campus_editing.disablewarning.text.linetwo";
    static OnCampusDisableLinethree = "campus_editing.disablewarning.text.linethree";
    static OnCampusDisableWarningTitle = "campus_editing.disablewarning.title";
    static OnCampusDisableWarningFooter = "campus_editing.disablewarning.footer";
    static OnCampusDisableWarningActionOne = "campus_editing.disablewarning.action.one";
    static OnCampusDisableWarningActionTwo = "campus_editing.disablewarning.action.two";
    static CampusNexusLicenseMessage = "campus_editing.nexuslicense.options.message";
    static CampusDisableWarningInternalRole = "campus_editing.msg.disabled.internalrole";
    static CampusDisableWarningOtherRole = "campus_editing.msg.disabled.otherrole";
    static CampusEnableWarningInternalRole = "campus_editing.msg.enabled.internalrole";
    static CampusEnableWarningOtherRole = "campus_editing.msg.enabled.otherrole";
}

export class GSAdminLocale {
    static RegionsModelName = "regions.model.name";
    static RegionEnglishName = "regions.english.name";
    static RegionGroup = "region.group.title";
    static RegionGroupName = "region.group.name";
    static RegionsModelTitle = "regions.model.title";
    static RegionModelGSContentVersion = "regions.model.gsContentversion";
    static RegionModelLSContentVersion = "regions.model.lsContentversion";
    static RegionModelLessonPlanVersions = "regions.model.lessonPlanVersions";
    static RegionModelLessonPlanVersion = "regions.model.lessonPlanVersion";
    static RegionModelREPMethodologyVersion = "regions.model.repMethodologyVersion";
    static RegionModelRemoteLessonPlanVersion = "regions.model.remoteLessonPlanVersion";
    static RegionModelDigitalLessonPlanVersion = "regions.model.digitalLessonPlanVersion";
    static RegionModelManager = "regions.model.manager";
    static RegionModelLicense = "schools.model.license";
    static RegionPrimaryLanguage = "region.primary.language";
    static RegionSupportEmail = "region.support.email";
    static RegionMaterialOrderEmail = "region.material.order.email";
    static RegionBillingDay = "region.billing.day";
    static RegionCurrency = "region.currency.text";
    static RegionPhoneCode = "region.phonecode.text";
    static RegionPhoneCodeLengthErr = "region.phone.lengthErr";
    static RegionPhoneCodeFormatErr = "region.phone.formatErr";
    static RegionExtendedSubscriptionPeriod = "region.extendedsubscriptionperiod.text";
    static RegionEOrderLink = "region.eorderlink.text";
    static RegionAppUnlockDays = "region.appunlockdays.text";
    static RegionAppLockState = "region.applockstate.text";
    static RegionAppLockStateChangePrompt = "region.applockstate.changeprompt";
    static RegionDisableUnitPlanGeneration = "region.disableunitplangeneration.text";
    static RegionDisableClassPromotion = "region.disableclasspromotion.text";
    static RegionEnableEasyLogin = "region.enableeasylogin.text";
    static RegionEnablePhoneBasedLogin = "region.enable.phoneBasedLogin.txt";
    static RegionAgreeToMaterialRequestLicense = "region.agreetomaterialrequestlicense.text";
    static RegionListTitle = "region.list.title";
    static RegionListGroup = "region.list.group";
    static RegionListNewGroup = "region.list.newgroup";
    static RegionListNewRegion = "region.list.newregion";
    static RegionListSuccessGroup = "region.list.successgroup";
    static RegionListFailGroup = "region.list.failgroup";
    static RegionListStudents = "region.list.students";
    static RegionListTeachers = "region.list.teachers";
    static EditRegion = "region.text.edit";
    static RegionAdministrator = "region.text.admin"
    static PendingUsersText = "region.pending.users.text";

    static EventManagerLinkText = "eventmanager.linktext";
    static EventManagerCancelText = "eventmanager.canceltext";
    static EventManagerOkText = "eventmanager.oktext";
    static EventManagerHeading = "eventmanager.heading";
    static EventManagerSchoolCategory = "eventmanager.school.category";
    static EventManagerCampusCategory = "eventmanager.campus.category";
    static EventManagerClassCategory = "eventmanager.class.category";
    static EventManagerLicenseCategory = "eventmanager.license.category";
    static EventManagerMaterialRequestCategory = "eventmanager.materialrequest.category";
    static EventManagerStudentVerificationCategory = "eventmanager.studentverification.category";
    static EventManagerAddCampusEvent = "eventmanager.addcampus.event";
    static EventManagerDisableCampusEvent = "eventmanager.disablecampus.event";
    static EventManagerChangeCampusMandatoryDataEvent = "eventmanager.changecampusdata.event";
    static EventManagerEnableCampusEvent = "eventmanager.enablecampus.event";
    static EventManagerMaterialOrderEvent = "eventmanager.materialorder.event";
    static EventManagerChangeSchoolMandatoryDataEvent = "eventmanager.changeschooldata.event";
    static EventManagerAllowStudentVerificationEvent = "eventmanager.allowstudentverification.event";
    static EventManagerAddClassEvent = "eventmanager.addclass.event";
    static EventManagerDisableClassEvent = "eventmanager.disableclass.event";
    static EventManagerChangeClassUnitPlanEvent = "eventmanager.changeclassunit.event";
    static EventManagerChangeLicenseEvent = "eventmanager.changelicense.event";
    static EventManagerChangeClassMandatoryDataEvent = "eventmanager.changeclassdata.event";
    static EventManagerRemoveStudentEvent = "eventmanager.removestudent.event";
    static EventManagerMoveStudentEvent = "eventmanager.movestudent.event";
    static EventManagerEnableClassEvent = "eventmanager.enableclass.event";
    static EventManagerMoveUnregisteredStudentEvent = "eventmanager.moveunregisteredstudent.event";
    static EventManagerPromoteClassEvent = "eventmanager.promoteclass.event";
    static EventManagerMovePromoteStudentsEvent = "eventmanager.movepromotestudentsevent.event";

    static TimeZone = "time.zone.text";
    static DeviceActivationLimit = "device.activation.limit";
    static DeviceDeactivationThreshold = "device.deactivation.threshold";
    static DeviceDeactivationSuccess = "device.deactivation.success";
    static DeviceDeactivationFailed = "device.deactivation.error";
    static DeviceDeactivationConfirm = "device.deactivation.confirm";
    static MaxUnitsPerYear = "max.unit.peryear";
    static VisitationResetDate = "visitation.reset.date";
    static MinDaysPerUnit = "min.days.perunit";
    static AllowDisabledRegion = "allow.disabled.region";
    static RegionStatus = "region.status";
    static RegionStatusDisabled = "region.status.disabled";
    static RegionStatusEnabled = "region.status.enabled";
    static RegionStatusTesting = "region.status.testing";
    static RegionAllowPhoneSupport = "region.allowphonesupport";
    static RegionAllowTermsAgreement = "region.allowtermsagreement";
    static RegionRegistrationMode = "region.registrationMode";
    static RegionStudentRegistrationText = "region.student.registration.text";
    static RegionStudentRegistrationGeneric = "region.student.registration.generic";
    static RegionStudentRegistrationManual = "region.student.registration.manual";
    static RegionStudentRegistrationValidation = "region.student.registration.validation";
    static RegionStudentRegistrationModeValidation = "region.student.registrationmode.validation";
    static RegionMaxUnitFlagText = "region.maxunitflag.text";
    static RegionMaxUnitFlagSchool = "region.maxunitflag.school";
    static RegionMaxUnitFlagClass = "region.maxunitflag.class";
    static AllowMaterialRequest = "allow.material.request";
    static AllowParentDownload = "allow.parent.download";
    static AllowParentGSDownload = "allow.parent.gsdownload"
    static AllowParentLSDownload = "allow.parent.lsdownload"
    static AllowSetTeacherContactParent = "allow.teacher.email.parent";
    static EnforceUnitPlanSpacing = "allow.enforce.unit.plant.spacing";
    static AllowSetParentContactTeacher = "allow.parent.email.teacher";
    static AllowSchoolEditUnit = "allow.school.edit.unit";
    static AllowSchoolEditLicense = "allow.school.edit.license";
    static ResetCampusAnnualPreparationComplete = "reset.campus.annual.preparation.complete";
    static DisabledRegionTip = "disabled.region.tip";
    static EnableRegionTip = "enable.region.tip";
    static RegionEditName = "users.edit.name";
    static RegionEditEnglishName = "invitation_registernew.englishname.text";
    static RegisterEnglishNamePlaceHolder = "invitation_registernew.englishname.placeholder";
    static RegionEditEmail = "users.model.email";
    static RegionEditTemplate = "users.invite.template";

    static UserEditRoleType = "users.edit.roletype";
    static UserEditAccessResource = "users.edit.accessresource";
    static UserEditName = "users.edit.name";
    static UserEditPhone = "users.edit.phone";
    static SchoolTeacherEmail = "school_teacher.emailcolumn.text";
    static DeviceLimitOverride = "device.limit.override";
    static UsersModelName = "regions.model.name";
    static UserRegionFieldName = "users.model.region";
    static UserSchoolFieldName = "users.schoolfield.name";
    static UserRoleFieldName = "users.model.role";
    static UserNameFieldName = "users.model.nameemailphone";
    static UserInvitationName = "users.edit.name";
    static UserInvitationEmail = "users.model.email";
    static UserInvitationType = "users.invite.type";
    static UserInvitationTemplate = "users.invite.template";

    static UserModelName = "users.edit.name";
    static UserModelPhone = "users.edit.phone";
    static UserModelEmail = "users.model.email";
    static UserModelRegion = "users.model.region";
    static UserModelGender = "users.model.gender";
    static UserModelCity = "users.model.city";
    static UserModelRole = "users.model.role";
    static UserModelDisabled = "users.model.disabled";
    static UserModelBirthday = "users.model.birthday";
    static UserModelChildren = "users.model.children";

    static UserPendingName = "users.pendingchanges.name";
    static UserPendingEmail = "users.pendingchanges.email";
    static UserPendingEmailPhone = "users.pendingchanges.emailphone";
    static UserPendingType = "users.pendingchanges.type";
    static UserPendingCreationDate = "users.pendingchanges.creationdate";

    static UsersListTitle = "users.list.title";
    static UsersListRole = "users.list.roles";
    static UsersListNameEmail = "users.list.nameemail";
    static UsersListAll = "users.list.all";
    static UsersListSearch = "users.list.search";
    static UsersListInvite = "users.list.invite";
    static UsersEditRemoveRole = "users.edit.removerole";
    static UsersEditRole = "users.edit.editrole";
    static UsersEditDelete = "users.edit.delete";
    static UsersEditRoleExist = "users.edit.roleexist";
    static UsersEditUser = "users.edit.edituser";
    static UsersEditDisabled = "users.edit.disabled";
    static UsersEditPermission = "users.edit.permission";
    static UsersEditAllowTesting = "users.edit.allowtesting";
    static UsersEditAllowNexus = "users.edit.allownexus";
    static UsersEditPermissions = "users.edit.permissions";
    static UsersEditRolesAndAssociation = "users.edit.rolesAndAssociation";
    static UsersImpersonation = "users.edit.impersonation";
    static UsersEditNewRole = "users.edit.newrole";
    static UsersEditDelUser = "users.edit.deluser";
    static UsersEditRoles = "users.edit.roles";
    static UsersRemoveResource = "users.remove.resource";
    static UsersRemoveResourceContent = "users.removeresource.content";
    static UsersRoleRemoveSucc = "users.role.removeSuccess";
    static UsersRoleRemoveFail = "users.role.removeFail";
    static UsersRoleRemoveLooseWarn = "users.role.removeLooseWarn";
    static UsersRoleRemoveLossWarn = "users.role.removeLossWarn";
    static UsersRoleRemoveConfirmYes = "user.role.removeConfirmYes";
    static UsersRoleRemoveConfirmNo = "user.role.removeConfirmNo";
    static UsersContentAdminRole = "user.role.contentAdminRole";
    static UsersRemoveRoleTooltip = "user.role.removeRoleTooltip";
    static UsersRemoteTsiToggleFail = "user.remotetsi.togglefail";
    static UsersRemoteTsiGrantButton = "user.remotetsi.grantbutton";
    static UsersRemoteTsiRevokeButton = "user.remotetsi.revokebutton";

    static GrapeSEEDByRegion = "dashboard.text.region";
    static LicenseCountView = "dashboard.text.licensecountview";
    static DigitalAdoptionView = "dashboard.text.digitaladoptionview";
    static TotalLicense = "dashboard.text.total";
    static LittleSEEDLicense = "dashboard.text.littleseed";
    static GrapeSEEDLicense = "dashboard.text.grapeseed";
    static DigitalLicense = "dashboard.text.digital";
    static TextBookLicense = "dashboard.text.textbook";
    static DigitalUsers = "dashboard.text.digitalusers";
    static GrapeSEEDText = "grapeseed.text";
    static CheckAll = "dashboard.text.checkall";
    static UnCheckAll = "dashboard.text.uncheckall";
    static Export = "dashboard.text.exportcount";
    static ExportAdoption = "dashboard.text.exportdigital";
    static SchoolRegionFieldName = "users.model.region";
    static SchoolFieldName = "users.schoolfield.name";
    static SchoolModelTitle = "schools.model.title";
    static SchoolModelImageType = "schools.model.imagetype";
    static SchoolModelImageSize = "schools.model.imagesize";
    static SchoolModelChangeSubType = "schools.model.changesubtype";
    static SchoolModelDelSchool = "schools.model.delschool";
    static SchoolModelSureDelSchool = "schools.model.suredelschool";
    static SchoolModelMainCampus = "schools.model.maincampus";
    static SchoolModelDragLogo = "schools.model.draglogo";
    static SchoolModelChangeAvatar = "schools.model.changeavatar";
    static SchoolModelRemoveAvatar = "schools.model.removeavatar";
    static SchoolModelCode = "schools.model.code";
    static SchoolModelSchoolCode = 'schools.model.school.code';
    static SchoolModelCampus = "schools.model.campus";
    static SchoolModelName = "schools.model.schoolName";
    static SchoolModelEnglishName = "schools.model.englishName";
    static SchoolModelCity = "users.model.city";
    static SchoolModelPrincipal = "schools.model.principal";
    static SchoolModelRegion = "users.model.region";
    static SchoolModelSubscriptionType = "schools.model.subscriptionType";
    static SchoolModelSubscriptionTypeUsage = "schools.model.subscriptionTypeUsage";
    static SchoolModelAppLockState = "schools.model.appLockState";
    static SchoolModelPreviousTotal = "schools.model.previousTotal";
    static SchoolModelCurrentTotal = "schools.model.currentTotal";
    static SchoolModelDigitalLicense = "schools.model.digitalLicense";
    static SchoolModelTextbookLicense = "schools.model.textbookLicense";
    static SchoolModelDualLicense = "schools.model.dualLicense";
    static SchoolModelMaxUnitLS = "schools.model.maxUnit_LS";
    static SchoolModelMaxUnitGS = "schools.model.maxUnit_GS";
    static SchoolModelLittleSEEDLicense = "schools.model.littleSeedLicense";
    static SchoolModelDigital = "schools.model.digital";
    static SchoolModelTextbook = "schools.model.textbook";
    static SchoolModelLittleSEED = "schools.model.littleSeed";
    static SchoolModelLicense = "schools.model.license";
    static SchoolModelSchoolName = "schools.model.schoolName";
    static SchoolModelAdmin = "schools.model.schoolAdmin";
    static SchoolModelMaxUnit = "schools.model.maxUnit";
    static SchoolModelTrainer = "schools.model.trainer";
    static SchoolModelAvatar = "schools.model.avatar";
    static SchoolModelDisabled = "schools.model.disabled";
    static SchoolModelClass = "schools.model.class";
    static SchoolModelBillingDate = "schools.model.billingDate";
    static SchoolModelLicenseType = "schools.model.licenseType";
    static SchoolModelPrepComplete = "schools.model.prepcomplete";
    static SchoolModelStudentCount = "schools.model.studentCount";
    static SchoolModelGSCount = "schools.model.gs_count";
    static SchoolModelLSCount = "schools.model.ls_count";
    static SchoolModelTags = "schools.model.tags";
    static SchoolModelChanges = "schools.model.changes";
    static SchoolModeNotes = "schools.model.notes";
    static SchoolModeNotesPlaceHolder = "schools.model.notesplaceholder";
    static SchoolModelCodeError = "schools.model.codeerrormsg";
    static SchoolPendingChangesPromptMessage = "schools.pendingchanges.promptmessage";
    static SchoolPendingChangesPromptMessage4Coach = "schools.pendingchanges.promptmessage4coach";
    static SchoolTeachingSettingError = "schools.teaching.setting.Error";
    static SchoolAppLockStateChangePrompt = "school.applockstate.changeprompt";

    static CampusName = "campus.text.name";
    static CampusManage = "campus.text.manage";
    static CampusPhone = "campus.text.phone";
    static CampusFax = "campus.text.fax";
    static CampusState = "campus.text.state";
    static CampusCity = "campus.text.city";
    static CampusPostalCode = "campus.text.postalCode";
    static CampusAddress1 = "campus.text.address1";
    static CampusAddress2 = "campus.text.address2";
    static CampusAddressFull = "campus.text.addressfull";

    static ChangeReleaseName = "changelog.change.release.name";
    static ChangeDetails = "change.details";
    static ChangeReferenceLink = "change.referenceLink";
    static ChangeArea = "change.area";
    static ChangeType = "change.type";
    static ChangePublishStatus = "change.publish.status";

    static SchoolClassLicenseModelStudentCount = "school.class.licensemodelstudentCount";
    static SchoolClassLicenseModelDigitalLicense = "school.class.licensemodeldigitalLicense";
    static SchoolClassLicenseModelTextbookLicense = "school.class.licensemodeltextbookLicense";

    static SchoolBillingModelPeriod = "school.billing.model.billingPeriod";
    static SchoolBillingModelCloseDate = "school.billing.model.closeDate";

    static SchoolUserModelName = "school.user.model.name";
    static SchoolUserModelEmail = "school.user.model.email";
    static SchoolEditTitle = "school.edit.title";
    static SchoolDisabledMessage = "school.disabled.message";
    static SchoolEnabledMessage = "school.enabled.message";
    static SchoolEditErr = "school.edit.err";
    static SchoolVisitationTitle = "school.visitation.title";
    static SchoolLicenseTitle = "school.license.title";
    static SchoolLicenseEdit = "school.license.edit";
    static SchoolManageSchool = "school.manage.school";
    static SchoolCardGoToPortal = "school.card.gotoportal";
    static SchoolCardEdit = "school.card.edit";
    static SchoolCardGsNumber = "school.card.gsnumber";
    static SchoolCardSubscriptionType = "school.card.subscriptiontype";
    static SchoolCardMaxUnit = "school.card.maxunit";
    static SchoolCardTitleAdmin = "school.card.titleadmin";
    static SchoolCardTitleAdmins = "school.card.titleadmins";
    static SchoolCardTitleTrainer = "school.card.titletrainer";
    static SchoolCardTitleTrainers = "school.card.titletrainers";
    static SchoolCardNone = "school.card.none";
    static SchoolAdminTitle = "school.admin.title";
    static SchoolAdminInvite = "school.admin.invite";
    static SchoolAdminUserTitle = "school.admin.usertitle";
    static SchoolAdminRemove = "school.admin.remove";
    static SchoolAdminNamePending = "school.admin.namepending";
    static SchoolAdminPending = "school.admin.pending";
    static SchoolTrainerTitle = "school.trainer.title";
    static RegionTrainerTitle = "region.trainer.title";

    static SchoolTrainerInvite = "school.trainer.invite";
    static RegionTrainerInvite = "region.trainer.invite";

    static SchoolTrainerUserTitle = "school.trainer.usertitle";
    static SchoolTrainerRemove = "school.trainer.remove";
    static SchoolTrainerNamePending = "school.trainer.namepending";
    static SchoolTrainerPending = "school.trainer.pending";

    static SchoolTrainerStageOnGoing = "school.trainer.stageongoing";
    static SchoolTrainerStagePending = "school.trainer.stagepending";
    static SchoolTrainerStageComplete = "school.trainer.stagecomplete";
    static SchoolTrainerVisitationHistoryTitle = "school.trainer.visitationhistorytitle";
    static SchoolTrainerTrainingPathLabel = "school.trainer.trainingpathlabel";
    static SchoolTrainerFeedbackTitle = "school.tainer.feedbacktitle";

    static Search = "search.text.title";
    static SearchText = "search.text.field";
    static ResultTitle = "search.result.title";
    static NoResult = "search.no.result";
    static SearchCategorySchools = "search.category.schools";
    static SearchCategoryCampuses = "search.category.campuses";
    static SearchCategoryClasses = "search.category.classes";
    static SearchCategoryStudents = "search.category.students";
    static SearchCategoryUsers = "search.category.users";
    static SchoolAdministrator = "school.text.administrator";
    static CampusAdministrator = "campus.text.administrator";
    static TeachingClasses = "teaching.text.classes";

    static InvitationTemplateListTitle = "invitation.template.listtitle";
    static InvitationTemplateAdd = "invitation.template.add";
    static InvitationTemplateDisabled = "invitation.template.disabled";
    static InvitationTemplateName = "invitation.template.name";
    static InvitationTemplateNewTitle = "invitation.template.newtitle";
    static InvitationTemplateTagValid = "invitation.template.tagvalid";
    static InvitationTemplateDelete = "invitation.template.delete";
    static InvitationTemplateSureDelete = "invitation.template.suredelete";
    static InvitationTemplateTitle = "invitation.template.title";
    static InvitationTemplateLanguage = "invitation.template.language";
    static InvitationTemplateType = "invitation.template.type";
    static InvitationTemplateText = "invitation.template.text";
    static InvitationTemplateRegion = "invitation.template.region";
    static InvitationTemplateTags = "invitation.template.tags";
    static InvitationTemplateTagTargetName = "invitation.template.tagtargetname";
    static InvitationTemplateTagTargetEmail = "invitation.template.tagtargetemail";
    static InvitationTemplateTagRegion = "invitation.template.tagregion";
    static InvitationTemplateTagSchool = "invitation.template.tagschool";
    static InvitationTemplateTagCampus = "invitation.template.tagcampus";
    static InvitationTemplateTagClass = "invitation.template.tagclass";
    static InvitationTemplateTagInvitationCode = "invitation.template.taginvitationcode";
    static InvitationTemplateTagQRcode = "invitation.template.tagqrcode";
    static InvitationTemplateTagAcceptLink = "invitation.template.tagacceptlink";
    static InvitationTemplateTagInvitationLink = "invitation.template.taginvitationlink";
    static InvitationTemplateTagExpiredDate = "invitation.template.tagexpiredate";
    static InvitationRegisterRedeemCode = "invitation.register.redeemcode";
    static InvitationRegisterEmail = "invitation.register.email";
    static InvitationRegisterFindEmail = "invitation.register.findemail";
    static InvitationRegisterFinished = "invitation.register.finished";
    static InvitationCreate = "invitation.text.create";
    static InvitationDelete = "invitation.text.delete";
    static InvitationNew = "invitation.text.new";
    static InvitationUsers = "invitation.text.users";
    static InvitationSubject = "invitation.text.subject";
    static InvitationCode = "invitation.text.code";
    static InvitationType = "invitation.type";
    static InvitationNext = "invitation.text.next";
    static InvitationTitle = "invitation.text.title";
    static InvitationPreviewSMSTitle = "invitation.text.previewsmstitle";
    static InvitationInvitations = "invitation.text.invitations";
    static InvitationResendSuccess = "invitation.resend.success";
    static InvitationResendMessageSuccess = "invitation.resend.message.success";

    static InvitationCodeTitle = "invitation.code.title";
    static InvitationValidErrMsg = "invitation.valid.errmsg";
    static InvitationValidCodes = "invitation.valid.codes";
    static InvitationProcessing = "invitation.text.processing";
    static InvitationFailedTitle = "invitation.valid.failed.title";
    static InvitationFailed = "invitation.valid.failed";
    static GoToHome = "go.to.home";
    static InvitationSuccessTitle = "invitation.valid.successtitle";
    static InvitationSuccess = "invitation.valid.success";
    static InvitationSuccessForGH = "invitation.success.gh";
    static InvitationGhOrTaTitle = "invitation.accept.globalheadOrtraineradmintitle";
    static InvitationRegionAdminTitle = "invitation.accept.regionadmintitle";
    static InvitationTrainerTitle = "invitation.accept.trainertitle";
    static InvitationExistingAccount = "invitation.existing.account";
    static InvitationCurrentAccount = "invitation.current.account";
    static InvitationCurrentText = "invitation.current.text";
    static InvitationExistingText = "invitation.existing.text";
    static InvitationRegisterText = "invitation.register.text";
    static InvitationModalEmailNotRegister = "invitation.modal.emailnotregister";
    static InvitationModalNotRegister = "invitation.modal.notregister";
    static InvitationModalNotRegisterDesc = "invitation.modal.notregisterdesc";
    static InvitationModalTitle = "invitation.modal.title";
    static InvitationModalPreview = "invitation.modal.preview";
    static InvitationModalClose = "invitation.modal.close";
    static InvitationModalBack = "invitation.modal.back";
    static InvitationModalUser = "invitation.modal.user";
    static InvitationModalEmailPreview = "invitation.modal.emailpreview";
    static InvitationModalSubject = "invitation.modal.subject";
    static InvitationModalTo = "invitation.modal.to";
    static InvitationModalTemplate = "invitation.modal.template";
    static InvitationModalName = "invitation.modal.name";
    static InvitationModalEmail = "invitation.modal.email";
    static InvitationModalPhone = "invitation.modal.phone";
    static InvitationModalInvitationType = "invitation.modal.invitationtype";

    static ValidMsg = "validate.text.message";
    static RegisterAccount = "register.text.account";
    static RegisterHeader = "register.text.header";
    static RedeemExistingTitle = "redeem.existing.title";
    static RedeemBtn = "redeem.text.title";
    static InvalidNameOrPassword = "invalid.name.password";
    static NoUser = "no.text.user";
    static InvitationDisabled = "invitation.text.disabled";
    static CodeHasBeenUsed = "code.has.beenused";
    static LoginFirstTitle = "login.first.title";
    static RegisterName = "register.text.name";
    static RegisterEmail = "register.text.email";
    static RegisterPassword = "register.text.password";
    static RegisterConfirmPassword = "register.confirm.password";
    static RegisterSuccessFirst = "register.success.first";
    static RegisterSuccessSecond = "register.success.second";
    static PrivacyPolicy = "privacy.text.policy";
    static RedeemExistingEmail = "redeem.existing.email";
    static RedeemExistingPassword = "redeem.existing.password";
    static InvitationPreviewTemplate = "invitation.preview.template";
    static InvitationSendEmailTip = "invitation.send.email.tip";
    static InvitationSendSMSTip = "invitation.send.sms.tip";
    static InvitationSendSuccess = "invitation.send.success";
    static InvitationSendMessageSuccess = "invitation.send.message.success";
    static InvitationRemoveFail = "invitation.remove.fail";
    static InvitationRemoveMessageFail = "invitation.remove.message.fail";
    static InvitationRemoveTip = "invitation.remove.tip";
    static InvitationRemovePrompt = "invitation.remove.prompt";
    static PasswordInvalid = "password.text.invalid";

    static InvalidVerificationCode = "verificationcode.text.invalid";

    static UserRemoveRolePrompt = "user.remove.role";

    static ErrorDeleteForTargetUsed = "error.delete.targetused";

    static ReleaseNameExists = "changelog.release.releaseNameExists";
    static ReleaseChangeRequired = "changelog.release.changeRequired";
    static ReleaseDeleteConfirm = "changelog.release.deleteChangeConfirmation";
    static ReleaseChangeArea = "changelog.release.changeArea";
    static ReleaseChangeType = "changelog.release.changeType";
    static ReleaseChangeDetails = "changelog.release.changeDetails";
    static ReleaseReferenceLink = "changelog.release.referenceLink";
    static ReleaseTableHeaderAction = "changelog.release.table.action";
    static ModelTitleMessage = "changelog.model.title.message";
    static ModelTitleConfirmation = "changelog.model.title.confirmation";
    static ModelButtonYes = "changelog.model.button.yes";
    static ModelButtonNo = "changelog.model.button.no";
    static ButtonSave = "button.common.save";
    static ButtonDelete = "button.common.delete";
    static ButtonOk = "button.common.ok";
    static ButtonBackToList = "button.common.backtolist";
    static ButtonSubmit = "button.common.submit";
    static ButtonCancel = "button.common.cancel";
    static ButtonClose = "button.common.close";
    static ReleaseButtonAddChange = "changelog.release.button.addChange";
    static ButtonGoBack = "button.common.goBack";
    static EditChangeModelTitle = "changelog.editChange.model.title";
    static AddChangeModelTitle = "changelog.addChange.model.title";
    static ChangeLogTitle = "changelog.title";
    static ButtonAddNewRelease = "changelog.button.addRelease";
    static ButtonEdit = "changelog.button.edit";
    static ChangeLogRelease = "changelog.release";
    static ButtonWait = "changelog.button.wait";
    static ReleaseGoBackConfirm = "changelog.release.goback.Confirmation";
    static ChangeLogNoData = "changelog.nodata";
    static ChangeLogCopyLink = "changelog.copyLink";
    static ChangeLogCopyLinkMessage = "changelog.copyLink.message";
    static LabelPublishDate = "changelog.confirm.publish.date";
    static ReleaseType = "changelog.release.type";
    static ReleaseTypePublic = "changelog.release.type.public";
    static ReleaseTypePrivate = "changelog.release.type.private";
    static TooltipSelectRelease = "changelog.tooltip.release";
    static TooltipSelectChangeArea = "changelog.tooltip.changeArea";
    static NewerReleaseCheckText = "changelog.text.newerRelease";
    static TooltipNewerReleaseCheck = "changelog.tooltip.newerRelease";
    static AllOption = "changelog.option.all";
    static ChangeLogFilterFrom = "changelog.filter.from";
    static ChangeLogFilterTo = "changelog.filter.to";
    static ReleaseTypeInternal = "changelog.release.type.internal";
    static ButtonPublishToTest = "changelog.button.publishToTest";
    static ButtonPublishToLive = "changelog.button.publishToLive";
    static ReleaseSavePublishToTestConfirm = "changelog.release.save.publishToTest.Confirmation";
    static ReleasePublishToTestConfirm = "changelog.release.publishToTest.Confirmation";
    static ReleaseSavePublishToLiveConfirm = "changelog.release.save.publishToLive.Confirmation";
    static ReleasePublishToLiveConfirm = "changelog.release.publishToLive.Confirmation";
    static ChangeLogPublishToTestSuccess = "changelog.publishedToTest.success";
    static ChangeLogPublishToLiveSuccess = "changelog.publishedToLive.success";
    static ButtonSaveAndPublishToTest = "changelog.button.saveAndPublishToTest";
    static ButtonSaveAndPublishToLive = "changelog.button.saveAndPublishToLive";
    static InvalidFromDateMessage = "changelog.invalidFromDateMessage";
    static InvalidToDateMessage = "changelog.invalidToDateMessage";

    static ListStatesActive = "gsadmin.common.liststatesactive";
    static ListStatesAll = "gsadmin.common.liststatesall";
    static ListStatesInactive = "gsadmin.common.liststatesinactive";

    static ProductName = "product.common.nametext";
    static ProductLicenseType = "product.common.licensetypetext";
    static ProductUnit = "product.common.unittext";
    static ProductStartUnit = "product.common.startunittext";
    static ProductEndUnit = "product.common.endunittext";
    static ProductDisabled = "product.common.disabledtext";
    static ProductAdd = "product.common.add";
    static ProductEditButton = "product.common.edit.button";
    static ProductSubAdd = "product.sub.add";
    static ProductUnitAddPrice = "product.unit.addprice";
    static ProductUnitEditPrice = "product.unit.edit.price";
    static ProductProductTypeGrapeseed = "product.producttype.grapeseed";
    static ProductProductTypeLittleseed = "product.producttype.littleseed";
    static ProductProductTypeGeneral = "product.producttype.general";
    static ProductClassNotSet = "product.class.notset";
    static GlobalProductListTitle = "globalproduct.list.title";
    static GlobalProductListNew = "globalproduct.list.new";
    static GlobalProductModelNew = "globalproduct.model.new";
    static GlobalProductModelName = "globalproduct.model.name";
    static GlobalProductModelVersionGroup = "globalproduct.model.versiongroup";
    static GlobalProductModelUnit = "globalproduct.model.unit";
    static GlobalProductModelDisabled = "globalproduct.model.disabled";
    static GlobalProductContentVersion = "globalproduct.contentversion.text";
    static GlobalProductNotFound = "globalproduct.message.notfound";
    static GlobalProductCanNotBeDelete = "globalproduct.message.cannotbedelete";

    static LocalProductPrice = "localproduct.price.text";
    static LocalProductPriceRemoveTip = "localproduct.price.removetip";
    static LocalProductPriceDuplicateMessage = "localproduct.message.unitpriceduplicate";
    static LocalProductPriceStartUnitErrorMessage = "localproduct.message.startuniterror";
    static LocalProductPriceEndUnitErrorMessage = "localproduct.message.enduniterror";
    static LocalProductPricesRequired = "localproduct.message.pricerequired";
    static LocalProductPricesConfusion = "localproduct.message.priceconfusion";
    static LocalProductPricesUnitConfusion = "localproduct.message.priceunitconfusion";
    static LocalProductPricesOverflow = "localproduct.message.priceoverflow";
    static LocalProductCurrency = "localproduct.currency.text";
    static LocalProductGlobal = "localproduct.global.text";
    static LocalProductGlobalErrorMessage = "localproduct.message.globalerror";
    static LocalProductTextbookSubscription = "localproduct.textbooksubscription.text";
    static LocalProductDefaultQuantityToLicenseCount = "localproduct.defaultquantitytolicensecount.text";
    static LocalProductDigitalSubscription = "localproduct.digitalsubscription.text";
    static LocalProductIsPurchase = "localproduct.ispurchase.text";
    static LocalProductAllowPurchase = "localproduct.allowpurchase.text";
    static LocalProductSubProducts = "localproduct.subproducts.text";
    static LocalProductSubProductsName = "localproduct.subproducts.namecolumntext";
    static LocalProductSubProductsRemoveTip = "localproduct.subproducts.removetip";
    static LocalProductSubscriptionErrorMessage = "localproduct.message.subscriptionerror";
    static LocalProductSubProductUnitConfusion = "localproduct.message.subproductunitconfusion";
    static LocalProductNotFound = "localproduct.message.notfound";
    static LocalProductNameDuplicate = "localproduct.message.nameduplicate";
    static LocalProductPricesCountError = "localproduct.message.pricecounterror";
    static LocalProductPricesUnitError = "localproduct.message.priceuniterror";
    static LocalProductCanNotBeDelete = "localproduct.message.cannotbedelete";
    static LocalProductCanNotBeDisabled = "localproduct.message.cannotbedisabled";
    static LocalProductListTitle = "localproduct.list.title";
    static LocalProductListSearchPlaceHolder = "localproduct.list.searchplaceholder";
    static LocalProductListNew = "localproduct.list.new";
    static LocalProductModelNew = "localproduct.model.new";
    static LocalProductModelDelete = "localproduct.model.delete";
    static LocalProductEditTitle = "localproduct.edit.title";
    static LocalProductNewTitle = "localproduct.new.title";
    static LocalProductDeleteTitle = "localproduct.delete.title";

    static NotificationTextRoleName = "notification.text.rolename";
    static NotificationTextTargetTypeName = "notification.text.targettypename";
    static NotificationTextTargetName = "notification.text.targetname";
    static NotificationTextName = "notification.text.name";
    static NotificationTextEmail = "notification.text.email";
    static NotificationEmailLimited = "notification.email.limit";
    static NotificationTargetEmailLimited = "notification.targetemail.limit";
    static NotificationsNoData = "notificationlist.no.data";
    static NotificationsShowarchived = "notificationlist.text.showarchived";
    static NotificationsCleared = "notifications.cleared";
    static NotificationsCreateArchived = "notificationarchived.create";
    static NotificationArchivedCreated = "notificationarchived.create.msg";
    static NotificationDetail = "notificationdetail.error.remove";
    static NotificationCreateDelete = "notificationcreate.text.delete";
    static NotificationCreateSureDelete = "notificationcreate.text.suredelete";
    static NotificationCreateEarlierDate = "notificationcreate.earlier.date";
    static NotificationCreateLaterDate = "notificationcreate.later.date";
    static NotificationCreateRoles = "notificationcreate.text.roles";
    static NotificationCreateUsers = "notificationcreate.text.users";
    static NotificationCreateNotify = "notificationcreate.text.notify";
    static NotificationCreateNotifyValidate = "notificationcreate.notify.validate";
    static NotificationCreateNotifyAddRole = "notificationcreate.notify.addrole";
    static NotificationCreateNotifyAddUser = "notificationcreate.notify.adduser";
    static NotificationCreateNotifyCount = "notificationcreate.notify.count";
    static NotificationCreateSubmit = "notificationcreate.text.submit";
    static NotificationCreateAdvancedEditor = "notificationcreate.text.advancededitor";
    static NotificationPopupTitle = "notification.popup.title";
    static NotificationPopupTitleOne = "notification.popup.title_one";
    static NotificationCreateCancel = "notificationcreate.text.cancel";
    static NotificationCreateRole = "notificationcreate.text.role";
    static NotificationCreateTargetType = "notificationcreate.text.targettype";
    static NotificationCreateTarget = "notificationcreate.text.target";
    static NotificationCreateName = "notificationcreate.text.name";
    static NotificationCreateEmail = "notificationcreate.text.email";
    static NotificationSendEmailToMore3k = "notificationsendemails.notify.message";
    static NotificationCreateSearchName = "notificationcreate.search.name";
    static NotificationCreateNotifyRoleTarget = "notificationcreate.notify.roletarget";
    static NotificationCreateAll = "notificationcreate.text.All";
    static NotificationCreateTeacher = "notificationcreate.text.teacher";
    static NotificationCreateParent = "notificationcreate.text.parent";
    static NotificationCreateCampusAdministrator = "notificationcreate.text.campusadministrator";
    static NotificationCreateSchoolAdministrator = "notificationcreate.text.schooladministrator";
    static NotificationCreateTrainingManager = "notificationcreate.text.trainingmanager";
    static NotificationCreateRegionAdministrator = "notificationcreate.text.regionadministrator";
    static NotificationCreateTrainingAdministrator = "notificationcreate.text.trainingadministrator";
    static NotificationCreateTrainer = "notificationcreate.text.trainer";
    static NotificationCreateGlobalHead = "notificationcreate.text.globalhead";
    static NotificationCreateAccountManager = "notificationcreate.text.accountmanager";
    static NotificationCreateRegion = "notificationcreate.text.region";
    static NotificationCreateSchool = "notificationcreate.text.school";
    static NotificationCreateCampus = "notificationcreate.text.campus";
    static NotificationCreateSchoolClass = "notificationcreate.text.schoolclass";
    static NotificationCreateSelectRegion = "notificationcreate.select.region";
    static NotificationCreateSelectSchool = "notificationcreate.select.school";
    static NotificationCreateSelectCampus = "notificationcreate.select.campus";
    static NotificationCreateSelectSchoolClass = "notificationcreate.select.schoolclass";
    static NotificationManageNotifications = "notificationmanage.text.notifications";
    static NotificationManageListStatesMine = "notificationmanage.text.liststatesmine";
    static NotificationManageListStatesOthers = "notificationmanage.text.liststatesothers";
    static NotificationCreateMyCreated = "notificationcreate.text.mycreated";
    static NotificationCreateNotifications = "notificationcreate.text.notifications";
    static NotificationCreateReview = "notificationreview.text.review";
    static NotificationCreateAdd = "notificationcreate.text.add";
    static NotificationCreateMyCreatedList = "notificationcreate.mycreated.list";
    static NotificationCreateTitle = "notificationcreate.text.title";
    static NotificationCreateDescription = "notificationcreate.text.description";
    static NotificationCreateLinkText = "notificationcreate.text.linktext";
    static NotificationCreateLinkUrl = "notificationcreate.text.linkurl";
    static NotificationCreateLinkUrlLegal = "notificationcreate.text.linkurllegal";
    static NotificationCreateLinkTitle = "notificationcreate.text.linktitle";
    static NotificationCreateLink = "notificationcreate.text.link";
    static NotificationCreateStartDate = "notificationcreate.text.startDate";
    static NotificationCreateEndDate = "notificationcreate.text.endDate";
    static NotificationCreateNotificationType = "notificationcreate.text.notificationType";
    static NotificationCreateSticky = "notificationcreate.text.sticky";
    static NotificationCreateChannel = "notificationcreate.text.channel";
    static NotificationCreateChannelBulletinBoard = "notificationcreate.text.bulletinboardchannel";
    static NotificationCreateChannelEmail = "notificationcreate.text.emailchannel";
    static NotificationCreateCheckUsers = "notificationcreate.text.checkusers";
    static NotificationRequireDescription = "notificationcreate.description.required";
    static NotificationMaxLengthDescription = "notificationcreate.description.maxlength";

    static NotificationReviewNotified = "notificationreview.review.notified";
    static NotificationReviewPublish = "notificationreview.review.publish";
    static NotificationReviewDeny = "notificationreview.review.deny";
    static NotificationReviewList = "notificationreview.review.list";
    static NotificationReviewApprove = "notificationreview.review.approve";
    static NotificationReviewReject = "notificationreview.review.reject";
    static NotificationReviewApproveMsg = "notificationreview.review.approvemsg";
    static NotificationReviewRejectMsg = "notificationreview.review.rejectmsg";

    static NotificationMsgSubmitError = "notification.message.submiterror";
    static NotificationMsgUpdateError = "notification.message.updateerror";
    static NotificationDeleteUnauthorizedError = "notification.delete.unauthorizederror";
    static NotificationUpdateUnauthorizedError = "notification.update.unauthorizederror";
    static NotificationRoleTargetsError = "notification.roletargets.error";

    static NotificationTarget = "notification.target";
    static NotificationSeenBy = "notification.seen.by";

    static NotificationMarkAllAsRead = "notification.mark.all.as.read";
    static NotificationClearAll = "notification.clear.all";

    static NotificationSwitchTypeErrorRole = "notification.switchtypeerror.role";
    static NotificationSwitchTypeErrorUser = "notification.switchtypeerror.user";

    static ChatErrorCannot = "chat.error.cannotchat";
    static ChatThreadTitleIllegal = "chat.thread.title.illegal";
    static Chat = "chat.text.chat";
    static MyChat = "chat.text.mychat";
    static ChatUnReadMessages = "chat.text.unread";
    static SenderPlaceHolder = "chat.text.senderplaceholder";
    static ChatHeader = "chat.text.header";
    static ContentResource = "resource.text.content";
    static TrainingResource = "resource.text.training";
    static LocalContent = "resource.text.localcontent";
    static ResourceSearch = "resource.text.search";
    static ChatThread = "chat.text.thread";
    static OpenNextUnReadThread = "chat.text.opennextunreadthread";
    static ChatTeacherName = "chat.text.teachername";
    static ChatTrainerName = "chat.text.coachname";
    static ChatThreadNew = "chat.text.createNewThread";
    static MyChatThreadSearch = "chat.text.mychat.search.thread";
    static ChatHistoryThreadSearch = "chat.text.history.search.thread";

    static ChatHistory = "chat.text.history";

    static BreadcrumbHome = "breadcrumb.text.home";
    static BreadcrumbDashboard = "breadcrumb.text.dashboard";
    static BreadcrumbAdmins = "breadcrumb.text.admins";
    static BreadcrumbBilling = "breadcrumb.text.billing";
    static BreadcrumbTemplates = "breadcrumb.text.templates";
    static BreadcrumbGlobalProducts = "breadcrumb.text.globalproducts";
    static BreadcrumbLocalProducts = "breadcrumb.text.localproducts";
    static BreadcrumbManageNotifications = "breadcrumb.text.managenotifications";
    static BreadcrumbCreated = "breadcrumb.text.created";
    static BreadcrumbRegions = "breadcrumb.text.regions";
    static BreadcrumbReview = "breadcrumb.text.review";
    static BreadcrumbUsers = "breadcrumb.text.users";
    static BreadcrumbCodeLookup = "breadcrumb.text.codelookup";
    static BreadcrumbPendingChanges = "breadcrumb.text.pendingchanges";
    static BreadcrumbCoaches = "breadcrumb.text.coaches";
    static HomeWelcome = "home.index.welcome";
    static HomeDashboard = "home.index.dashboard";
    static HomeSchools = "home.index.schools";
    static HomeUsers = "home.index.users";
    static HomeInvitationTemplates = "home.index.invitationtemplates";
    static HomeRegions = "home.index.regions";
    static HomeProducts = "home.index.products";
    static HomeBulletinBoard = "home.index.bulletinboard";
    static HomeShowMore = "home.index.showmore";
    static DashboardTitle = "dashboard.text.title";
    static DashboardExportCount = "dashboard.text.exportcount";
    static DashboardExportDigital = "dashboard.text.exportdigital";
    static DashboardLicenseCountView = "dashboard.text.licensecountview";
    static DashboardDigitalAdoptionView = "dashboard.text.digitaladoptionview";
    static DashboardTotal = "dashboard.text.total";
    static DashboardGrapeSEED = "dashboard.text.grapeseed";
    static DashboardLittleSEED = "dashboard.text.littleseed";
    static DashboardDigital = "dashboard.text.digital";
    static DashboardTextbook = "dashboard.text.textbook";
    static DashboardDigitalUsers = "dashboard.text.digitalusers";
    static DashboardRegion = "dashboard.text.region";
    static DashboardCheckAll = "dashboard.text.checkall";
    static DashboardUncheckAll = "dashboard.text.uncheckall";
    static RegionSchoolsRegion = "region.schools.region";
    static RegionSchoolsEditRegion = "region.schools.editregion";
    static RegionSchoolsGsVersion = "region.schools.gsversion";
    static RegionSchoolsLsVersion = "region.schools.lsversion";
    static RegionSchoolsSupportEmail = "region.schools.supportemail";
    static RegionSchoolsMaterialEmail = "region.schools.materialemail";
    static RegionSchoolsBillingDay = "region.schools.billingday";
    static RegionSchoolsAdmin = "region.schools.admin";
    static RegionSchoolsAdmins = "region.schools.admins";
    static RegionSchoolsTotals = "region.schools.totals";
    static RegionSchoolsDigital = "region.schools.digital";
    static RegionSchoolsTextbook = "region.schools.textbook";
    static RegionSchoolsLittleSEED = "region.schools.littleseed";
    static RegionSchoolsSchools = "region.schools.schools";
    static RegionSchoolPeriod = "region.schools.period";
    static RegionSchoolsExportCurrent = "region.schools.exportcurrent";
    static RegionSchoolsExportPeriod = "region.schools.exportperiod";
    static RegionSchoolsAdd = "region.schools.add";
    static RegionSchoolsOrders = "region.schools.orders";
    static RegionTalkTimeSetting = "region.schools.talk-time-access";
    static RegionTagManager = "region.schools.tag-manager";
    static RegionTermManager = "region.schools.term-manager";
    static RegionSchoolsProducts = "region.schools.products";
    static RegionSchoolsGoSchoolPortal = "region.schools.goschoolportal";
    static RegionSchoolsNoLicenseExport = "region.schools.nolicenseexport";
    static RegionSchoolsExportSuccess = "region.schools.exportsuccess";
    static RegionSchoolsExportSchoolSuccess = "region.schools.exportschoolsuccess";
    static RegionSchoolsNoSchoolExport = "region.schools.noschoolexport";
    static RegionSchoolsNoCampusExport = "region.schools.nocampusexport";
    static RegionSchoolsLicenseGenerate = "region.schools.licensegenerate";
    static RegionSchoolsExport = "region.schools.export";
    static RegionSchoolsExportLicenses = "region.export.licenses";
    static RegionSchoolsExportSchools = "region.export.schools";
    static RegionEditSureDelete = "region.edit.suredelete";
    static RegionEditTitle = "region.edit.title";
    static RegionBillingDetails = "region.edit.billingdetails";
    static RegionInvitePending = "region.invite.pending";
    static RegionInviteRemove = "region.invite.remove";
    static RegionInviteResent = "region.invite.resent";
    static RegionInviteRemoveList = "region.invite.removelist";
    static RegionInviteRegionAdmins = "region.invite.regionadmins";
    static RegionInviteEmail = "region.invite.email";
    static RegionInvitePhone = "region.invite.phone";
    static RegionInviteAdmins = "region.invite.inviteadmin";
    static RegionInviteAs = "region.invite.invitedas";
    static RegionInviteRegionAdmin = "region.invite.regionadmin";
    static RegionInviteSchoolAdmin = "region.invite.schooladmin";
    static RegionInviteTrainer = "region.invite.trainer";
    static RegionInviteSystem = "region.invite.system";
    static RegionInviteGlobal = "region.invite.global";
    static RegionInviteTraning = "region.invite.traning";
    static RegionInviteEmailNotRegister = "region.invite.emailnotregister";
    static RegionInviteNotRegister = "region.invite.notregister";
    static RegionInviteNotRegisterDesc = "region.invite.notregisterdesc";
    static RegionInvite = "region.invite";
    static RegionInvitePreview = "region.invite.preview";
    static RegionInviteClose = "region.invite.close";
    static RegionInviteBack = "region.invite.back";
    static RegionInviteUser = "region.invite.user";
    static RegionInviteEmailPreview = "region.invite.emailpreview";
    static RegionInviteSubject = "region.invite.subject";
    static RegionInviteTo = "region.invite.to";
    static RegionBillingCycleWarning = "region.billing.warning";
    static AvatarMenuDashboard = "avatar.menu.dashboard";
    static AvatarMenuUsers = "avatar.menu.users";
    static AvatarMenInvitations = "avatar.menu.invitations";
    static AvatarMenuRegions = "avatar.menu.regions";
    static AvatarMenuSchools = "avatar.menu.schools";
    static AvatarMenuInvitationTemplates = "avatar.menu.invitationtemplates";
    static AvatarMenuProducts = "avatar.menu.products";
    static AvatarMenuMyActivity = "avatar.menu.myactivity";
    static AvatarMenuEditProfile = "avatar.menu.editprofile";
    static AvatarMenuSignout = "avatar.menu.signout";
    static AvatarMenuChangeLog = "help.menu.chagnelog";
    static AvatarMenuContact = "help.menu.contact";

    static RegionOrderTitle = "region.orders.title";
    static RegionOrderSearchKeyword = "region.orders.searchkeywordtext";
    static RegionOrderSearchPlaceHolder = "region.orders.searchplaceholder";
    static RegionOrderSearchDateSubmission = "region.orders.searchdatesubmissiontext";

    static ServiceInviteCodeInvalid = "service.invite.codeinvalid";
    static ServiceInviteTemNotFound = "service.invite.temnotfound";
    static ServiceInviteTypeInvalid = "service.invite.typeinvalid";
    static ServiceInviteSchoolDisabled = "service.invite.schooldisabled";
    static ServiceInviteClassDisabled = "service.invite.classdisabled";
    static ServiceInviteCampusDisabled = "service.invite.campusdisabled";
    static ServiceInviteNotGenerate = "service.invite.notgenerate";
    static ServiceInviteUsed = "service.invite.used";
    static ServiceInviteRemoved = "service.invite.removed";
    static ServiceAccountNotifyCount = "service.account.notify.count";
    static ServiceAccountSubmit = "service.account.submit";
    static ServiceAccountCancel = "service.account.cancel";
    static ServiceAccountRole = "service.account.role";
    static ServiceAccountTargetType = "service.account.targettype";
    static ServiceAccountTarget = "service.account.target";
    static ServiceResourceNotExisted = "service.resource.notexisted";
    static ServiceTempNotFound = "service.temp.notfound";
    static ServiceTempNoPermissionCreate = "service.temp.nopermissioncreate";
    static ServiceTempNoPermissionEdit = "service.temp.nopermissionedit";
    static ServiceTempUsed = "service.temp.used";
    static ServiceTempNodel = "service.temp.nodel";
    static ServicLicenseNotFound = "service.license.notfound";
    static ServiceRegionNotFound = "service.regoin.notfound";
    static ServiceRegionDisabled = "service.regoin.disabled";
    static ServiceRegionNoPermission = "service.regoin.nopermission";
    static ServiceRegionNotSave = "service.regoin.notsave";
    static ServiceRegionDelErr = "service.regoin.delerr";
    static ServiceResourceUser = "service.resource.user";
    static ServiceSchoolNotFound = "service.school.notfound";
    static ServiceSchoolNotSave = "service.school.notsave";
    static ServiceSchoolErr = "service.school.err";
    static ServiceUserNotFound = "service.user.notfound";
    static ServiceUserEmailInvalid = "service.user.emailinvalid";
    static ServiceUserEmailExist = "service.user.emailexist";
    static ServiceUserCodeUsed = "service.user.codeused";
    static ServiceUserPwdSix = "service.user.pwdsix";
    static JiraArea = "changelog.jira.area";
    static JiraStatus = "changelog.jira.status";
    static JiraType = "changelog.jira.type";
    static JiraSummary = "changelog.jira.summary";
    static JiraJQL = "changelog.jira.jql";
    static ButtonJiraImport = "changelog.button.jira.import";
    static ButtonJiraAdd = "changelog.button.jira.add";
    static ButtonImportFromJira = "changelog.button.importFromJira";
    static JiraModelTitle = "changelog.jira.model.title";
    static ChangeLogTabContent = "changelog.tab.content";
    static ChangeLogTabSystem = "changelog.tab.system";
    static SupportDeviceList = "support.devicelist";
    static SupportDeviceDeactivatedCount = "support.devicelist.summary";
    static SupportDeviceModel = "support.devicelist.model";
    static SupportDeviceName = "support.devicelist.name";
    static SupportDeviceBrand = "support.devicelist.brand";
    static SupportDeviceDate = "support.devicelist.lastlogindate";
    static SupportDeviceVersion = "support.devicelist.version";
    static SupportWijmoDragInfo = "support.wijmo.dragInfo";
    static SupportUserName = "support.userinfo.userName";
    static SupportUserEmail = "support.userinfo.userEmail";
    static SupportChangeEmail = "support.userinfo.changeEmail";
    static SupportUserCreatedOn = "support.userinfo.createdOn";
    static SupportUserPassword = "support.userinfo.password";
    static SupportUserTeacherDetails = "support.teacher.details";
    static SupportStudentList = "support.studentlist";
    static SupportSchoolList = "support.schoollist";
    static SupportStudentName = "support.studentlist.name";
    static SupportSchool = "support.common.school";
    static SupportCampus = "support.common.campus";
    static SupportRegion = "support.common.region";
    static SupportClass = "support.common.class";
    static SupportStudentRegistrationDate = "support.studentlist.registrationDate";
    static SupportStudentLeaveDate = "support.studentlist.leaveDate";
    static SupportStudentGracePeriodEndingDate = "support.studentlist.gracePeriodEndingDate";
    static SupportStudentMaxUnit = "support.studentlist.maxUnit";
    static SupportStudentMaxUnitValidationError = "support.studentlist.maxUnit.validation.error";
    static SupportRegionList = "support.regionlist";
    static SupportChildRemoved = "support.parent.childremoved";
    static SupportChildRemoveConfirm = "support.parent.childRemoveConfirm";
    static ResetPassword = "support.resetpassword";
    static SupportDeviceAppName = "support.devicelist.appname";
    static SupportDeviceAppVersion = "support.devicelist.appversion";

    static UserUpdateSuccess = "support.userupdate.success";
    static UserUpdateError = "support.userupdate.error";
    static SupportNewEmailLabel = "support.userupdate.newemail";
    static ResetPasswordConfirmText = "support.resetpassword.confirmtext";
    static ChangeEmailRequired = "support.changeEmail.required";
    static ChangeEmailNewEmail = "support.changeEmail.newemail";
    static EmailAlreadyExists = "support.changeEmail.emailexists";
    static SupportLink = "support.supportlink";
    static SupportNA = "support.common.na";
    static CodeLookupHeader = "codelookup.header.text";
    static CodeLookupCodeLabel = "codelookup.code.label";
    static CodeLookupInvitationType = "codelookup.invitationtype.label";
    static CodeLookupIssueDate = "codelookup.issuedate.label";
    static CodeLookupInvitationStatus = "codelookup.invitationstatus.label";
    static CodeLookupRegistrationDate = "codelookup.registrationdate.label";
    static CodeLookupIssueFrom = "codelookup.issuefrom.label";
    static CodeLookupSchool = "codelookup.school.label";
    static CodeLookupRegion = "codelookup.region.label";
    static CodeLookupCampus = "codelookup.campus.label";
    static CodeLookupClass = "codelookup.class.label";
    static CodeLookupDoesNotBelongToRegion = "codelookup.message.doesnotbelongtoregion";
    static CodeLookupDoesNotBelongToSchool = "codelookup.message.doesnotbelongtoschool";
    static CodeLookupDoesNotBelongToCampus = "codelookup.message.doesnotbelongtocampus";
    static CodeLookupContentAdmin = "codelookup.invitationtype.contentManager";
    static CodeLookupDoesNotExists = "codelookup.message.doesnotexists";
    static CodeLookupInvalid = "codelookup.message.invalid";
    static CodeLookupRequired = "codelookup.message.required";
    static CodeLookupError = "codelookup.message.error";
    static CodeLookupRegionAdmin = "codelookup.invitationtype.regionadmin";
    static CodeLookupTrainingAdmin = "codelookup.invitationtype.trainingadmin";
    static CodeLookupTrainer = "codelookup.invitationtype.trainer";
    static CodeLookupGlobalHead = "codelookup.invitationtype.globalhead";
    static CodeLookupSystemAdmin = "codelookup.invitationtype.systemadmin";
    static CodeLookupSubstituteTeacher = "codelookup.invitationtype.substituteteacher";
    static CodeLookupSchoolTeacher = "codelookup.invitationtype.schoolteacher";
    static CodeLookupAccountManager = "codelookup.invitationtype.accountmanager";
    static RegionBillingStartDay = "region.billing.startDay";
    static RegionBillingStartMonth = "region.billing.startMonth";
    static RegionBillingPeriod = "region.billing.period";
    static RegionBillUserForPartialMonthAdd = "region.billing.billforpartialmonthadd";
    static RegionBillUserForPartialMonthRemove = "region.billing.billforpartialmonthremove";
    static RegionDelayClassBilling = "region.billing.delayclassbilling";
    static MonthJan = "common.month.jan";
    static MonthFeb = "common.month.feb";
    static MonthMar = "common.month.mar";
    static MonthApr = "common.month.apr";
    static MonthMay = "common.month.may";
    static MonthJun = "common.month.jun";
    static MonthJul = "common.month.jul";
    static MonthAug = "common.month.aug";
    static MonthSep = "common.month.sep";
    static MonthOct = "common.month.oct";
    static MonthNov = "common.month.nov";
    static MonthDec = "common.month.dec";
    static SchoolModelLSMaxUnit = "schools.model.maxUnitLS";
    static SchoolModelGSMaxUnit = "schools.model.maxUnitGS";
    static SettingsAllowGSMaxUnit = "school_editing.item.curriculumGrapeseed";
    static SettingsAllowLSMaxUnit = "school_editing.item.curriculumLittleseed";
    static SettingMaxUnitSettingTitle = "school_editing.item.maxUnitSettingTitle";
    static SettingErrorNoMaxUnitSelected = "school_editing.item.errorNoMaxUnitSelected";
    static SettingErrorNoLicenseTypeSelected = "school_editing.item.errorNoLicenseTypeSelected";
    static SettingInvalidLcenseTypeModal = "school_editing.item.invalidLicenseType";
    static SettingErrorLicenseTypeAlreadyInUse = "school_editing.item.licenseTypeIsAlreadyInUse";
    static SettingInvalidLicenseTypeModalMsg = "school_editing.item.invalidLicenseTypeChange";
    static SettingErrorSelectedMaxUnitIsLower = "school_editing.item.maxUnitLesserFromExisting";
    static SettingInvalidUnitModalCampus = "school_editing.item.invalidUnitModalCampus";
    static SettingInvalidUnitModalClass = "school_editing.item.invalidUnitModalClass";
    static SettingInvalidUnitModalClassMsg = "school_editing.item.invalidUnitModalClassMsg";
    static SettingVisitationDetails = "setting.visitation.details";
    static SettingOnsiteVisitCount = "setting.onsite.visitcount";
    static SettingLVACount = "setting.lva.count";
    static CommonButtonOk = "common.ok.text";
    static CommonRemove = "common.remove.text";

    static MonthCompleteJanuary = "common.month.complete.january";
    static MonthCompleteFebruary = "common.month.complete.february";
    static MonthCompleteMarch = "common.month.complete.march";
    static MonthCompleteApril = "common.month.complete.april";
    static MonthCompleteMay = "common.month.complete.may";
    static MonthCompleteJune = "common.month.complete.june";
    static MonthCompleteJuly = "common.month.complete.july";
    static MonthCompleteAugust = "common.month.complete.august";
    static MonthCompleteSeptember = "common.month.complete.september";
    static MonthCompleteOctober = "common.month.complete.october";
    static MonthCompleteNovember = "common.month.complete.november";
    static MonthCompleteDecember = "common.month.complete.december";
    static CommonDaySu = "common.day.Su";
    static CommonDayMo = "common.day.Mo";
    static CommonDayTu = "common.day.Tu";
    static CommonDayWe = "common.day.We";
    static CommonDayTh = "common.day.Th";
    static CommonDayFr = "common.day.Fr";
    static CommonDaySa = "common.day.Sa";
    static ButtonSaveEditLicense = "button.common.save.editlicense";
    static RegionCoach = "region.trainer";
    static RegionCoaches = "region.trainers";
    static RegionManageAction = "region.manage.action";
    static RegionManageStudents = "region.manage.students";
    static RegionManageStudentsAction = "region.manage.students.action";
    static RegionManageStudentsSearch = "region.manage.students.search";
    static RegionManageStudentsTitle = "region.manage.students.title";
    static RegionManageStudentsName = "region.manage.students.name";
    static RegionManageStudentsRegDate = "region.manage.students.RegDate";
    static RegionManageStudentsSchool = "region.manage.students.School";
    static RegionManageStudentsCampus = "region.manage.students.Campus";
    static RegionManageStudentsClass = "region.manage.students.Class";
    static RegionManageStudentsUnit = "region.manage.students.Unit";
    static RegionManageStudentsVerify = "region.manage.students.Verify";
    static RegionManageStudentsVerifyAll = "region.manage.students.VerifyAll";
    static RegionManageStudentsModalTitle = "region.manage.students.modal.title";
    static RegionManageStudentsModalContent = "region.manage.students.modal.content";
    static RegionManageStudentsModalConfirm = "region.manage.students.modal.confirm";
    static RegionManageStudentsModalCancel = "region.manage.students.modal.cancel";
    static RegionManageStudentsMerge = "region.manage.students.merge";
    static RegionManageStudentMergeVerification = "region.manage.students.mergeverification";
    static RegionManageStudentsNoMatch = "region.manage.students.nomatch";
    static RegionManageStudentsErrorInGet = "region.manage.students.error.get";
    static RegionManageStudentsApprove = "region.manage.students.approve";
    static RegionManageStudentsUndo = "region.manage.students.undo";
    static RegionManageStudentsPreviousClass = "region.manage.students.class.previous";
    static RegionManageStudentsNewClass = "region.manage.students.class.new";
    static RegionManageStudentsViewCIMS = "region.manage.students.cims.view";
    static RegionManageStudentsSuccess = "region.manage.students.success";
    static RegionManageStudentsUndoSuccess = "region.manage.students.undosuccess";

    static RegistrationFormUserName = "registration.form.usernametext";
    static RegistrationFormUserNameRequired = "registration.form.usernamerequired";
    static RegistrationFormEnglishName = "registration.form.englishnametext";
    static RegistrationFormEmailPhone = "registration.form.emailphonetext";
    static RegistrationFormEmailPhonePlaceHolder = "registration.form.emailphoneplaceholder";
    static RegistrationFormEmailPhoneRequired = "registration.form.emailphonerequired";
    static RegistrationFormEmail = "registration.form.emailtext";
    static RegistrationFormEmailPlaceHolder = "registration.form.emailplaceholder";
    static RegistrationFormEmailRequired = "registration.form.emailrequired";
    static RegistrationFormEmailFormatError = "registration.form.emailformaterror";
    static RegistrationFormEmailLengthError = "registration.form.emaillengtherror";
    static RegistrationFormPhoneFormatError = "registration.form.phoneformaterror";
    static RegistrationFormGetVerificationCode = "registration.form.getverificationcodetext";
    static RegistrationFormVerificationCountingDownLabel = "registration.form.verificationCountingdownlabel";
    static RegistrationFormSendingVerificationCodeHint1 = "registration.form.sendingverificationcodehint1";
    static RegistrationFormSendingVerificationCodeHint2 = "registration.form.sendingverificationcodehint2";
    static RegistrationFormVerificationCode = "registration.form.verificationcodetext";
    static RegistrationFormVerificationCodeRequired = "registration.form.verificationcoderequired";
    static RegistrationFormPassword = "registration.form.passwordtext";
    static RegistrationFormPasswordRequired = "registration.form.passwordrequired";
    static RegistrationFormPasswordFormatError = "registration.form.passwordformaterror";
    static RegistrationFormConfirmPassword = "registration.form.confirmpasswordtext";
    static RegistrationFormPasswordInConsistent = "registration.form.passwordinconsistent";
    static RegistrationFormSubmitButton = "registration.form.submitbuttontext";
    static RegistrationFormTabBasicInfo = "registration.form.tab.basicInfo";
    static RegistrationFormTabVerification = "registration.form.tab.verification";
    static RegistrationFormTabComplete = "registration.form.tab.complete";
    static RegistrationFormEmailExists = "registration.form.user.emailexits";
    static RegistrationFormPhoneExists = "registration.form.user.phoneexits";
    static RegistrationFormAtLeastOneReq = "registration.form.atLeastOneReq";
    static RegistrationFormLoginTip = "registration.form.loginTip";
    static RegistrationFormPhoneLabel = "registration.form.phone.label";
    static RegistrationFormPhonePlaceHolder = "registration.form.phone.placeHolder";
    static RegistrationFormPassReq = "registration.form.password.req";
    static RegistrationFormBasicStageNext = "registration.form.basicStage.next";
    static RegistrationFormVerifyCfmLeave = "registration.form.verify.confirmLeave";
    static RegistrationFormVerifyHeadEmail = "registration.form.verify.headEmail";
    static RegistrationFormVerifyHeadPhone = "registration.form.verify.headPhone";
    static RegistrationFormVerifyEmailNotEnt = "registration.form.verify.emailNotEnt";
    static RegistrationFormVerifyPhoneNotEnt = "registration.form.verify.phoneNotEnt";
    static RegistrationFormVerifyEmailLabel = "registration.form.verify.emailLabel";
    static RegistrationFormVerifyPhoneLabel = "registration.form.verify.phoneLabel";
    static RegistrationFormVerifyLeaveOkTxt = "registration.form.verify.leaveOkTxt";
    static RegistrationFormVerifyLeaveCancelTxt = "registration.form.verify.leaveCancelTxt";
    static RegistrationFormVerifyBackBtn = "registration.form.verify.btnBack";
    static RegistrationFormVerifySubmitBtn = "registration.form.verify.btnSubmit";
    static RegistrationFormVerifyInvalidCode = "registration.form.verify.invalidCode";
    static RegistrationFormVerifyCodeReq = "registration.form.verify.codeReq";
    static RegistrationFormVerifyStatus = "registration.form.verify.status";
    static RegistrationFormVerifyUnver = "registration.form.verify.statusUnVer";
    static RegistrationFormVerifyVer = "registration.form.verify.statusVer";
    static RegistrationFormVerifyVerify = "registration.form.verify.statusVerify";
    static RegistrationInvalidCode = "registration.form.invalidCode";
    static RegistrationEmailLabel = "registration.form.email.label";
    static RegitrationSuccessHere = "registration.success.here";
    static RegistrationSignUpHeader = "registration.signup.head";
    static RegistrationFinishMsg = "registration.finish.msg";
    static RegistrationFormEmailReq = "registration.form.emailReq";
    static RegistrationVerifyEmailCfm = "registration.form.verify.confirmLeaveEmail";
    static RegistrationFormVerifyDisTooltip = "registration.form.verify.disableTooltip";
    static RegistrationFormVerifyEnaTooltip = "registration.form.verify.enableTooltip";
    static RegistrationRedeemErrTitle = "registration.redeem.err.title";
    static RegistrationRedeemErrDesc = "registration.redeem.err.desc";
    static RegistrationRemoveContactConfirm = "registration.removecontact.confirm";
	static RegistrationRemoveContactConfirmYes = "registration.removecontact.confirm.yes";
	static RegistrationRemoveContactConfirmNo = "registration.removecontact.confirm.no";
	static RegistrationRemoveContactDelete = "registration.removecontact.delete";

    static CampusLicenseChangeWarningColumnClass = "campus_editing.licensechangewarning.column.class";
    static CampusEditingErrorLicenseTypeAlreadyInUse = "campus_editing.item.licenseTypeIsAlreadyInUse";
    static CampusEditingInvalidLicenseTypeModalMsg = "campus_editing.item.invalidLicenseTypeChange";
}

export class SurveyLocale {
    static SurveyCommonArchive = "survey.common.archive";
    static SurveyCommonEdit = "survey.common.edit";
    static SurveyCommonDelete = "survey.common.delete";
    static SurveyCommonClone = "survey.common.clone";
    static SurveyCommonGoBack = "survey.common.back";
    static SurveyViewTab = "survey.view.tab";
    static SurveyCreateTab = "survey.create.tab";
    static SurveyTemplateTab = "survey.template.tab";
    static SurveyQuestionTypeText = "survey.question.type.text";
    static SurveyQuestionTypeRating = "survey.question.type.rating";
    static SurveyQuestionTypeOptions = "survey.question.type.options";
    static SurveyQuestionTypeCheckbox = "survey.question.type.checkbox";
    static SurveyQuestionTypeScore = "survey.question.type.score";
    static SurveyCreateEnterYourAnswer = "surveyCreateSurvey.yourAnswer";

    static SurveyCreateTabHeader = "survey.create.tabHeader";
    static SurveyNewText = "survey.create.text";
    static SurveyHistoryText = "survey.create.history";
    static SurveyCreateUserText = "survey.create.userText";
    static SurveyCreateSaveDraft = "survey.create.saveDraft";
    static SurveyCreateRoleText = "survey.create.roleText";
    static SurveyCreateCreationDate = "survey.create.creationDate";
    static SurveyCreateSurvey = "surveyCreateSurvey.AddNew";
    static SurveyCreateAddOption = "surveyCreateSurvey.AddOption";
    static SurveyCreateOptionRequired = "surveyCreateSurvey.OptionRequired";
    static SurveyCreateRequired = "surveyCreateSurvey.Required";
    static SurveyCreateQuestion = "surveyCreateSurvey.Questions";
    static SurveyCreateSelectRoles = "surveyCreateSurvey.SelectRoles";
    static SurveyCreateTwoOptionsSame = "surveyCreateSurvey.TwoOptionSame";
    static SurveyCreateDigitalUserStatistics = "surveyCreateSurvey.UserStatisticsReport";
    static SurveyCreateSchoolClassesReport = "surveyCreateSurvey.SchoolClassesReport";
    static SurveyCreateClassCount = "surveyCreateSurvey.classcount";
    static SurveyCreateCampusCount = "surveyCreateSurvey.campuscount";
    static SurveyCreateStudentCount = "surveyCreateSurvey.studentcount";
    static SurveyCreateTeacherCount = "surveyCreateSurvey.teachercount";
    static SurveyCreateTotal = "surveyCreateSurvey.total";
    static SurveyCreateGrapeseed = "surveyCreateSurvey.grapeseed";
    static SurveyCreateLittleseed = "surveyCreateSurvey.littleseed";
    static SurveyCreateLicenseHistoryReport = "surveyCreateSurvey.LicenseHistoryReport";
    static SurveyCreateDataByRegionGroup = "surveyCreateSurvey.DataByRegionGroup";
    static SurveyCreateDataByRegion = "surveyCreateSurvey.DataByRegion";
    static PollCloneConfirmMessage = "surveyCreateSurvey.clone.confirm";
    static PollClonedSuccessfully = "surveyCreateSurvey.duplicate.success.title";
    static SurveyMaxScoreLabel = "surveyCreateSurvey.maxscore.label";
    static SurveyNotApplicableLabel = "surveyCreateSurvey.notapplicable.label";
    static SurveyMaxScoreMessage = "surveyCreateSurvey.maxscore.validation";

    static SurveySaveAsDraftText = "survey.complete.saveAsDraft";
    static SurveyFillShare = "survey.complete.share";
    static SurveyFillRegion = "survey.complete.region";
    static SurveyFillRegionGroup = "survey.complete.regionGroup";
    static SurveyFillSelect = "survey.complete.select";
    static SurveyReviewText = "survey.review.reviewed";
    static SurveyReviewEmailResponse = "survey.review.emailResponse";
    static SurveyMarkAsReadText = "survey.view.markAsRead";

    static TemplateDeleteMsg = "surveyMessage.template.delete";
    static TemplateReferencedMsg = "surveyMessage.template.referenced";
    static ArchivedSurveyMsg = "surveyMessage.survey.archived";
    static ArchiveSurveyErrorMsg = "surveyMessage.survey.errorOnArchive";
    static SurveyDeleteConfirmMsg = "surveyMessage.survey.confirmDelete";
    static SurveyDeleteNote = "surveyMessage.survey.deleteNote";
    static SurveyDeleteSuccessMsg = "surveyMessage.survey.deleteSuccess";
    static SurveyDeleteErrorMsg = "surveyMessage.survey.deleteError";
    static SurveyChangesLostMsg = "surveyMessage.complete.changesLost";
    static SurveyFillOneAnswerMsg = "surveyMessage.complete.allEmpty";
    static SurveyFillSaveAsDraftSuccess = "surveyMessage.complete.saveAsDraftSuccess";
    static SurveyFillSuccess = "surveyMessage.complete.success";
    static SurveyCompleteAttachmentFailure = "surveyMessage.complete.attachmentFailure";
    static SurveyCompleteLicenseCountView = "survey.complete.licenseCountView";
    static SurveyReviewSuccess = "surveyMessage.review.success";
    static SurveyQuestionsPendingMessage = "surveyMessage.questions.pending";
    static SurveyQuestionPendingMessage = "surveyMessage.question.pending";
    static SurveyReviewError = "surveyMessage.review.error";
    static SurveyMarkAsReadSuccess = "surveyMessage.markAsRead.success";
    static SurveyMarksAsReadError = "surveyMessage.markAsRead.error";
    static SurveyCancelUploadConfirm = "surveyMessage.cancelUpload.confirm";
    static SurveyInvalidFileType = "surveyMessage.invalid.file";
    static SurveyFileSizeError = "surveyMessage.filesize.limit";
    static SurveyAnswerRequired = "surveyCreateSurvey.AnswerRequired";
    static IsRegionSpecific = "surveyCreateTemplate.RegionSpecific";
    static StatisticsFilterType = "surveyCreateTemplate.StatisticsFilterType";
    static ReportType = "surveyCreateTemplate.ReportType";
    static MaxQuestionError = "surveyCreateTemplate.MaxQuestionError";
    static MinQuestionError = "surveyCreateTemplate.MinQuestionError";
    static IncludeStatistics = "surveyCreateTemplate.IncludeStatistics";
    static CreateTemplateName = "surveyCreateTemplate.TemplateName";
    static CreateTemplateQuestion = "surveyCreateTemplate.Question";
    static SelectOptionMessage = "surveyCreateTemplate.SelectOption";
    static MaxQuestionLengthMessage = "surveyCreateTemplate.MaxQuestionLength";
    static MinQuestionLengthMessage = "surveyCreateTemplate.MinQuestionLength";
    static RequiredQuestionMessage = "surveyCreateTemplate.RequiredQuestion";
    static AddTemplateText = "surveyCreateTemplate.AddTemplateText";
    static AddQuestionText = "surveyCreateTemplate.AddQuestion";
    static AddSurveyText = "surveyCreateSurvey.AddSurveyText";
    static SelectTemplateLabel = "surveyCreateSurvey.SelectTemplateLabel";
    static SurveyDescriptionLabel = "surveyCreateSurvey.descriptionlabel";
    static SurveyNameLabel = "surveyCreateSurvey.SurveyNameLabel";
    static IsRecurringLabel = "surveyCreateSurvey.IsRecurringLabel";
    static DueDateLabel = "surveyCreateSurvey.DueDateLabel";
    static DueDayLabel = "surveyCreateSurvey.DayLabel";
    static RecurrenceLinkText = "surveyCreateSurvey.RecurrenceLinkText";
    static RecurrenceWeeklyLabel = "surveyCreateSurvey.RecurrenceWeeklyLabel";
    static RecurrenceMonthlyLabel = "surveyCreateSurvey.RecurrenceMonthlyLabel";
    static RecurrenceDayMon = "surveyCreateSurvey.RecurrenceDay.Mon";
    static RecurrenceDayTues = "surveyCreateSurvey.RecurrenceDay.Tues";
    static RecurrenceDayWed = "surveyCreateSurvey.RecurrenceDay.Wed";
    static RecurrenceDayThur = "surveyCreateSurvey.RecurrenceDay.Thur";
    static RecurrenceDayFri = "surveyCreateSurvey.RecurrenceDay.Fri";
    static RecurrenceDaySat = "surveyCreateSurvey.RecurrenceDay.Sat";
    static RecurrenceDaySun = "surveyCreateSurvey.RecurrenceDay.Sun";
    static RecurrenceDaySunday = "surveyCreateSurvey.RecurrenceDay.Sunday";
    static RecurrenceDayMonday = "surveyCreateSurvey.RecurrenceDay.Monday";
    static RecurrenceDayTuesday = "surveyCreateSurvey.RecurrenceDay.Tuesday";
    static RecurrenceDayWednesday = "surveyCreateSurvey.RecurrenceDay.Wednesday";
    static RecurrenceDayThursday = "surveyCreateSurvey.RecurrenceDay.Thursday";
    static RecurrenceDayFriday = "surveyCreateSurvey.RecurrenceDay.Friday";
    static RecurrenceDaySaturday = "surveyCreateSurvey.RecurrenceDay.Saturday";
    static RecurrenceMonthDayRangeError = "surveyCreateSurvey.RecurrenceMonthDay.Err";
    static UniqueSurveyNameError = "surveyCreateSurvey.UniqueSurveyName.Err";
    static DueDateError = "surveyCreateSurvey.DueDate.Err";
    static ShareLinkText = "surveyCreateSurvey.ShareSurveyLink.Text";
    static NotificationSaveSuccessMsg = "surveyCreateSurvey.SaveSuccessMsg";
    static MySurveyTodo = "mysurveys.Todos";
    static MySurveyReview = "mysurveys.Review";
    static MySurveySharedReports = "mysurveys.SharedReports";
    static MySurveyDueOn = "mysurveys.DueOn";
    static MySurveyComplete = "mysurveys.Complete";
    static MySurveyContribute = "mysurveys.Contribute";
    static MySurveyView = "mysurveys.View";
    static MySurveyMyHistory = "mysurveys.MyHistory";
    static MySurveyDaysLate = "mysurveys.DaysLate";
    static MySurveyDayLate = "mysurveys.DayLate";
    static MySurveyFromUser = "mysurveys.FromUser";
    static MySurveyResponseByUser = "mysurveys.ResponseByUser";
    static MySurveyShowMore = "mysurveys.ShowMore";
    static MySurveyViewAll = "mysurveys.ViewAll";
    static MySurveyNoSurveyMessage = "mysurveys.noSurveyMessage";
    static MySurveyNoSurveyToReview = "mysurveys.noSurveyToReview";
    static MySurveyGoBack = "reviewsurveys.GoBack";
    static MySurveyShowReviewed = "reviewsurveys.ShowReviewed";
    static MySurveyHideReviewed = "reviewsurveys.HideReviewed";
    static CreateSurveyRoleAccountManager = "surveyCreateSurvey.AccountManager";
    static SurveyNoResponseMessage = "surveyCreateSurvey.response.noResponse";
    static SurveyResponseDueOnText = "surveyCreateSurvey.response.dueOn";
    static SurveyResponseSubmittedOnText = "surveyCreateSurvey.response.submittedOn";
    static CommonShowMoreText = "survey.common.showMore";
    static CommonViewText = "survey.common.view";
    static SurveyCommonResponses = "survey.common.responses";
    static SurveyNoHistoryMessage = "mysurveys.history.nodata";
    static SurveyFillShareModalTitle = "survey.complete.share.modalTitle";
    static SurveyFillShareModalText = "survey.complete.share.modalText";
    static SurveyFillShareModalNote = "survey.complete.share.modalNote";
    static SurveyFillShareModalMaxUserError = "survey.complete.share.modal.maxShareError";
    static SurveyFilleShareRegionRequired = "surevy.complete.share.region.required";

    static SurveyEmailTo = "surveyEmail.to.label";
    static SurveyEmailCc = "surveyEmail.cc.label";
    static SurveyEmailSubject = "surveyEmail.subject.label";
    static SurveyEmailQuestionReset = "surveyEmail.question.reset";
    static SurveyEmailCommentPlaceholder = "surveyEmail.comments.placeholder";
    static SurveyEmailSend = "surveyEmail.send.label";
    static SurveyEmailSuccess = "surveyEmail.send.success";
    static SurveyEmailError = "surveyEmail.send.error";
    static SurveyEmailToValidation = "surveyEmail.validate.to";

    static PollHeading = "poll.heading";
    static CreateNewPoll = "poll.create.text";
    static ExitSurveyDownloadRecords = "exitsurvey.download.records";
    static ExitSurveyDownloadException = "exitsurvey.download.exception";
    static ExitSurveyDownloadDateRangeException = "exitsurvey.download.daterange.exception";
    static PollCreatedHistory = "poll.createdhistory.text";
    static PollSharedSurvey = "poll.sharedsurvey.text";
    static PollsToView = "poll.view.text";
    static PollNameColumn = "poll.name.text"
    static PollDueDate = "poll.duedate.text";
    static PollPublishedDate = "poll.published.text";
    static PollParticipation = "poll.participation.text";
    static PollsToReview = "poll.review.text";
    static PollFrom = "poll.from.text";
    static PollAction = "poll.action.text";
    static PollCompletedText = "poll.completed.text";
    static PollReviewHistory = "poll.reviewhistory.text";
    static PollShowExpired = "poll.createdhistory.showexpired";
    static PollShowHistory = "poll.reviewhistory.showhistory";
    static PollReviewAll = "poll.reviewhistory.all";
    static PollReviewComplete = "poll.reviewhistory.complete";
    static PollReviewContribute = "poll.reviewhistory.contribute";
    static PollReviewView = "poll.reviewhistory.view";
    static PollSubmittedDate = "poll.reviewhistory.submittedon";
    static PollCreationDate = "poll.reviewhistory.createdon";

    static PollResponseNoResponseText = "pollresponse.noresponsetext";
    static PollResponsePollName = "pollresponse.pollname.text";
    static PollResponseInstanceCreationDate = "pollresponse.polldate.text";
    static PollResponseSubmitDate = "pollresponse.column.submitteddate";
    static PollResponseUser = "pollresponse.column.user";
    static PollResponseRating = "pollresponse.rating.axis";
    static PollResponseUserCount = "pollresponse.usercount.axis";
    static PollResponseAverage = "pollresponse.average.text";
    static PollResponseUnpublishedMsg = "pollresponse.message.unpublished";
    static PollResponseOption = "pollresponse.option.axis";

    static SurveyDigitalAdoptionAccountsLinked = "surveyreport.digitaladoption.accountslinked";
    static SurveyDigitalAdoptionDigitalLicenses = "surveyreport.digitaladoption.digitallicenses";
    static SurveyDigitalAdoptionGrapeseedLicenses = "surveyreport.digitaladoption.grapeseedlicenses";
    static SurveyDigitalAdoptionInvitationsPrinted = "surveyreport.digitaladoption.invitationsprinted";
    static SurveyDigitalAdoptionREPPlayback = "surveyreport.digitaladoption.repplayback";
    static SurveyDigitalAdoptionTabletLogin = "surveyreport.digitaladoption.tabletlogin";
    static SurveyExportButtonTitle = "survey.export.button.title";
    static SurveyExportMessagePrepare = "survey.export.message.prepare";
    static SurveyExportMessageSuccess = "survey.export.message.success";

    static SurveyQuestion1 = "exitSurvey.questions.number1";
	static SurveyQuestion2 = "exitSurvey.questions.number2";
	static SurveyQuestion3 = "exitSurvey.questions.number3";
	static SurveyQuestion4 = "exitSurvey.questions.number4";
	static SurveyQuestion1Option1 = "exitSurvey.options.que1.opt1";
	static SurveyQuestion1Option2 = "exitSurvey.options.que1.opt2";
	static SurveyQuestion1Option3 = "exitSurvey.options.que1.opt3";
	static SurveyQuestion1Option4 = "exitSurvey.options.que1.opt4";
	static SurveyQuestion1Option5 = "exitSurvey.options.que1.opt5";
	static SurveyQuestion1Option6 = "exitSurvey.options.que1.opt6";
	static SurveyQuestion1Option7 = "exitSurvey.options.que1.opt7";

}


export class TrainingLocale {
    static QuestionnaireGalleryHeader = "questionnaire.gallery.header";
    static QuestionnaireAnswerCorrect = "questionnaire.answer.correct";
    static QuestionnaireAnswerLabel = "questionnaire.answer.label";
    static QuestionnaireQuestionLabel = "questionnaire.question.label";
    static QuestionnaireQuestionTypeLabel = "questionnaire.questionType.label";
    static QuestionnaireQuestionTypeOptionLabel = "questionnaire.questionType.option.label";
    static QuestionnaireQuestionTypeMultiChoiceLabel = "questionnaire.questionType.multiChoice.label";
    static QuestionnaireQuestionTypeTextLabel = "questionnaire.questionType.text.label";
    static QuestionnaireLabel = "questionnaire.questionnaire.label";
    static QuestionnaireCourseLabel = "questionnaire.course.label";
    static QuestionnaireSeriesLabel = "questionnaire.series.label";
    static QuestionnaireMandatoryLabel = "questionnaire.mandatory.label";
    static QuestionnaireUnitLabel = "questionnaire.unit.label";
    static QuestionnaireCreateTitle = "questionnaire.create.title";
    static QuestionnaireEditTitle = "questionnaire.edit.title";
    static QuestionnaireEditInfo = "questionnaire.edit.info";
    static QuestionnaireCardTitle = "questionnaire.card.title";
    static QuestionnaireEmptyMessage = "questionnaire.empty.message";
    static QuestionnaireSubmitAnswersButtonTxt = "questionnaire.submit.answers.buttonTxt";
}

export class LinkManager {
    static Label = "linkmanager.locallink";
    static Title = "linkmanager.title.header";
    static TabSchool = "linkmanager.tab.school";
    static TabParent = "linkmanager.tab.parent";
    static TabSchoolResource = "linkmanager.tab.school.resource";
    static TabSchoolAmin = "linkmanager.tab.school.admin";
    static TabSchoolHelp = "linkmanager.tab.school.help";
    static TabParentREP = "linkmanager.tab.parent.rep";
    static TabParentResources = "linkmanager.tab.parent.resources";
    static TabContentResources = "linkmanager.tab.content.resources";
    static TabContentAdmin = "linkmanager.tab.content.admin";
    static TabTrainingResource = "linkmanager.tab.training.resource";
    static TabConnectResource = "linkmanager.tab.connect.resource";
    static TabConnectAdministrator = "linkmanager.tab.connect.administrator";
    static TabReportResource = "linkmanager.tab.report.resource";
    static LocalLink = "linkmanager.locallink";
    static TextAdd = "linkmanager.add";
    static EnglishName = "linkmanager.englishname";
    static LocalText = "linkmanager.localtext";
    static Link = "linkmanager.link";
    static IconDelete = "linkmanager.icon.delete";
    static TextSureDel = "linkmanager.msg.delete";
    static AddLocalLink = "linkmanager.add.local.link";
    static AddALink = "linkmanager.addlink";
    static Required = "linkmanager.required";
    static Edit = "linkmanager.icon.edit";
    static EnglishText = "linkmanager.englishtext";
    static Save = "linkmanager.save";
    static Cancel = "linkmanager.cancel";
    static Loading = "linkmanager.loading";
    static Error = "linkmanager.error"
    static Note = "linkmanager.note"
    static ValidURL = "linkmanager.validurl"
    static ValidRequire = "linkmanager.validrequire"
}

export class MovePromoteStudentsLocale {
    static CampusFeatureTitle = "movepromotestudents.campusfeature.title";
    static BreadcrumbTitle = "movepromotestudents.breadcrumb.title";
    static SelectCampusMessage = "movepromotestudents.selectcampus.message";
    static SelectClassMessage = "movepromotestudents.selectclass.message";
    static NotesLabel = "movepromotestudents.notes.label";
    static ActiveClassLabel = "movepromotestudents.activeclass.label";
    static InactiveClassLabel = "movepromotestudents.inactiveclass.label";
    static MoveClassLabel = "movepromotestudents.moveclass.label";
    static PromoteClassLabel = "movepromotestudents.promoteclass.label";
    static ClassFilterLabel = "movepromotestudents.classfilter.label";
    static PendingChangesLabel = "movepromotestudents.pendingchanges.label";
    static ResetButtonLabel = "movepromotestudents.resetbutton.label";
    static SaveButtonLabel =  "movepromotestudents.savebutton.label";
    static AgeGradeLabel = "movepromotestudents.agegrade.label";
    static SelectAllLabel = "movepromotestudents.selectall.label";
    static StudentCountLabel = "movepromotestudents.studentcount.label";
    static UnitLabel = "movepromotestudents.unit.label";
    static ChangesLostWarningMessage = "movepromotestudents.changeslostwarning.message";
    static ChangesSaveSucessfullMessage = "movepromotestudents.changessavesucessfull.message";
    static NoRecordsMessage = "movepromotestudents.norecords.message";
    static RestrictFutureCurrentMessage = "movepromotestudents.restrictfuturecurrent.message";
    static RestrictAlreadyPromotedMessage = "movepromotestudents.restrictalreadypromoted.message";
    static RestrictAlreadyPromotedInSameClassMessage = "movepromotestudents.restrictalreadypromotedinsameclass.message" ;
    static PreviousPromotionCanceledWarningMessage = "movepromotestudents.previouspromotioncanceledwarning.message";
    static ClassTermDropdownLabel = "movepromotestudents.classterm.dropdown.label";
    static ClassTermDropdownPlaceholder = "movepromotestudents.classterm.dropdown.placeholder";
    static RestrictActiveToDisableWarningMessage = "movepromotestudents.restrictactivetodisablewarning.message";
}

export class TagManagerLocale {
    static PageTitle = "tagmanager.page_title";
    static AddLinkText = "tagmanager.add_link_text";
    static GridTagLabel = "tagmanager.grid_tag_label";
    static GridUsageLabel = "tagmanager.grid_usage_label";
    static GridRemoveLabel = "tagmanager.grid_remove_label";

    static AddPopupTitle = "tagmanager.add_popup_title";
    static AddPopupEntityLabel = "tagmanager.add_popup_entity_label";
    static AddPopupTagLabel = "tagmanager.add_popup_tag_label";
    static AddPopupTagRequiredMessage = "tagmanager.add_popup_tag_required";
    static AddPopupTagLengthMessage = "tagmanager.add_popup_tag_length";
    static AddPopupTagPatternMessage = "tagmanager.add_popup_tag_pattern";
    static AddPopupTagUniqueMessage = "tagmanager.add_popup_tag_unique";
    static AddPopupCancel = "tagmanager.add_popup_cancel";
    static AddPopupSave = "tagmanager.add_popup_save";

    static RemovePopupTitle = "tagmanager.remove_popup_title";
    static RemovePopupText = "tagmanager.remove_popup_text";
    static RemovePopupCancel = "tagmanager.remove_popup_cancel";
    static RemovePopupDelete = "tagmanager.remove_popup_delete";

    static UsagePopupTitle = "tagmanager.usage_popup_title";
    static UsagePopupGridEntityLabel = "tagmanager.usage_popup_grid_entity_label";
    static UsagePopupGridRemoveLabel = "tagmanager.usage_popup_grid_remove_label";

    static TaggingAddButtonLabel = "tagmanager.tagging_add.button_label";
    static TaggingAddPopoverTitle = "tagmanager.tagging_add.popover_title";
    static TaggingAddPopoverCancel = "tagmanager.tagging_add.popover_cancel";
    static TaggingAddPopoverSave = "tagmanager.tagging_add.popover_save";
    static TaggingAddPopoverNoData = "tagmanager.tagging_add.popover_nodata";

    static TaggingViewShowMoreTooltip = "tagmanager.tagging.show_button_label";
    static TaggingViewPopoverTitle = "tagmanager.tagging.popover_title";
}

export class TermManagerLocale {
    static PageTitle = "termmanager.page_title";
    static GridNameLabel = "termmanager.grid_name_label";
    static GridStartDateLabel = "termmanager.grid_startdate_label";
    static GridEndDateLabel = "termmanager.grid_enddate_label";
    static GridEnforceDateLabel = "termmanager.grid_enforcedate_label";
    static AddLinkText = "termmanager.add_link_text";
    static GridRemoveLabel = "termmanager.grid_remove_label";
    static ModalSaveLabel = "termmanager.modal.save_label";
    static ModalCancelLabel = "termmanager.modal.cancel_label";
    static ModalNameLabel = "termmanager.grid_name_label";
    static ModalStartDateLabel = "termmanager.grid_startdate_label";
    static ModalEndDateLabel = "termmanager.grid_enddate_label";
    static ModalEnforceDateLabel = "termmanager.grid_enforcedate_label";
    static ModalDisabledLabel = "termmanager.modal.disabled_label";
    static ModalDeleteLabel = "termmanager.modal.delete_label";
    static ModalConfirmDeleteMessage = "termmanager.modal.confirm_delete_message";
    static ModalConfirmTitle = "termmanager.modal.confirm.title";
    static ModalButtonOkLabel = "termmanager.modal.confirm.ok_label";
    static ModalButtonCancelLabel = "termmanager.modal.cancel_label";
    static ModalConfirmMessage = "termmanager.modal.confirm.message";
}

export class TalkTime {
    static TalkTimeSaveMsg = "talk.time.save.msg";
    static TalkTimeCancelMsg = "talk.time.cancel.msg";
    static TalkTimeNavigationMsg = "talk.time.navigation.msg";
    static TalkTimeTotalAllowed = "talk.time.total.allowed";
    static TalkTimeTotal = "talk.time.total";
    static TalkTimeAllow = "talk.time.allow";
    static TalkTimeSettingSaveSuccess = "talk.time.setting.save.success";
    static TalkTimeExternalUserAccess = "talk.time.external.user.access";
}

export class RewardPointsLocale {
    static EntryText = "rewardpoints.entry.text";
    static RewardPointsExportAction = "rewardpoints.action.export";
    static BulkAddPointTitle = "rewardpoints.bulkdaddpoint.title";
    static RewardPointsAdjustAction = "rewardpoints.action.adjust";
    static RewardPointsDelConfirmAction = "rewardpoints.action.delconfirm";
    static RewardPointsDelConfirmYesAction = "rewardpoints.action.delconfirmyes";
    static RewardPointsDelConfirmNoAction = "rewardpoints.action.delconfirmno";
    static RewardPointsLabelSchool = "rewardpoints.label.school";
    static RewardPointsLabelCampus = "rewardpoints.label.campus";
    static RewardPointsLabelTotalPoints = "rewardpoints.label.totalpoints";
    static RewardPointsLabelRecordImported = "rewardpoints.label.recordimported";
    static RewardPointsLabelRecordInvalid = "rewardpoints.label.recordivalid";
    static RewardPointsLabelRecordInvalidDetail ="rewardpoints.label.recordivaliddetail";
    static RewardPointsLabelCommit = "rewardpoints.label.commit";
    static RewardPointsLabelSave = "rewardpoints.label.save";
    static RewardPointsLabelCancel = "rewardpoints.label.cancel";
    static RewardPointsLabelClear = "rewardpoints.label.clear";
    static RewardPointsLabelUploadSupportedFormat = "rewardpoints.label.upload.supportedformat";
    static RewardPointsLabelUploadFormatLinkText ="rewardpoints.label.upload.formatlinktext"
    static RewardPointsNotiDeleted = "rewardpoints.noti.deleted";
    static RewardPointsNotiCreated = "rewardpoints.noti.created";
    static RewardPointsNotiUpdated = "rewardpoints.noti.updated";
    static RewardPointsNotiUploaded = "rewardpoints.noti.uploaded";
    static RewardPointsNotiFileType = "rewardpoints.noti.filetype";
    static RewardPointsNotiFileSize = "rewardpoints.noti.filesize";
    static RewardPointsNotiFileSheet = "rewardpoints.noti.filesheet";
    static RewardPointsColumnSchool = "rewardpoints.column.school";
    static RewardPointsColumnCampus = "rewardpoints.column.campus";
    static RewardPointsColumnDate = "rewardpoints.column.date";
    static RewardPointsColumnDesc = "rewardpoints.column.desc";
    static RewardPointsColumnPoints = "rewardpoints.column.points";
    static RewardPointsRedirectWaring = "rewardpoints.redirectwarning";
    static RewardPointsDragLabel = "rewardpoints.draglabel";
    static RewardPointsValidateCampusId = "rewardpoints.validate.campusid";
    static RewardPointsValidateDesc= "rewardpoints.validate.desc";
    static RewardPointsValidatePoint= "rewardpoints.validate.point";
    static RewardPointsValidateDateMissing= "rewardpoints.validate.date.missing";
    static RewardPointsValidateDateInvalid= "rewardpoints.validate.date.invalid";
    static RewardPointsValidatePointMissing= "rewardpoints.validate.point.missing";
    static RewardPointsValidateFormPoint = "rewardpoints.validate.form.point";
    static RewardPointsValidateFormPointZero = "rewardpoints.validate.form.pointzero";
    static RewardPointsValidateFormPointRange = "rewardpoints.validate.form.pointrange";
    static RewardPointsValidateFormDate = "rewardpoints.validate.form.date";
    static RewardPointsValidateFormDesc = "rewardpoints.validate.form.desc";
    static RewardPointsValidateFormPlaceholderDesc = "rewardpoints.validate.form.placeholder.desc"
}

export class LicenseTypeLocale {
    static Classic = "license.type.classic";
    static Connect = "license.type.connect";
    static Nexus = "license.type.nexus";
    static HybridConnect = "license.type.hybridconnect";
    static HybridNexus = "license.type.hybridnexus";
    static HybridConnectNexus = "license.type.hybridconnectnexus";
}